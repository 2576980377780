import React from 'react';
import PropTypes from 'prop-types';
import styles from './nav-group.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import BetaIcon from '@/components/atoms/beta-icon';

const NavGroup = ({ title, icon, activeIcon, betaIcon, absolutePath, aliasMatch = null, links, closed, external, children, ...props }) => {
  const location = useLocation();

  const isActive = links.some(link => location.pathname === link) || (aliasMatch && aliasMatch(location.pathname));

  return (
    <div className={styles.cgNavGroup} {...props}>
      <NavLink to={absolutePath ? { pathname: links[0] } : links[0]} target={external ? '_blank' : '_self'} className={`${styles.cgNavGroup__header} ${isActive && styles.cgNavGroup__headerActive}`}>
        <div className={styles.cgNavGroup__headerIcon}>
          {isActive ? activeIcon : icon}
        </div>
        <div className={`${closed ? styles.cgNavGroup__closed : styles.cgNavGroup__headerTitle} ${isActive && styles.cgNavGroup__headerTitleActive}`}>
          {title} {betaIcon && <BetaIcon />}
        </div>
      </NavLink>
      <div className={`${closed ? styles.cgNavGroup__closed : styles.cgNavGroup__children}`}>{children}</div>
    </div>
  );
}

NavGroup.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  activeIcon: PropTypes.element,
  betaIcon: PropTypes.bool,
  aliasMatch: PropTypes.func,
  links: PropTypes.array,
  closed: PropTypes.bool,
  external: PropTypes.bool,
  absolutePath: PropTypes.bool,
};

const Link = ({ link, counter, external, children, ...props }) => (<NavLink to={{pathname: link}} target={external ? '_blank' : '_self'} className={styles.cgNavGroupLink} {...props} activeClassName={styles.cgNavGroupLinkActive}>
  {children} {counter ? `(${counter})` : ''}
</NavLink>);

Link.propTypes = {
  link: PropTypes.string,
  external: PropTypes.bool,
  counter: PropTypes.number
}

export default Object.assign(NavGroup, {
  Link
});
