import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import styles from './nav-pill.module.scss';

const NavPill = ({className = '', classNameActive, active, empty, disableHover = false, children, ...props}) => (
  <Button
    className={[styles.cgNavPill,
      className,
      (active && classNameActive),
      (active ? styles.cgNavPill__active : styles.cgNavPill__inactive),
      (empty && styles.cgNavPill__empty),
      (!props.to && styles.cgNavPill__noRedirect),
      (!disableHover ? styles.cgNavPill__hover : styles.cgNavPill__disabledHover)
    ]}
    as={Link} {...props}>{children}</Button>
);

NavPill.propTypes = {
  className: PropTypes.string,
  classNameActive: PropTypes.string,
  active: PropTypes.bool,
  disableHover: PropTypes.bool,
  empty: PropTypes.bool,
  to: PropTypes.string,
};

export default NavPill;
