export const TermsOfUseTranslations = {
    termsOfUseTranslations: {
        pageTitle: 'Terms of use',
        paragraph1: 'Welcome to Caregiving.com. We’re so glad you’re here with us.',
        paragraph2: 'These Terms of Use apply to Caregiving.com and all of its trade names, henceforth referred to collectively as “Caregiving.com.”',
        paragraph3: 'Caregiving.com is a community of family caregivers sharing their stories, support and solutions.',
        paragraph4: 'Caregiving.com is an informational resource to help you assist your family members and friends with chronic illnesses and/or disabilities. While we hope to provide you with useful information and assistance, we do not provide medical advice, diagnosis, or treatment; legal, financial, or professional advice; or specific advice regarding which service providers you should use. Any decisions that you need to make about the care of a family member or friend should be made after engaging and consulting with a professional licensed service provider based upon your specific needs or conducting your own careful review and investigation.',
        paragraph5: 'These Terms of Use were last revised on May 31, 2018.',
        paragraph6: 'These Terms of Use (“Terms”) are a legal agreement between you and Caregiving.com (our “Website”) and govern your use of our Website either as a visitor, content contributor or as a registered user. Use of, posting of information to and access to our Website is subject to your compliance with these Terms, so please read them carefully before using our Website. Caregiving.com reserves the right to limit or terminate your access to our Website or terminate or suspend your registration for failure to comply with the terms and policies posted on our Website, or for any reason at any time.',
        paragraph7: 'BY ACCESSING AND USING OUR WEBSITE IN ANY WAY, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS, DO NOT USE OR POST INFORMATION ON OUR WEBSITE IN ANY MANNER.',
        paragraph9: {
            title: 'MODIFICATIONS TO THE TERMS OF USE',
            description: 'Caregiving.com may change, modify, update, add, or remove portions of these Terms at any time.Please check these Terms periodically for changes.Your continued use of our Website following the posting of any changes will signify your acceptance of those changes.'
        },
        paragraph10: {
            title: 'REGISTRATION',
            description: 'To use certain parts of our Website(e.g., participating in our groups), you will be asked to create an account, which means you provide certain information about yourself, including your first name, e - mail address and password.'
        },
        paragraph11: {
            title: 'PRIVACY POLICY',
            description: 'Caregiving.com respects your desire for privacy and takes care to protect personal information that you provide to us.Caregiving.com does not share your information. Please see our Privacy Policy for more information.'
        },
        paragraph12: {
            title: 'SECURITY POLICY',
            description: 'Caregiving.com has security measures in place to protect the loss, misuse, and alteration of the information under Caregiving.com’s control. Although Caregiving.com has taken the steps described in our privacy policy to ensure that your personal information is delivered and disclosed only in accordance with our privacy policy, Caregiving.com cannot and does not guarantee that the personal information you provide will not be intercepted by others and decrypted.'
        },
        paragraph13: {
            title: 'COMMUNITY GUIDELINES',
            description: 'Caregiving.com has created community areas and group discussions so you can share your experiences, ask and / or answer questions, and share reviews of products and services and more.Our community includes people from a wide variety of experiences and backgrounds including professional service backgrounds.We appreciate that Caregiving.com’s community reflects the diversity of its users and is an interesting place to share, learn and interact.We share with respect and an open mind.We reserve the right to remove members who post inappropriate, obscene, vulgar and / or insulting comments and material.'
        },
        paragraph14: {
            title: 'USING OUR WEBSITE',
            description: 'Caregiving.com provides you with access to a great variety of resources.The materials, information, and links to third party sites, provided on our Website, including but not limited to information, documents, images, articles, advice, time - saving tips and checklists, easy - to - use tools, products, software, and services, if applicable (“Materials”), are provided either by Caregiving.com or by others and may be copyrighted and / or protected by other intellectual property rights.Caregiving.com hereby grants you limited permission to use the Materials provided by Caregiving.com subject to these Terms, as long as the use of such Materials is solely for your own informational use.Unless stated within the Materials, none of the Materials may be copied, reproduced, distributed, republished, downloaded, displayed, posted, or transmitted in any form or by any means, including but not limited to electronic, mechanical, photocopying, recording, or other means, without the prior express written permission of Caregiving.com and / or the appropriate owner.Also, you may not “mirror” any Materials contained on our Website or any other server without Caregiving.com’s prior written consent.'
        },
        paragraph15: {
            title: 'USING OUR DIRECTORY',
            subsection1: 'You are solely responsible for your interactions with other members.You understand that Caregiving.com currently does not conduct criminal background checks on its members.Caregiving.com also does not inquire into the backgrounds of all of its members or attempt to verify the statements of its members.Caregiving.com makes no representations or warranties as to the conduct of members.Caregiving.com reserves the right to conduct any criminal background check or other screenings(such as sex offender register searches), at any time and using available public records.',
            subsection2: 'In no event shall Caregiving.com be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and / or incidental, arising out of or relating to the conduct of you or anyone else in connection with the use of the service, including without limitation, bodily injury, emotional distress, and / or any other damages resulting from communications or meetings with other registered users of this service or persons you meet through this service.You agree to take reasonable precautions in all interactions with other members of the service, particularly if you decide to meet offline or in person.You should not provide your financial information (for example, your credit card or bank account information) to other members.We suggest you use a third - party payment system, like PayPal, to complete transactions.',
            subsection3: 'By posting your listing on Caregiving.com, you agree that it is in compliance with our guidelines listed below.',
            subsection4: 'We reserve the right to modify any ads in violation of our guidelines order to prevent abuse and keep the content appropriate for our general audience.This includes people of all ages, races, religions, and nationalities.All ads in violation of our guidelines are subject to being removed immediately and without prior notice.',
            subsection5: 'By posting a listing on Caregiving.com, you agree to the following statement: I agree that I will be solely responsible for the content of any listing that I post on this website.I will not hold the owner of this website responsible for any losses or damages to myself or to others that may result directly or indirectly from any ads that I post here.',
            subsection6: {
                title: 'By posting a listing on our site, you further agree to the following guidelines:',
                item1: '–You accurately describe the condition of all supplies, equipment, devices, books, DVDs and videos so the buyer understands what he or she is purchasing.',
                item2: '–If you are volunteering, you will be a responsible, helpful volunteer.',
                item3: '–If you are looking to hire a caregiver, you will conduct your own background and reference checks and create a contract between you and the caregiver you hire.',
                item4: '–You and your buyer will agree on price, payment terms and delivery of all products you list.',
                item5: '–No foul or otherwise inappropriate language will be tolerated.Ads in violation of this rule are subject to being removed immediately and without warning.If it was a paid ad, no refund will be issued.',
                item6: '–No racist, hateful, or otherwise offensive comments will be tolerated.',
                item7: '–No ad promoting activities that are illegal under the current laws of this state or country.',
                item8: '–Any ad that appears to be merely a test posting, a joke, or otherwise insincere or non - serious is subject to removal.',
                item9: 'We reserve the ultimate discretion as to which listings, if any, are in violation of these guidelines.',
                item10: 'We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.',
            }
        },
        paragraph16: {
            title: 'NO UNLAWFUL OR PROHIBITED USE',
            subsection1: 'While using our Website, you may not:',
            subsection2: '–restrict or inhibit any other user from using and enjoying our Website;',
            subsection3: '–post or transmit any unlawful, fraudulent, libelous, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, offensive, or otherwise objectionable information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability, constitute unauthorized practice of a professional service, or otherwise violate any local, state, national, or foreign law, including without limitation U.S.export control laws and regulations;',
            subsection4: '–post or transmit any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities or schemes, or other unsolicited commercial communication (except as otherwise expressly permitted by Caregiving.com), or engage in spamming, phishing, or flooding;',
            subsection5: '–use any robot, spider, scraper, or other automated means to access our Website or services for any purpose without our express written permission; however, this provision shall not apply to the indexing or updating of search engines;',
            subsection6: '–post or transmit any information or software that contains a virus, Trojan horse, worm, or other harmful component;',
            subsection7: '–post, publish, transmit, reproduce, distribute, or in any way exploit any information, software, or other material obtained through our Website for commercial purposes (other than as expressly permitted by the provider of such information, software, or other material);',
            subsection8: '–upload, post, publish, transmit, reproduce, or distribute in any way information, software, or other material obtained through our Website which is protected by copyright or other proprietary right, or derivative works with respect thereto, without obtaining permission of the copyright owner or right holder; or',
            subsection9: '–upload, post, publish, reproduce, transmit, or distribute in any way any component of our Website itself or derivative works with respect thereto without our prior written consent, as our Website is protected under U.S.copyright laws.'
        },
        paragraph17: {
            title: 'LINKS TO THIRD PARTY WEBSITES; ADVERTISERS; THIRD PARTY SERVICES',
            subsection1: 'In attempting to provide you with comprehensive and useful resources, our Website may contain links to third - party websites which are not under the control of Caregiving.com.Caregiving.com is not responsible for the content of or products provided by any linked site or any link contained in a linked site, or any changes or updates to such sites.Caregiving.com is providing these links to you only as a convenience, and the inclusion of any link does not imply that Caregiving.com endorses, guarantees, or accepts any responsibility for the content or products on such a third - party site.',
            subsection2: 'In addition, Caregiving.com will accept advertising on our Website.Any dealings that you have with advertisers found on Caregiving.com are between you and the advertiser and you acknowledge and agree that we are not liable for any loss or claim you may have against an advertiser.',
            subsection3: 'In connection with your use of our Website, you may be made aware of services, products, offers, and promotions provided by third parties, and not by Caregiving.com.If you decide to use third - party services or products, you are responsible for reviewing and understanding the terms and conditions governing any third - party services or products.You agree that the third party, and not Caregiving.com, is responsible for the performance of the third - party services or products.',
        },
        paragraph18: {
            title: 'CHANGES TO OUR WEBSITE',
            description: 'In an effort to continually improve our Website and its usefulness to you, we may add additional services or make changes to existing services.In the event that Caregiving.com makes such changes, these Terms shall apply to the new services and to any changes to existing services.'
        },
        paragraph19: {
            title: 'POSTING OF USER AND EXPERT CONTENT',
            subsection1: 'Certain areas of our Website may allow you to post content including your experiences, advice, expert content, recommendations, opinions, information, messages, or other material(“Your Content”).For instance, you may use our groups, forums, blogs or other community areas to post Your Content.Please be aware that these areas are public and not confidential.You may only post Your Content to public areas and where you have permission to post.You may not post any content that violates these Terms of Use or any third party rights.',
            subsection2: 'Caregiving.com does not claim ownership of any of Your Content that you may post. However, by posting Your Content to public areas, groups and / or forums on Caregiving.com, you grant Caregiving.com, its affiliates, partners, and distributors the right to share and promote Your Content and to attribute your postings to you.In addition to Your Content, you may submit feedback to us.We encourage you to provide us feedback.You agree that we may use in any manner and without limitation all comments, advice, recommendations, suggestions, complaints, and other feedback you provide relating to Our Website, and that Caregiving.com will own all intellectual property that we create based upon or incorporating your feedback.'
        },
        paragraph20: {
            title: 'NO DUTY TO MONITOR',
            description: 'You agree that we are not liable for content that is provided by others.While some community and other areas are monitored for relevance, we have no obligation to pre-screen content that may be posted in such areas and are not responsible for such postings. While we have no duty to pre-screen content, we have the right to refuse to post or to edit submitted content.We reserve the right to remove content for any reason, but we are not responsible for any failure or delay in removing such material.'
        },
        paragraph21: {
            title: 'PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT',
            subsection1: 'At Caregiving.com, we respect the intellectual property of others.If you believe that your copyrighted work has been copied and is accessible on Caregiving.com in a way that constitutes copyright infringement, please contact us to report possible copyright infringement.When contacting us, please provide Caregiving.com’s copyright agent the following information:',
            subsection2: '–A full description of the copyrighted work or other intellectual property that you claim has been infringed;',
            subsection3: '–An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;',
            subsection4: '–A description of where the material that you claim is infringing is located on the site; Your name, address, telephone number, and e - mail address;',
            subsection5: '–A statement by you that you have a good - faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and',
            subsection6: '–A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the copyright or intellectual property owner’s behalf. Caregiving.com’s agent can be reached as follows:',
            subsection7: 'By e - mail:',
            subsection8: 'christina@caregiving.com'
        },
        paragraph22: {
            title: 'WARRANTY DISCLAIMER',
            description: 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE AND TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR WEBSITE AND ALL MATERIALS, INFORMATION, POSTINGS, OPINIONS OR SERVICES ON OUR WEBSITE OR LINKED FROM OUR WEBSITE ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND AND CAREGIVING.COM AND ITS SUPPLIERS, THIRD PARTY SERVICE PROVIDERS, PARTNERS AND EXPERTS (HEREINAFTER COLLECTIVELY REFERRED TO AS “SUPPLIERS”) HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND TITLE.CAREGIVING.COM AND ITS SUPPLIERS MAKE NO WARRANTY THAT OUR WEBSITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, THAT ACCESS TO OUR WEBSITE OR ANY INFORMATION. POSTINGS, OPINIONS OR SERVICES LINKED FROM OUR WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR - FREE, OR THAT RESULTS FROM SUCH USE WILL BE ACCURATE OR RELIABLE, OR WILL BE SUITABLE FOR YOUR PURPOSES.IN ADDITION, CAREGIVING.COM IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY THIRD PARTY SERVICE PROVIDER, PARTNER, CAREGIVER, CARE RECIPIENT OR EXPERT WHETHER ONLINE OR OFFLINE.NO ORAL OR WRITTEN INFORMATION RECEIVED BY CAREGIVING.COM, ITS SUPPLIERS, OR OBTAINED OTHERWISE WILL CHANGE THIS DISCLAIMER.Some jurisdictions do not allow these limitations, so the above limitations may not apply to you.'
        },
        paragraph23: {
            title: 'LIMITATION OF LIABILITY',
            description: 'YOU EXPRESSLY UNDERSTAND AND AGREE THAT CAREGIVING.COM(OR CAREGIVING.COM’S OFFICERS, DIRECTORS, INVESTORS, SUBSIDIARIES, AGENTS, ASSIGNEES, REPRESENTATIVES, ADVERTISERS, EXPERTS, SUPPLIERS, PARTNERS OR EMPLOYEES) SHALL NOT BE LIABLE FOR ANY DIRECT DAMAGES OR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA, PROFITS, OR OTHER INTANGIBLE LOSSES(EVEN IF CAREGIVING.COM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE USE OF OR THE INABILITY TO USE OUR WEBSITE, REMOVAL OR TERMINATION OF OUR WEBSITE, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ERRORS OR OMISSIONS IN CONTENT ON OUR WEBSITE, STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR WEBSITE, INSTRUCTION, INFORMATION OR SERVICES PROVIDED BY OR LINKED FROM OUR WEBSITE OR ANY OTHER MATTER RELATING TO OUR WEBSITE.IN NO EVENT SHALL CAREGIVING.COM’S AND ITS SUPPLIERS’ CUMULATIVE LIABILITY FOR ANY AND ALL CLAIMS RELATING TO THE USE OF OUR WEBSITE EXCEED THE TOTAL AMOUNT OF FEES, IF ANY, THAT YOU PAID TO CAREGIVING.COM DURING THE PRIOR YEAR FOR THE SPECIFIC SERVICE AT ISSUE, OR $1, 000, WHICHEVER AMOUNT IS LESS.Some jurisdictions do not allow these limitations, so the above limitations may not apply to you.'
        },
        paragraph24: {
            title: 'NO PROFESSIONAL ADVICE',
            subsection1: 'CAREGIVING.COM OFFERS INFORMATION RELATED TO CARE GIVING WHICH INCLUDES MEDICAL, LEGAL, FINANCIAL, HOUSING, AND OTHER INFORMATION RELATED TO CARE GIVING.THIS INFORMATION IS PROVIDED TO YOU FOR EDUCATIONAL AND INFORMATIONAL PURPOSES ONLY.ANY INFORMATION FOUND ON CAREGIVING.COM IS NOT A SUBSTITUTE FOR OBTAINING SPECIFIC MEDICAL, LEGAL, FINANCIAL, OR OTHER PROFESSIONAL OR LICENSED SERVICES ADVICE DIRECTLY APPLICABLE TO YOUR SITUATION.YOU SHOULD NOT RELY ON ANY INFORMATION ON CAREGIVING.COM AS A SUBSTITUTE FOR SUCH SERVICES OR ADVICE.',
            subsection2: 'THE USE OF ANY INFORMATION PROVIDED ON THIS WEBSITE IS SOLELY AT YOUR OWN RISK.ALWAYS SEEK THE ADVICE OF A PHYSICIAN, ATTORNEY, FINANCIAL, ADVISOR OR OTHER PROFESSIONAL OR LICENSED ADVISOR. NEVER DISREGARD MEDICAL, LEGAL, FINANCIAL, OR OTHER PROFESSIONAL OR LICENSED ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ ON CAREGIVING.COM.',
            subsection3: 'NOTHING STATED OR POSTED ON THIS WEBSITE OR AVAILABLE THROUGH ANY SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICINE OR LAW; OR FINANCIAL, PROFESSIONAL, OR LICENSED SERVICES.FOR PURPOSES OF THIS AGREEMENT, THE PRACTICE OF MEDICINE INCLUDES, WITHOUT LIMITATION, PSYCHIATRY, PSYCHOLOGY, PSYCHOTHERAPY, OR PROVIDING HEALTH CARE TREATMENT, INSTRUCTIONS, DIAGNOSIS, PROGNOSIS, OR ADVICE.CAREGIVING.COM PROVIDES NO ASSURANCES THAT THE INFORMATION CONTAINED IN THIS WEBSITE WILL ALWAYS INCLUDE THE MOST RECENT FINDINGS OR DEVELOPMENTS WITH RESPECT TO THE PARTICULAR MATERIAL.Your access or use of our Website and our services does not create in any way a physician / patient, attorney / client, confidential, or privileged relationship, or any other relationship that would give rise to any duties on Caregiving.com’s part or the part of our suppliers, distributors, partners, and / or affiliates. We do not recommend or endorse any specific tests, physicians, attorneys, advisors, caregivers, procedures, opinions, advice, or other information that may appear on our Website.If you rely on any of the information provided by our Website, its employees, consultants, or its guests or visitors, you do so solely at your own risk.'
        },
        paragraph25: {
            title: 'INDEMNIFICATION',
            description: 'You shall indemnify, defend, and hold harmless Caregiving.com, its affiliates, and their respective officers, employees, and agents from any and all claims, demands, damages, costs, and liabilities, including reasonable attorneys’ fees, made by any third party due to or arising out of your acts or omissions arising out of your use of Caregiving.com.'
        },
        paragraph26: {
            title: 'GOVERNING LAW',
            description: 'You and Caregiving.com agree that any claim or controversy at law or equity that arises out of these Terms or your use of our Website shall be governed by Illinois law(without regard to conflict of law provisions) and that the United Nations Convention on Contracts for the International Sale of Goods shall have no applicability.You and Caregiving.com agree that all claims and actions related hereto shall be brought in the appropriate state or federal court located in Cook County, Illinois, and you agree and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim or action.'
        },
        paragraph27: {
            title: 'ASSIGNMENT',
            description: 'Caregiving.com may assign these Terms of Use at any time to any subsidiary, or any affiliated company, or as part of the sale to, merger with, or other transfer of Caregiving.com to another entity.You may not assign this agreement.'
        },
        paragraph28: {
            title: 'INTERNATIONAL USE',
            description: 'Caregiving.com is designed for users residing in the United States.We make no representation that content on Caregiving.com is appropriate or available for use in locations outside the United States.If you choose to access Caregiving.com from a location outside the United States, you do so on your own initiative and you are responsible for compliance with local laws.'
        },
        paragraph29: {
            title: 'MISCELLANEOUS',
            subsection1: 'These Terms constitute the entire agreement between you and Caregiving.com regarding this subject matter, and they govern your use of our Website.The failure of Caregiving.com to exercise or enforce any right or provision of these Terms does not constitute a waiver of such right or provision.If a court of competent jurisdiction finds any provision of these Terms to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of Caregiving.com or these Terms must be filed within one(1) year after such claim or cause of action arose or be forever barred.The section titles in these Terms are for convenience only and have no legal or contractual effect.',
            subsection2: '© 1996 - 2023 Caregiving.com.All rights reserved.'
        },
        paragraph30: {
            title: 'Caregiving.com Community Code of Conduct',
            subsection1: 'To make sure that this community remains a safe and encouraging place for everyone, we ask that you adhere to the following code of conduct while participating:',
            subsection2: '–Please choose a user name that’s tactful, or the Caregiving.com staff will ask you to make a change.',
            subsection3: '–Please refrain from using language that’s profane, abusive, obscene, harassing, threatening, hateful, or otherwise objectionable.',
            subsection4: '–Personal attacks are never allowed. Differences of opinion are welcome, as long as they’re presented respectfully. Attacking character and personal beliefs are unacceptable at all times.',
            subsection5: '–Revealing another member’s real name, address, phone number, e - mail address, or any other personally identifying information is forbidden. It’s an invasion of privacy.',
            subsection6: '–Do not use our community for solicitation purposes. This includes activities such as promoting businesses or professional services, sharing referral codes / links, and soliciting our members for research purposes.',
            subsection7: '–Do not use our community to generate traffic for other sites including your own. Making references, providing a link, or otherwise mentioning other sites of value to our members is permitted—as long as you don’t do so for your own self interest.',
            subsection8: '–Bloggers can add a link to their sites if they are republishing their own content.This link can be included at the bottom of the post and read like, “I originally published this content on my website, ABCaregiving.net.”',
            subsection9: '–Members can promote their personal fundraisers to raise money to offset medical costs by placing a listing in our Directory section.',
            subsection10: 'PLEASE NOTE',
            subsection11: '– It’s your responsibility to keep your contact information current.The staff at Caregiving.com may use your e - mail address to contact you regarding your posts made in our community.It’s our goal to help you to contribute within our set of rules.However, we reserve the right to disallow your participation in our community and terminate your account at Caregiving.com if, in our opinion, you violate our code of conduct or for any reason whatsoever in the sole discretion of Caregiving.com.'
        }
    }
}