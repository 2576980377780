export function getESTTime() {
  // Check for a stored timestamp in local storage
  const storedTimestamp = localStorage.getItem('nfcsTimestamp');
  let currentDate = storedTimestamp ? new Date(parseInt(storedTimestamp, 10)) : new Date();

  // Convert to EST timezone (-5 hours from UTC)
  let userTimezoneOffset = currentDate.getTimezoneOffset() * 60000; // offset in milliseconds
  let estTime = new Date(currentDate.getTime() + userTimezoneOffset - (5 * 60 * 60 * 1000));

  return estTime;
}

export function isBefore(month, day, hour) {
  const estTime = getESTTime();
  const targetTime = new Date(estTime.getFullYear(), month - 1, day, hour);

  if (estTime < targetTime) {
    return true;
  } else {
    return false;
  }
}

export function isAfter(month, day, hour) {
  const estTime = getESTTime();
  const targetTime = new Date(estTime.getFullYear(), month - 1, day, hour);

  if (estTime >= targetTime) {
    return true;
  } else {
    return false;
  }
}
