export const ProviderPageTranslations = {
  providerPageTranslations: {
    caregiverSupport: 'Apoyo del cuidador',
    insuranceCompany: 'Compañía de seguros',
    caregivingPromise: 'Por el bien de la industria, la Promesa de cuidado es nunca vender clientes potenciales ni cobrar una comisión a las comunidades.',
    more: 'más',
    about: 'Acerca de',
    follow: 'Suscríbete',
    following: 'Suscrito',
    save: 'Guardar',
    saved: 'Guardado',
    share: 'Compartir',
    createAccountCTA: {
      body: 'Obtenga el máximo provecho de caregiving.com creando una cuenta.',
      button: '¡Regístrese ahora!',
    },
    discoveryItems: {
      caregiving101: 'Cuidado 101',
      longTermCare: 'Atención a largo plazo',
      personalWellness: 'Bienestar personal',
      financialLegal: 'Finanzas y legal',
      healthMedical: 'Cuidado de la condición',
    },
    types: {
      contributor: 'Editor'
    }
  },
};
