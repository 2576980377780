import 'core-js';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import App from './app';
import './i18n';

// eslint-disable-next-line no-unused-vars
if (typeof window === 'object') {
  window.setTimestampInLocalStorage = (month, day, hour, timeZone) => {
    if (month === undefined) {
      localStorage.removeItem('nfcsTimestamp');
      return
    }
    // Create a date object for the specified time.
    const year = new Date().getFullYear(); // Assuming the current year
    let date = new Date(`${year}-${month}-${day}T${hour}:00:00${timeZone}`);

    // Store the timestamp in local storage
    localStorage.setItem('nfcsTimestamp', date.getTime());
  }
}

loadableReady(() => {
  const container = document.getElementById('container');
  hydrate(
    <Router>
      <App />
    </Router>
    , container
  )
});
