import { useRef } from 'react';

export const useDebounce = (func, wait) => {
  const timeoutRef = useRef(null);
  const lastPromiseReject = useRef(null);

  return (...args) => {

    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        if (lastPromiseReject.current) {
          lastPromiseReject.current(undefined);
        }
      }
      lastPromiseReject.current = resolve;

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        lastPromiseReject.current = null;
        // Handle both promise-returning and synchronous functions uniformly
        // eslint-disable-next-line no-undef
        Promise.resolve(func(...args)).then(resolve).catch(reject);
      }, wait);
    });
  };
};
