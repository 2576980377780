import React from 'react';
import styles from './footer-links.module.scss';

const FooterLinks = ({children, ...props}) => (
  <div className={styles.cgFooterLinks} {...props}>
    <div className={styles.cgFooterLinks__children}>{children}</div>
  </div>
);

const LabeledLinks = ({children, ...props}) => <div className={styles.cgFooterLinks__labeledLinks} {...props}>
  {children}
</div>

const IconLinks = ({children, ...props}) => <div className={styles.cgFooterLinks__iconLinks} {...props}>
  {children}
</div>

const Link = ({children, ...props}) => (<a className={styles.cgFooterLinksLink} {...props}>
  {children}
</a>);

const IconLink = ({children, ...props}) => (<a className={styles.cgFooterLinksIconLink} {...props}>
  {children}
</a>);


export default Object.assign(FooterLinks, {
  LabeledLinks: Object.assign(LabeledLinks, {Link}),
  IconLinks: Object.assign(IconLinks, {IconLink}),
});
