export const TermsOfUseTranslations = {
    termsOfUseTranslations: {
        pageTitle: 'Términos de uso',
        paragraph1: 'Bienvenido a Caregiving.com. Estamos muy contentos de que estés aquí con nosotros.',
        paragraph2: 'Estas Condiciones de uso se aplican a Caregiving.com y todos sus nombres comerciales, en lo sucesivo denominados colectivamente como "Caregiving.com".',
        paragraph3: 'Caregiving.com es una comunidad de cuidadores familiares que comparten sus historias, apoyo y soluciones.',
        paragraph4: 'Caregiving.com es un recurso informativo para ayudarlo a asistir a sus familiares y amigos con enfermedades crónicas y/o discapacidades. Si bien esperamos brindarle información y asistencia útiles, no brindamos asesoramiento médico, diagnóstico o tratamiento; asesoramiento legal, financiero o profesional; o consejos específicos sobre qué proveedores de servicios debe utilizar. Cualquier decisión que deba tomar sobre el cuidado de un familiar o amigo debe tomarse después de contratar y consultar con un proveedor de servicios profesional con licencia en función de sus necesidades específicas o realizar su propia revisión e investigación cuidadosas.',
        paragraph5: 'Estas Condiciones de uso se revisaron por última vez el 31 de mayo de 2018.',
        paragraph6: 'Estos Términos de uso ("Términos") son un acuerdo legal entre usted y Caregiving.com (nuestro "Sitio web") y rigen su uso de nuestro sitio web, ya sea como visitante, colaborador de contenido o como usuario registrado. El uso, la publicación de información y el acceso a nuestro sitio web están sujetos a su cumplimiento de estos Términos, así que léalos detenidamente antes de usar nuestro sitio web. Caregiving.com se reserva el derecho de limitar o cancelar su acceso a nuestro sitio web o cancelar o suspender su registro por incumplimiento de los términos y políticas publicados en nuestro sitio web, o por cualquier motivo en cualquier momento.',
        paragraph7: 'AL ACCEDER Y UTILIZAR NUESTRO SITIO WEB DE CUALQUIER MANERA, USTED ACEPTA ESTAR OBLIGADO POR ESTOS TÉRMINOS. SI NO ACEPTA TODOS LOS TÉRMINOS Y CONDICIONES, NO UTILICE NI PUBLIQUE INFORMACIÓN EN NUESTRO SITIO WEB DE NINGUNA MANERA.',
        paragraph9: {
            title: 'MODIFICACIONES A LAS CONDICIONES DE USO',
            description: 'Caregiving.com puede cambiar, modificar, actualizar, agregar o eliminar partes de estos Términos en cualquier momento. Consulte estos Términos periódicamente para ver si hay cambios. Su uso continuado de nuestro sitio web después de la publicación de cualquier cambio significará su aceptación de esos cambios.'
        },
        paragraph10: {
            title: 'REGISTRO',
            description: 'Para usar ciertas partes de nuestro sitio web (por ejemplo, participar en nuestros grupos), se le pedirá que cree una cuenta, lo que significa que debe proporcionar cierta información sobre usted, incluido su nombre, dirección de correo electrónico y contraseña.'
        },
        paragraph11: {
            title: 'POLÍTICA DE PRIVACIDAD',
            description: 'Caregiving.com respeta su deseo de privacidad y se encarga de proteger la información personal que nos proporciona. Caregiving.com no comparte su información. Por favor, consulte nuestra política de privacidad para más información.'
        },
        paragraph12: {
            title: 'POLÍTICA DE SEGURIDAD',
            description: 'Caregiving.com cuenta con medidas de seguridad para proteger la pérdida, mal uso y alteración de la información bajo el control de Caregiving.com. Aunque Caregiving.com ha tomado las medidas descritas en nuestra política de privacidad para garantizar que su información personal se entregue y divulgue solo de acuerdo con nuestra política de privacidad, Caregiving.com no puede garantizar y no garantiza que la información personal que proporcione no será interceptada por otros y descifrado.'
        },
        paragraph13: {
            title: 'DIRECTRICES DE LA COMUNIDAD',
            description: 'Caregiving.com ha creado áreas comunitarias y debates grupales para que pueda compartir sus experiencias, hacer y/o responder preguntas y compartir reseñas de productos y servicios y más. Nuestra comunidad incluye personas de una amplia variedad de experiencias y antecedentes, incluidos antecedentes de servicio profesional. Apreciamos que la comunidad de Caregiving.com refleje la diversidad de sus usuarios y sea un lugar interesante para compartir, aprender e interactuar. Compartimos con respeto y una mente abierta. , comentarios y material vulgares y/o insultantes.'
        },
        paragraph14: {
            title: 'USO DE NUESTRO SITIO WEB',
            description: 'Caregiving.com le brinda acceso a una gran variedad de recursos. Los materiales, la información y los enlaces a sitios de terceros, proporcionados en nuestro sitio web, incluidos, entre otros, información, documentos, imágenes, artículos, consejos, tiempo - Consejos para guardar y listas de verificación, herramientas, productos, software y servicios fáciles de usar, si corresponde ("Materiales"), son proporcionados por Caregiving.com o por otros y pueden tener derechos de autor y/o estar protegidos por otra propiedad intelectual. Caregiving.com le otorga permiso limitado para utilizar los Materiales proporcionados por Caregiving.com sujeto a estos Términos, siempre que el uso de dichos Materiales sea únicamente para su propio uso informativo. A menos que se indique en los Materiales, ninguno de los Materiales puede copiarse, reproducirse, distribuirse, volver a publicarse, descargarse, mostrarse, publicarse o transmitirse de cualquier forma o por cualquier medio, incluidos, entre otros, medios electrónicos, mecánicos, de fotocopias, de grabación u otros, sin el consentimiento previo r permiso expreso por escrito de Caregiving.com y/o del propietario apropiado. Además, no puede “duplicar” ningún Material contenido en nuestro sitio web o en cualquier otro servidor sin el consentimiento previo por escrito de Caregiving.com.'
        },
        paragraph15: {
            title: 'USO DE NUESTRO DIRECTORIO',
            subsection1: 'Usted es el único responsable de sus interacciones con otros miembros. Comprende que Caregiving.com actualmente no realiza verificaciones de antecedentes penales de sus miembros. Caregiving.com tampoco investiga los antecedentes de todos sus miembros ni intenta verificar las declaraciones de sus miembros. Caregiving.com no hace representaciones ni garantías en cuanto a la conducta de los miembros. Caregiving.com se reserva el derecho de realizar cualquier verificación de antecedentes penales u otras evaluaciones (como búsquedas de registros de delincuentes sexuales), en cualquier momento y utilizando registros públicos disponibles.',
            subsection2: 'En ningún caso, Caregiving.com será responsable de ningún daño, ya sea directo, indirecto, general, especial, compensatorio, consecuente y/o incidental, que surja de o esté relacionado con la conducta de usted o cualquier otra persona en relación con con el uso del servicio, incluidos, entre otros, lesiones corporales, angustia emocional y/o cualquier otro daño que resulte de comunicaciones o reuniones con otros usuarios registrados de este servicio o personas que conozca a través de este servicio. Usted acepta tomar precauciones razonables en todas las interacciones con otros miembros del servicio, especialmente si decide reunirse fuera de línea o en persona. No debe proporcionar su información financiera (por ejemplo, la información de su tarjeta de crédito o cuenta bancaria) a otros miembros. Le sugerimos que utilice un tercero: sistema de pago de parte, como PayPal, para completar transacciones.',
            subsection3: 'Al publicar su listado en Caregiving.com, acepta que cumple con nuestras pautas que se enumeran a continuación.',
            subsection4: 'Nos reservamos el derecho de modificar cualquier anuncio que infrinja nuestras pautas para evitar el abuso y mantener el contenido apropiado para nuestra audiencia general. Esto incluye personas de todas las edades, razas, religiones y nacionalidades. Todos los anuncios que infrinjan nuestros las directrices están sujetas a ser eliminadas inmediatamente y sin previo aviso.',
            subsection5: 'Al publicar una lista en Caregiving.com, usted acepta la siguiente declaración: Acepto que seré el único responsable del contenido de cualquier lista que publique en este sitio web. No responsabilizaré al propietario de este sitio web. por cualquier pérdida o daño a mí mismo o a otros que puedan resultar directa o indirectamente de cualquier anuncio que publique aquí.',
            subsection6: {
                title: 'Al publicar una lista en nuestro sitio, también acepta las siguientes pautas:',
                item1: '–Usted describe con precisión el estado de todos los suministros, equipos, dispositivos, libros, DVD y videos para que el comprador comprenda lo que está comprando.',
                item2: '–Si eres voluntario, serás un voluntario responsable y servicial.',
                item3: '–Si está buscando contratar a un cuidador, realizará sus propios antecedentes y verificación de referencias y creará un contrato entre usted y el cuidador que contrate.',
                item4: '–Usted y su comprador acordarán el precio, las condiciones de pago y la entrega de todos los productos que enumere.',
                item5: '–No se tolerará ningún lenguaje grosero o inapropiado. Los anuncios que violen esta regla están sujetos a ser eliminados de inmediato y sin previo aviso. Si era un anuncio pagado, no se emitirá ningún reembolso.',
                item6: '–No se tolerarán comentarios racistas, odiosos u ofensivos.',
                item7: '–Ningún anuncio que promueva actividades que sean ilegales según las leyes vigentes de este estado o país.',
                item8: '–Cualquier anuncio que parezca ser simplemente una publicación de prueba, una broma, o de otro modo falso o no serio está sujeto a eliminación.',
                item9: 'Nos reservamos la máxima discreción en cuanto a qué listados, si los hay, infringen estas pautas.',
                item10: 'No vendemos ni alquilamos su información personal a terceros para fines de marketing sin su consentimiento explícito.',
            }
        },
        paragraph16: {
            title: 'NO USO ILÍCITO O PROHIBIDO',
            subsection1: 'Mientras usa nuestro sitio web, no puede:',
            subsection2: '–restringir o impedir que cualquier otro usuario use y disfrute de nuestro sitio web;',
            subsection3: '–publicar o transmitir cualquier información ilegal, fraudulenta, calumniosa, difamatoria, obscena, pornográfica, profana, amenazante, abusiva, odiosa, ofensiva o objetable de cualquier tipo, incluidas, entre otras, las transmisiones que constituyen o fomentan una conducta que constituiría un delito penal, dar lugar a responsabilidad civil, constituir la práctica no autorizada de un servicio profesional o violar cualquier ley local, estatal, nacional o extranjera, incluidas, entre otras, las leyes y reglamentos de control de exportaciones de EE. UU.;',
            subsection4: '– publicar o transmitir anuncios, solicitudes, cartas en cadena, esquemas piramidales, oportunidades o esquemas de inversión u otra comunicación comercial no solicitada (excepto que Caregiving.com lo permita expresamente), o participar en spam, phishing o inundación; ',
            subsection5: '-utilizar cualquier robot, araña, raspador u otro medio automatizado para acceder a nuestro sitio web o servicios para cualquier propósito sin nuestro permiso expreso por escrito; sin embargo, esta disposición no se aplicará a la indexación o actualización de motores de búsqueda;.',
            subsection6: '–publicar o transmitir cualquier información o software que contenga un virus, caballo de Troya, gusano u otro componente dañino;',
            subsection7: '–publicar, publicar, transmitir, reproducir, distribuir o explotar de alguna manera cualquier información, software u otro material obtenido a través de nuestro sitio web con fines comerciales (aparte de lo expresamente permitido por el proveedor de dicha información, software o otro material);',
            subsection8: '- cargar, publicar, transmitir, reproducir o distribuir de cualquier manera información, software u otro material obtenido a través de nuestro sitio web que esté protegido por derechos de autor u otro derecho de propiedad, o trabajos derivados con respecto al mismo, sin obtener permiso del propietario de los derechos de autor o del titular de los derechos; o',
            subsection9: '–cargar, publicar, reproducir, transmitir o distribuir de cualquier forma cualquier componente de nuestro sitio web o trabajos derivados con respecto al mismo sin nuestro consentimiento previo por escrito, ya que nuestro sitio web está protegido por las leyes de derechos de autor de EE. UU.'
        },
        paragraph17: {
            title: 'ENLACES A SITIOS WEB DE TERCEROS; ANUNCIANTES; SERVICIOS DE TERCEROS',
            subsection1: 'Al intentar brindarle recursos integrales y útiles, nuestro sitio web puede contener enlaces a sitios web de terceros que no están bajo el control de Caregiving.com. Caregiving.com no es responsable del contenido o los productos proporcionados por cualquier sitio enlazado o cualquier enlace contenido en un sitio enlazado, o cualquier cambio o actualización de dichos sitios. , o acepta cualquier responsabilidad por el contenido o los productos en dicho sitio de terceros.',
            subsection2: 'Además, Caregiving.com aceptará publicidad en nuestro sitio web. Cualquier trato que tenga con los anunciantes que se encuentran en Caregiving.com es entre usted y el anunciante y usted reconoce y acepta que no somos responsables de ninguna pérdida o reclamo que usted puede tener contra un anunciante.',
            subsection3: 'En relación con su uso de nuestro sitio web, es posible que tenga conocimiento de servicios, productos, ofertas y promociones proporcionados por terceros, y no por Caregiving.com. Si decide utilizar servicios o productos de terceros, usted es responsable de revisar y comprender los términos y condiciones que rigen los servicios o productos de terceros. Usted acepta que el tercero, y no Caregiving.com, es responsable del desempeño de los servicios o productos de terceros.',
        },
        paragraph18: {
            title: 'CAMBIOS EN NUESTRO SITIO WEB',
            description: 'En un esfuerzo por mejorar continuamente nuestro sitio web y su utilidad para usted, podemos agregar servicios adicionales o realizar cambios en los servicios existentes. En caso de que Caregiving.com realice dichos cambios, estos Términos se aplicarán a los nuevos servicios y a cualquier cambio en los servicios existentes.'
        },
        paragraph19: {
            title: 'PUBLICACIÓN DE CONTENIDO DE USUARIOS Y EXPERTOS',
            subsection1: "Ciertas áreas de nuestro sitio web pueden permitirle publicar contenido, incluidas sus experiencias, consejos, contenido experto, recomendaciones, opiniones, información, mensajes u otro material ('Su contenido'). Por ejemplo, puede usar nuestros grupos, foros, blogs u otras áreas de la comunidad para publicar Su contenido. Tenga en cuenta que estas áreas son públicas y no confidenciales. Solo puede publicar Su contenido en áreas públicas y donde tenga permiso para publicar. No puede publicar ningún contenido que viole estos Condiciones de uso o cualquier derecho de terceros.",
            subsection2: 'Caregiving.com no reclama la propiedad de ninguno de sus Contenidos que pueda publicar. Sin embargo, al publicar Su Contenido en áreas públicas, grupos y/o foros en Caregiving.com, otorga a Caregiving.com, sus afiliados, socios y distribuidores el derecho de compartir y promocionar Su Contenido y atribuirle sus publicaciones. Además de Su contenido, puede enviarnos sus comentarios. Le animamos a que nos envíe sus comentarios. Usted acepta que podemos usar de cualquier manera y sin limitación todos los comentarios, consejos, recomendaciones, sugerencias, quejas y otros comentarios que proporcione en relación con Nuestro sitio web, y que Caregiving.com será el propietario de toda la propiedad intelectual que creemos en base a sus comentarios o que los incorpore.',
        },
        paragraph20: {
            title: 'SIN DEBER DE SUPERVISIÓN',
            description: 'Usted acepta que no somos responsables del contenido proporcionado por otros. Si bien algunas comunidades y otras áreas son monitoreadas por su relevancia, no tenemos la obligación de evaluar previamente el contenido que se puede publicar en dichas áreas y no somos responsables de tales publicaciones. Si bien no tenemos el deber de evaluar previamente el contenido, tenemos el derecho de negarnos a publicar o editar el contenido enviado. Nos reservamos el derecho de eliminar el contenido por cualquier motivo, pero no somos responsables de ninguna falla o retraso en la eliminación de dicho material. .'
        },
        paragraph21: {
            title: 'PROCEDIMIENTO PARA REALIZAR RECLAMACIONES DE INFRACCIÓN DE DERECHOS DE AUTOR',
            subsection1: 'En Caregiving.com, respetamos la propiedad intelectual de los demás. Si cree que su trabajo protegido por derechos de autor ha sido copiado y está accesible en Caregiving.com de una manera que constituye una infracción de derechos de autor, comuníquese con nosotros para informar una posible infracción de derechos de autor. Cuando se comunique con nosotros, proporcione al agente de derechos de autor de Caregiving.com la siguiente información:',
            subsection2: '–Una descripción completa del trabajo protegido por derechos de autor u otra propiedad intelectual que usted afirma que se ha infringido;',
            subsection13: '–Una firma electrónica o física de la persona autorizada para actuar en nombre del propietario de los derechos de autor u otro interés de propiedad intelectual;',
            subsection4: '–Una descripción de dónde se encuentra en el sitio el material que usted afirma que está infringiendo; Su nombre, dirección, número de teléfono y dirección de correo electrónico;',
            subsection5: '–Una declaración suya de que cree de buena fe que el uso en disputa no está autorizado por el propietario de los derechos de autor, su agente o la ley; y',
            subsection6: '–Una declaración suya, hecha bajo pena de perjurio, de que la información anterior en su aviso es precisa y que usted es el propietario de los derechos de autor o de la propiedad intelectual o está autorizado para actuar en nombre del propietario de los derechos de autor o de la propiedad intelectual. Se puede contactar al agente de Caregiving.com de la siguiente manera:',
            subsection7: 'Por correo electrónico:',
            subsection8: 'christina@caregiving.com'
        },
        paragraph22: {
            title: 'EXENCIÓN DE RESPONSABILIDAD DE LA GARANTÍA',
            description: 'EXCEPTO QUE SE PROPORCIONE EXPRESAMENTE LO CONTRARIO Y EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, NUESTRO SITIO WEB Y TODOS LOS MATERIALES, INFORMACIÓN, PUBLICACIONES, OPINIONES O SERVICIOS EN NUESTRO SITIO WEB O VINCULADOS DESDE NUESTRO SITIO WEB SE PROPORCIONAN "TAL CUAL" Y "SEGÚN DISPONIBILIDAD" SIN GARANTÍA DE CUALQUIER TIPO Y CAREGIVING.COM Y SUS PROVEEDORES, TERCEROS PROVEEDORES DE SERVICIOS, SOCIOS Y EXPERTOS (EN ADELANTE DENOMINADOS COLECTIVAMENTE "PROVEEDORES") POR EL PRESENTE RENUNCIA EXPRESAMENTE TODAS LAS GARANTÍAS DE CUALQUIER TIPO, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUYENDO PERO NO LIMITADO A GARANTÍAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO, NO VIOLACIÓN Y TÍTULO.CAREGIVING.COM Y SUS PROVEEDORES NO GARANTIZAN QUE NUESTRO SITIO WEB CUMPLA CON SUS REQUISITOS O EXPECTATIVAS, QUE ACCESO A NUESTRO SITIO WEB O CUALQUIER INFORMACIÓN. LAS PUBLICACIONES, OPINIONES O SERVICIOS VINCULADOS DESDE NUESTRO SITIO WEB SERÁN ININTERRUMPIDOS, OPORTUNOS, SEGUROS O LIBRES DE ERRORES, O LOS RESULTADOS DE DICHO USO SERÁN EXACTOS O CONFIABLES, O SERÁN ADECUADOS PARA SUS PROPÓSITOS. ADEMÁS, CAREGIVING.COM NO ES RESPONSABLE DE LA CONDUCTA DE CUALQUIER TERCERO PROVEEDOR DE SERVICIOS, SOCIO, CUIDADOR, RECEPTOR DE ATENCIÓN O EXPERTO YA SEA EN LÍNEA O FUERA DE LÍNEA. NINGUNA INFORMACIÓN ORAL O ESCRITA RECIBIDA POR CAREGIVING.COM, SUS PROVEEDORES U OBTENIDA DE OTRO MODO CAMBIARÁ ESTA EXENCIÓN DE RESPONSABILIDAD. Algunas jurisdicciones no permita estas limitaciones, por lo que es posible que las limitaciones anteriores no se apliquen a usted.'
        },
        paragraph23: {
            title: 'LIMITACIÓN DE RESPONSABILIDAD',
            description: 'USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE CARREGIVING.COM (O LOS FUNCIONARIOS, DIRECTORES, INVERSORES, SUBSIDIARIAS, AGENTES, ASIGNADOS, REPRESENTANTES, ANUNCIANTES, EXPERTOS, PROVEEDORES, SOCIOS O EMPLEADOS DE CARREGIVING.COM) NO SERÁN RESPONSABLES DE CUALQUIER DAÑO DIRECTO O CUALQUIER DAÑO ESPECIAL, DIRECTO, INDIRECTO, INCIDENTAL, CONSECUENTE O EJEMPLAR, INCLUYENDO PERO SIN LIMITARSE A CUALQUIER DAÑO QUE RESULTE DE LA PÉRDIDA DE USO, DATOS, BENEFICIOS U OTRAS PÉRDIDAS INTANGIBLES (INCLUSO SI CARIGIVING.COM HA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS), RESULTANTES DEL USO O LA IMPOSIBILIDAD DE UTILIZAR NUESTRO SITIO WEB, LA ELIMINACIÓN O TERMINACIÓN DE NUESTRO SITIO WEB, EL ACCESO NO AUTORIZADO O LA ALTERACIÓN DE SUS TRANSMISIONES O DATOS, ERRORES U OMISIONES EN EL CONTENIDO DE NUESTRO SITIO WEB, DECLARACIONES O CONDUCTA DE CUALQUIER TERCERO PARTE EN NUESTRO SITIO WEB, INSTRUCCIONES, INFORMACIÓN O SERVICIOS PROPORCIONADOS O VINCULADOS DESDE NUESTRO SITIO WEB O CUALQUIER OTRO ASUNTO RELACIONADO CON NUESTRO SITIO WEB. EN NINGÚN CASO CAREGIVING.COM Y SUS PROVEEDORES LA RESPONSABILIDAD ACUMULADA POR CUALQUIER Y TODAS LAS RECLAMACIONES RELACIONADAS CON EL USO DE NUESTRO SITIO WEB EXCEDE LA CANTIDAD TOTAL DE LAS TARIFAS, SI LAS HAY, QUE USTED PAGÓ A CAREGIVING.COM DURANTE EL AÑO ANTERIOR POR EL SERVICIO ESPECÍFICO EN CUESTIÓN, O $1,000, CUALQUIERA QUE LA CANTIDAD SEA MENOS. Algunas jurisdicciones no permiten estas limitaciones, por lo que es posible que las limitaciones anteriores no se apliquen a usted.'
        },
        paragraph24: {
            title: 'SIN CONSEJOS PROFESIONALES',
            subsection1: 'CAREGIVING.COM OFRECE INFORMACIÓN RELACIONADA CON LA PRESTACIÓN DE ATENCIÓN QUE INCLUYE INFORMACIÓN MÉDICA, LEGAL, FINANCIERA, DE VIVIENDA Y OTRA INFORMACIÓN RELACIONADA CON LA PRESTACIÓN DE ATENCIÓN. NO ES UN SUSTITUTO PARA OBTENER CONSEJOS ESPECÍFICOS MÉDICOS, LEGALES, FINANCIEROS U OTROS SERVICIOS PROFESIONALES O CON LICENCIA DIRECTAMENTE APLICABLES A SU SITUACIÓN. NO DEBE CONFIAR EN NINGUNA INFORMACIÓN EN CARIGIVING.COM COMO SUSTITUTO DE DICHOS SERVICIOS O CONSEJOS.',
            subsection2: 'EL USO DE CUALQUIER INFORMACIÓN PROPORCIONADA EN ESTE SITIO WEB ES ÚNICAMENTE BAJO SU PROPIO RIESGO. BUSQUE SIEMPRE EL CONSEJO DE UN MÉDICO, ABOGADO, ASESOR FINANCIERO U OTRO ASESOR PROFESIONAL O CON LICENCIA. NUNCA DEJES DE IGUALAR LOS CONSEJOS MÉDICOS, LEGALES, FINANCIEROS U OTROS PROFESIONALES O CON LICENCIA NI DEMORES EN BUSCARLOS POR ALGO QUE HAS LEÍDO EN CARIGIVING.COM.',
            subsection3: 'NADA DE LO ESTABLECIDO O PUBLICADO EN ESTE SITIO WEB O DISPONIBLE A TRAVÉS DE CUALQUIER SERVICIO TIENE LA INTENCIÓN DE SER, Y NO DEBE CONSIDERARSE COMO, LA PRÁCTICA DE LA MEDICINA O LA LEY; O SERVICIOS FINANCIEROS, PROFESIONALES O CON LICENCIA. A LOS EFECTOS DE ESTE ACUERDO, LA PRÁCTICA DE LA MEDICINA INCLUYE, ENTRE OTROS, LA PSIQUIATRÍA, LA PSICOLOGÍA, LA PSICOTERAPIA O PROPORCIONAR TRATAMIENTO DE ATENCIÓN MÉDICA, INSTRUCCIONES, DIAGNÓSTICO, PRONÓSTICO O CONSEJOS. GARANTÍAS DE QUE LA INFORMACIÓN CONTENIDA EN ESTE SITIO WEB SIEMPRE INCLUYE LOS HALLAZGOS O DESARROLLOS MÁS RECIENTES CON RESPECTO AL MATERIAL EN PARTICULAR. Su acceso o uso de nuestro sitio web y nuestros servicios no crea de ninguna manera un médico / paciente, abogado / cliente, información confidencial , o relación privilegiada, o cualquier otra relación que daría lugar a obligaciones por parte de Caregiving.com o por parte de nuestros proveedores, distribuidores, socios y/o afiliados. No recomendamos ni respaldamos ninguna prueba específica, médicos, abogados, asesores, cuidadores, procedimientos, opiniones, consejos u otra información que pueda aparecer en nuestro sitio web. Si confía en la información proporcionada por nuestro sitio web, sus empleados, consultores, o sus invitados o visitantes, lo hace bajo su propio riesgo.'
        },
        paragraph25: {
            title: 'INDEMNIFICACIÓN',
            description: 'Usted indemnizará, defenderá y eximirá de responsabilidad a Caregiving.com, sus afiliados y sus respectivos funcionarios, empleados y agentes de todos y cada uno de los reclamos, demandas, daños, costos y responsabilidades, incluidos los honorarios razonables de los abogados, realizados por cualquier tercero debido a o como resultado de sus actos u omisiones que surjan de su uso de Caregiving.com.'
        },

        paragraph26: {
            title: 'LEY APLICABLE',
            description: 'Usted y Caregiving.com acuerdan que cualquier reclamo o controversia de derecho o equidad que surja de estos Términos o su uso de nuestro sitio web se regirá por la ley de Illinois (sin tener en cuenta las disposiciones sobre conflictos de leyes) y que las Naciones Unidas La Convención sobre Contratos para la Venta Internacional de Bienes no tendrá aplicabilidad. Usted y Caregiving.com acuerdan que todos los reclamos y acciones relacionados con el presente se presentarán ante el tribunal estatal o federal apropiado ubicado en el condado de Cook, Illinois, y usted acepta y se somete a el ejercicio de la jurisdicción personal de dichos tribunales con el fin de litigar cualquier reclamación o acción de este tipo.'
        },
        paragraph27: {
            title: 'ASIGNACIÓN',
            description: 'Caregiving.com puede asignar estos Términos de uso en cualquier momento a cualquier subsidiaria o compañía afiliada, o como parte de la venta, fusión u otra transferencia de Caregiving.com a otra entidad. Usted no puede asignar este acuerdo.'
        },
        paragraph28: {
            title: 'USO INTERNACIONAL',
            description: 'Caregiving.com está diseñado para usuarios que residen en los Estados Unidos. No garantizamos que el contenido de Caregiving.com sea apropiado o esté disponible para su uso en ubicaciones fuera de los Estados Unidos. Si elige acceder a Caregiving.com desde una ubicación fuera de los Estados Unidos, lo hace por su propia iniciativa y es responsable del cumplimiento de las leyes locales.'
        },
        paragraph29: {
            title: 'VARIOS',
            subsectio1: 'Estos Términos constituyen el acuerdo completo entre usted y Caregiving.com con respecto a este tema, y rigen su uso de nuestro sitio web. El hecho de que Caregiving.com no ejerza o haga cumplir cualquier derecho o disposición de estos Términos no constituye un renuncia a dicho derecho o disposición. Si un tribunal de jurisdicción competente determina que alguna disposición de estos Términos no es válida, las partes, sin embargo, acuerdan que el tribunal debe esforzarse por hacer efectivas las intenciones de las partes tal como se refleja en la disposición, y las demás disposiciones de estos Términos permanecen en pleno vigor y efecto. Independientemente de cualquier estatuto o ley que establezca lo contrario, cualquier reclamo o causa de acción que surja o esté relacionado con el uso de Caregiving.com o estos Términos debe presentarse dentro de un (1) año después de dicho reclamo o causa de acción surgió o prescribirá para siempre. Los títulos de las secciones en estos Términos son solo por conveniencia y no tienen ningún efecto legal o contractual.',
            subsection2: '© 1996 - 2023 Caregiving.com. Todos los derechos reservados.'
        },
        paragraph30: {
            title: 'Código de conducta de la comunidad de Caregiving.com',
            subsection1: 'Para asegurarnos de que esta comunidad siga siendo un lugar seguro y alentador para todos, le pedimos que cumpla con el siguiente código de conducta mientras participa:',
            subsection2: '–Por favor, elija un nombre de usuario que tenga tacto, o el personal de Caregiving.com le pedirá que haga un cambio.',
            subsection3: '–Por favor, absténgase de utilizar un lenguaje profano, abusivo, obsceno, acosador, amenazante, odioso u objetable de otro modo.',
            subsection4: '–Los ataques personales nunca están permitidos. Las diferencias de opinión son bienvenidas, siempre que se presenten con respeto. Atacar el carácter y las creencias personales es inaceptable en todo momento.',
            subsection5: '–Está prohibido revelar el nombre real, la dirección, el número de teléfono, la dirección de correo electrónico o cualquier otra información de identificación personal de otro miembro. Es una invasión de la privacidad.',
            subsection6: '–No utilice nuestra comunidad con fines de solicitud. Esto incluye actividades como la promoción de negocios o servicios profesionales, el intercambio de códigos/enlaces de referencia y la solicitud de nuestros miembros con fines de investigación.',
            subsection7: '–No utilice nuestra comunidad para generar tráfico para otros sitios, incluido el suyo. Está permitido hacer referencias, proporcionar un enlace o mencionar otros sitios de valor para nuestros miembros, siempre que no lo haga por su propio interés.',
            subsection8: '–Los blogueros pueden agregar un enlace a sus sitios si vuelven a publicar su propio contenido. Este enlace se puede incluir en la parte inferior de la publicación y decir: "Originalmente publiqué este contenido en mi sitio web, ABCaregiving.net". ',
            subsection9: '–Los miembros pueden promover sus recaudadores de fondos personales para recaudar dinero para compensar los costos médicos colocando una lista en nuestra sección de Directorio.',
            subsection10: 'TENGA EN CUENTA',
            subsection11: '– Es su responsabilidad mantener su información de contacto actualizada. El personal de Caregiving.com puede usar su dirección de correo electrónico para contactarlo con respecto a sus publicaciones realizadas en nuestra comunidad. Nuestro objetivo es ayudarlo a contribuir dentro de nuestro conjunto de reglas. Sin embargo, nos reservamos el derecho de rechazar su participación en nuestra comunidad y cancelar su cuenta en Caregiving.com si, en nuestra opinión, viola nuestro código de conducta o por cualquier motivo, a la sola discreción de Caregiving.com.'
        }
    }
}