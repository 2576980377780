import React, {useEffect, useState} from 'react';
import Vision from '@/assets/images/about/about-vision.png';
import Mission from '@/assets/images/about/about-mission.png';
import Banner from '@/assets/images/about/about-banner.png';
import PinkyPromise from '@/assets/images/about/about-pinkies.png';
import MobileBanner from '@/assets/images/about/about-mobile-banner.png';
import Books from '@/assets/images/about/books.png';
import Family from '@/assets/images/about/family.png';
import Support from '@/assets/images/about/support.png';
import CaregiverPromiseIcon from '@/assets/images/about/caregiving-promise.png';
import StatusQuo from '@/assets/images/about/overturn-status-quo.png';
import MobileStatusQuo from '@/assets/images/about/overturn-status-quo-mobile.png';
import styles from './about.module.scss';
import { useHttpGet } from '@/hooks/use-http';
import {ARTICLE_GROUP} from '@/constants/endpoints.constant';
import {LayoutObservable} from '@/observables/layout.observable';
import {useTranslation} from 'react-i18next';
import Loading from '@/components/atoms/loading';
import FullImageBanner from '@/components/molecules/full-image-banner';
import Image from '@/components/atoms/image';
import CardShelf from '@/components/molecules/card-shelf';


const About = () => {
  const {execute: getCompanyNews, data, loading, error} = useHttpGet();
  const {t} = useTranslation();
  const [statusQuoImg, setStatusQuoImg] = useState({});
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    LayoutObservable.toggleDisplayNavbar(false);
  }, []);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
      if (dimensions.width < 768.98) {
        setStatusQuoImg(MobileStatusQuo)
        setIsMobile(true)
      } else {
        setStatusQuoImg(StatusQuo)
        setIsMobile(false)
      }
  }, [dimensions]);

  useEffect(() => {
    getCompanyNews(ARTICLE_GROUP('company-news'))
  }, [])

  const renderCompanyNews = () => {
    if (loading || !!error || !data || !data.articles || data.articles.length === 0) {
      return <Loading/>
    }

    return <>
      <div className={styles.cgOurTeam__news}>
        <CardShelf list={data?.articles} className={styles.cgOurTeam__newsBody} title={t('aboutTranslations.companyTitle')}/>
      </div>
    </>
  }
  
  return (
    <div className={styles.cgOurTeam}>
      <div className={styles.cgOurTeam__headerGrid}>
        {<FullImageBanner src={Banner} mobileImg={MobileBanner} className={isMobile ? styles.cgOurTeam__headerGrid__mobileBanner : styles.cgOurTeam__headerGrid__banner}>
          <div className={isMobile ? styles.cgOurTeam__headerGrid__mobileBanner : styles.cgOurTeam__headerGrid__banner}>
            <h2 className={styles.cgOurTeam__headerGrid__banner_header}>{t('aboutTranslations.banner.title')}</h2>
          </div>
        </FullImageBanner>}
        <div className={styles.cgOurTeam__headerGrid__wrap}>
        <div className={styles.cgOurTeam__headerGrid__container}>
          <div className={styles.cgOurTeam__headerGrid__container__leftText}>
            <h3>{t('aboutTranslations.LeftText.title')}</h3>
            <p>{t('aboutTranslations.LeftText.text')}</p>
          </div>
          <Image src={Mission} className={styles.cgOurTeam__headerGrid__container__leftImage} />
        </div>
        <div className={styles.cgOurTeam__headerGrid__container} >
          <div className={styles.cgOurTeam__headerGrid__container__rightText}>
            <h3>{t('aboutTranslations.RightText.title')}</h3>
            <h2>{t('aboutTranslations.RightText.text')}</h2>
          </div>
          <Image src={Vision} className={styles.cgOurTeam__headerGrid__container__rightImage} />
        </div>
        </div>
      </div>
      <div className={styles.cgOurTeam__bodyGrid}>
        <div className={styles.cgOurTeam__bodyGrid__whyBanner}>
          <h3>{t('aboutTranslations.whyBanner.title')}</h3>
          <Image src={statusQuoImg} alt={'we want to overturn the status quo'} className={styles.cgOurTeam__bodyGrid__whyBanner__image}/>
          <p>{t('aboutTranslations.whyBanner.text')}</p>
        </div>
        <div className={styles.cgOurTeam__bodyGrid__howTitle}><h3>{t('aboutTranslations.howTitle.title')}</h3></div>
        <div className={styles.cgOurTeam__bodyGrid__howStandOut
        }>
          <Image src={Books} alt={'books icon'} className={styles.cgOurTeam__bodyGrid__howStandOut__image}/>
          <p>{t('aboutTranslations.howTitle.howStandOut')}</p>
        </div>
        <div className={styles.cgOurTeam__bodyGrid__howSupport}>
          <Image src={Support} alt={'support icon'} className={styles.cgOurTeam__bodyGrid__howSupport__image}/>
          <p>{t('aboutTranslations.howTitle.howSupport')}</p>
        </div>
        <div className={styles.cgOurTeam__bodyGrid__howFamily}>
          <Image src={Family} alt={'family icon'} className={styles.cgOurTeam__bodyGrid__howFamily__image}/>
          <p>{t('aboutTranslations.howTitle.howFamily')}</p>
        </div>
        <div className={styles.cgOurTeam__bodyGrid__caregivingPromise}>
          <div className={styles.cgOurTeam__bodyGrid__caregivingPromise__wrapper}>
            <Image src={CaregiverPromiseIcon} alt={'promise icon'}/>
            <h3>{t('aboutTranslations.caregivingPromise.title')}</h3>
            <p>{t('aboutTranslations.caregivingPromise.text')}</p>
          </div>
          <Image src={PinkyPromise} alt={'promise icon'} className={styles.cgOurTeam__bodyGrid__caregivingPromise__image} />
        </div>
      </div>
      { renderCompanyNews() }
    </div>
  )
};

export default About;
