import React from 'react';
import PropTypes from 'prop-types';
import crStyles from './completion-time.module.scss';
import {PostTypeIds} from '@/constants/post-type-ids.constant';
import OpenBookIcon from '@/assets/icons/open-book-content.svg'
import VideoPlayIcon from '@/assets/icons/videos-play-icon.svg'
import PodcastIcon from '@/assets/icons/mic-podcast.svg'

const CompletionTime = ({time = 0, type = PostTypeIds.ARTICLE}) => {
  const icon = () => {
    switch (type) {
      case PostTypeIds.PODCAST || PostTypeIds.PODCAST:
        return <PodcastIcon />;
      case PostTypeIds.VIDEO:
        return <VideoPlayIcon />;
      default:
        return <OpenBookIcon />;
    }
  }

  return (
    <div className={crStyles.cgCompletionTime}>
      {icon()} <span>{time || ''} {time ? 'min' : ''}</span>
    </div>
  );
}

CompletionTime.propTypes = {
  type: PropTypes.string,
  time: PropTypes.number,
};

export default CompletionTime;
