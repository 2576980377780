export const ChampionsTranslations = {
    championTranslations: {
        pageTitle: 'Campeonas',
        championBanner: {
            title:'Conviértete en una campeona de Caregiving.com',
            description: 'Cuéntenos sobre usted y un miembro de nuestro equipo de Crecimiento de la comunidad se comunicará con usted.',
            buttonText: 'Aprende más'
        },
        description: {
            title: 'Uniendo a los cuidadores y los recursos de la comunidad',
            paragraph1: 'Caregiving.com se dedica a brindar los recursos correctos a través de tecnología de punta para ayudar a millones de cuidadores a vivir una vida mejor para ellos y sus seres queridos. Estamos haciendo esto por:',
            list: {
                item1: 'Compartir historias de cuidadores de una manera que les permita a las personas saber que no están solos en su viaje',
                item2: 'Brindar sugerencias, herramientas y consejos prácticos para los desafíos cotidianos.',
                item3: 'Desarrollar tecnología que conecte a los cuidadores entre sí, así como con los recursos en sus comunidades locales.'
            }
        }
    }
}