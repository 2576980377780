import { BehaviorSubject } from 'rxjs';
import { LocalStorageKeys } from '@/constants/local-storage-keys.constant';
import LocalStorage from '@/observables/localStorage.observable';

const supportLayer = LocalStorage.getItem(LocalStorageKeys.SUPPORT_LAYER);
const slInfo = supportLayer && JSON.parse(supportLayer) || {};

const slSubject = new BehaviorSubject(slInfo);

export const SupportLayerObservable = Object.freeze({
  updateSupportLayer: (data) => {
    if (data) {
      LocalStorage.setItem(LocalStorageKeys.SUPPORT_LAYER, JSON.stringify(data));
      slSubject.next(data);
    } else {
      LocalStorage.removeItem(LocalStorageKeys.SUPPORT_LAYER);
      slSubject.next({});
    }
  },
  addSupportLayerPartner: (partner) => {
    const list = slSubject.value?.list || [];
    if (partner && list.indexOf(partner) === -1) {
      list.push(partner);
      LocalStorage.setItem(LocalStorageKeys.SUPPORT_LAYER, JSON.stringify({ list }));
      slSubject.next({ list });
    }
  },
  removeSupportLayerPartner: (partner) => {
    const list = slSubject.value?.list || [];
    if (list.length === 0 || !partner) {
      return; // list already empty, nothing to do
    }
    const pos = list.indexOf(partner);
    if (pos !== -1) {
      list.splice(pos, 1); // remove partner from array at pos
      LocalStorage.setItem(LocalStorageKeys.SUPPORT_LAYER, JSON.stringify({ list }));
      slSubject.next({ list });
    }
  },
  hasSupportLayer: (subject, value) => {
    if (!subject) {
      return false;
    }
    if (!subject?.list) {
      return false
    }
    return subject.list.indexOf(value) !== -1;
  },
  supportLayer$: slSubject.asObservable(),
});
