import {TermsOfUseTranslations} from './terms-of-use';
import {PrivacyPolicyTranslations} from './privacy-policy';

const Index = {
    atomsTranslations: {
        collection: 'Collection',
        back: 'Back',
        //begin collapsible tabs
        more: 'More',
        articles: 'Articles',
        community: 'Community',
        caregivingStories: 'Caregiver Stories',
        research: 'Research',
        awards2020: '2020 Awards',
        chat: 'Chat',
        //end collapsible tabs
        concert: {
            title: 'Music We Remember Ft. John Van Beek',
            paragraph1: 'LIVE',
            paragraph2: 'An exclusive, live performance concert series with music by John Van Beek'
        },
        reprintPermission: {
            title: 'Reprint permission',
            paragraph1: 'Did you read an article on Caregiving.com that you would like to reprint in your print publication? Our articles, except for the blogs of our family caregivers, are available for you to use in your newsletters and brochures.',
            instructions: {
                title: 'Here’s how to do it:',
                step1: 'E-mail Caregiving.com to let us know which article you’d like to use.',
                step2: 'Include this credit with the article: “Reprinted with permission from Caregiving.com. Caregiving.com cares for you as you care for family and friends.” (Please note: You must include a link back to the original article on Caregiving.com.)'
            },
            paragraph2: 'That’s it!',
            paragraph3: 'Thanks very much; we are thrilled that you find our site valuable!'
        },
        resourceTypeSelector: {
            placeholder: 'e.g. Senior living, Legal services',
        },
        search: {
            search: 'Search',
            noResults: 'No results...',
            searching: 'Searching'
        }
    }
}

const AtomsTranslations = Object.assign(Index, TermsOfUseTranslations, PrivacyPolicyTranslations)

export default AtomsTranslations;
