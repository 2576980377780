import { BehaviorSubject } from 'rxjs';

const showHeaderSubject = new BehaviorSubject(true);
const showNavbarSubject = new BehaviorSubject(true);
const showNavPillsSubject = new BehaviorSubject(false);
const navPillsLinksSubject = new BehaviorSubject([]);
const showSideMenuSubject = new BehaviorSubject(true);
const showLayoutSubject = new BehaviorSubject(true);
const showFooterSubject = new BehaviorSubject(true);
const showRightPanelSubject = new BehaviorSubject(false);
const footerContainerClassSubject = new BehaviorSubject('');
const nfcsHeroEnabledSubject = new BehaviorSubject(false);
const nfcsHeroInViewSubject = new BehaviorSubject(false);
const nfcsBarEnabledSubject = new BehaviorSubject(false);
const layoutRef = new BehaviorSubject(null);
export const LayoutObservable = Object.freeze({
  toggleDisplayHeader: (shouldShow) => {
    showHeaderSubject.next(shouldShow);
  },
  toggleDisplayNavbar: (shouldShow) => {
    showNavbarSubject.next(shouldShow);
  },
  toggleDisplayNavPills: (shouldShow) => {
    showNavPillsSubject.next(shouldShow);
  },
  setNavPillsLinks: (links) => {
    navPillsLinksSubject.next(links);
  },
  toggleDisplaySideMenu: (shouldShow) => {
    showSideMenuSubject.next(shouldShow);
  },
  toggleDisplayLayout: (shouldShow) => {
    showLayoutSubject.next(shouldShow);
  },
  toggleDisplayFooter: (shouldShow) => {
    showFooterSubject.next(shouldShow);
  },
  toggleDisplayRightPanel: (shouldShow) => {
    showRightPanelSubject.next(shouldShow);
  },
  setFooterContainerClass: (className) => {
    footerContainerClassSubject.next(className);
  },
  setNfcsHeroEnabled: (value) => {
    nfcsHeroEnabledSubject.next(value);
  },
  setNfcsHeroInView: (value) => {
    nfcsHeroInViewSubject.next(value);
  },
  setNfcsBarEnabled: (value) => {
    value = false;
    nfcsBarEnabledSubject.next(value);
  },
  setLayoutRef: (ref) => {
    layoutRef.next(ref);
  },
  footerContainerClass$: footerContainerClassSubject.asObservable(),
  showHeader$: showHeaderSubject.asObservable(),
  showNavbar$: showNavbarSubject.asObservable(),
  showNavPills$: showNavPillsSubject.asObservable(),
  navPillsLinks$: navPillsLinksSubject.asObservable(),
  showSideMenu$: showSideMenuSubject.asObservable(),
  showLayout$: showLayoutSubject.asObservable(),
  showFooter$: showFooterSubject.asObservable(),
  showRightPanel$: showRightPanelSubject.asObservable(),
  nfcsHeroEnabled$: nfcsHeroEnabledSubject.asObservable(),
  nfcsHeroInView$: nfcsHeroInViewSubject.asObservable(),
  nfcsHeroInViewSync: nfcsHeroInViewSubject,
  nfcsBarEnabled$: nfcsBarEnabledSubject.asObservable(),
  layoutRef$: layoutRef.asObservable(),
});
