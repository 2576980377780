export const AccountTranslations = {
    accountTranslations:{
        account: 'Cuenta',
        careRecipient: 'Mi destinatario de la atención',
        myRewards: 'Mi recompensa',
        saved: 'Salvado',
        following: 'Suscripciones',
        incorrectPassword: 'Contraseña son incorrectos',
        updatedPassword: 'Contraseña actualiza',
        updatedName: 'Nombre actualizado',
        updatedEmail: 'Correo electrónico actualizado',
        updatedUsername: 'Nombre de usuario actualizado',
        updatedZip: 'Código postal actualizado',
        updatedGender: 'Género actualizado',
        updatedRace: 'Carrera actualizada',
        updatedBirthday: 'Cumpleaños actualizado',
        updatedEmploymentStatus: 'Estado de empleo actualizado',
        updatedProfilePhoto: 'Foto de perfil actualizada',
        removedProfilePhoto: 'Foto de perfil eliminada'
    }
}
