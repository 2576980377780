import { useEffect } from 'react';
import { LayoutObservable } from '@/observables/layout.observable';

export const useDisposeFooter = () => {
  useEffect(() => {
    LayoutObservable.toggleDisplayFooter(false);

    return () => LayoutObservable.toggleDisplayFooter(true);
  }, []);
}
