import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import styles from './signup-mail-section.module.scss';
import PaperAirplaneMobileImage from '@/assets/images/paper-airplane-mobile.png';
import PaperAirplaneImage from '@/assets/images/paper-airplane.png';
import { isEmail, isEmpty } from 'validator';
import { AlertObservable } from '@/observables/alert.observable';
import { Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap';
import { useTranslation, useHttpPost } from '@/hooks';
import { MAILING_LIST_SUBSCRIBE } from '@/constants/endpoints.constant';

const SignupMailSectionComponent = ({ className, title, description, buttonTitle, href, headingNumber }) => {
  const { execute: signup, loading: signupLoading } = useHttpPost();
  const [email, setEmail] = useState('');
  const {t} = useTranslation();
  const MESSAGE_ERROR = t('organismsTranslations.mailingList.errorMessage');

  const disableButton = () => {
    return signupLoading || isEmpty(email) || !isEmail(email);
  }

  let HeadingTag;
  if(headingNumber){
    HeadingTag = `h${headingNumber}`;
  }

  const signupSubmit = () => {
    signup(MAILING_LIST_SUBSCRIBE, {email}, { auth: false }).then(() => {
      setEmail('');
      AlertObservable.fireAlert({
        title: 'Mailing list',
        type: 'success',
        description: 'Your email is now subscribed.'
      });
    }).catch((message) => {
      let errMsg = 'Oops! Looks like an error occurred. Please try again.'
      if (String(message) === 'already_in_mailing_list') {
        errMsg = <p>{MESSAGE_ERROR} <a href="https://www.caregiving.com/">here</a>.</p>;
      }
      AlertObservable.fireAlert({
        title: 'Mailing list',
        type: 'error',
        description: errMsg,
      });
    })
  }
  return (
    <div className={`${styles.cgSignupMailSection} ${className}`}>
      <div className={`${styles.cgSignupMailSection__content_wrapper}`}>
        <picture className={`${styles.cgSignupMailSection__image_wrapper}`}>
          <source srcSet={PaperAirplaneImage} media="(min-width: 768px)"/>
          <img className={styles.cgSignupMailSection__img} src={PaperAirplaneMobileImage}/>
        </picture>
        <div>
        <Row className="justify-content-start">
          <Col xs={'auto'}>
            {headingNumber &&
              <HeadingTag className={`${styles.cgSignupMailSection__title} text-left`}>{title || t('organismsTranslations.mailingList.title')}</HeadingTag>
            }
            {!headingNumber &&
              (title || t('organismsTranslations.mailingList.title'))
            }
            <p className={`${styles.cgSignupMailSection__description} text-left`}>
              {description || t('organismsTranslations.mailingList.description')}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center justify-content-md-start">
          <Col md={'auto'} xs={12}>
            {!!buttonTitle ?
              <Button
                as="a"
                href={href}
                className={styles.cgSignupMailSection__singleButton}
                variant="white">
                {buttonTitle}
              </Button> :
                <div>
                <div className={'d-md-none'}>
                  <FormControl
                    value={email}
                    disabled={signupLoading}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    className={styles.cgSignupMailSection__input}
                    placeholder={t('formTitles.yourEmail')}
                  ></FormControl>
                  <Button
                      disabled={disableButton()}
                      onClick={signupSubmit}
                      className={styles.cgSignupMailSection__button}
                      variant="white">
                      Sign up
                  </Button>
                </div>
              <InputGroup className={`${styles.cgSignupMailSection__form} d-none d-md-flex`}>
                <FormControl
                  value={email}
                  disabled={signupLoading}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  className={styles.cgSignupMailSection__input}
                  placeholder={t('formTitles.yourEmail')}
                />
                <InputGroup.Append>
                  <Button
                    disabled={disableButton()}
                    onClick={signupSubmit}
                    className={styles.cgSignupMailSection__button}
                    variant="white">
                    {t('organismsTranslations.mailingList.button')}
                </Button>
                </InputGroup.Append>
                </InputGroup>
              </div>
            }
          </Col>
        </Row>
        </div>
      </div>
    </div>
  );
};

SignupMailSectionComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  href: PropTypes.string,
  headingNumber: PropTypes.number
};

export default memo(SignupMailSectionComponent);
