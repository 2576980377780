export const VisionaryAwardsTranslations = {
    visionaryAwardsTranslations: {
        2020: {
            header:{
                title: 'Premios Cuidadores Visionarios 2020',
                sponsor: 'Patrocinado por:'
            },
            navPills:{
                label: 'Resumen de premios 2020'
            },
            awardRecipient :{
                title: 'Destinatarios del premio al cuidador',
                description: 'Conozca a los cuidadores familiares y profesionales cuyo trabajo inspira a otros y marca la diferencia en la vida de otros cuidadores.'
            },
            organizationWinners: {
                title: 'Ganadores de organizaciones de cuidado',
                description: 'Obtenga más información sobre cómo los ganadores y finalistas de los premios de nuestra organización están marcando una diferencia en las vidas de sus empleados que brindan cuidados.'
            },
            nomineeStories: {
                title: 'Historias de nominados',
                description: 'Conozca a algunos de nuestros otros nominados que se preocupan por un ser querido y ofrecen apoyo a otras personas que se preocupan.'
            },
            articleJumbotron: {
                title:'Vea los premios visionarios de cuidado de 2020',
                subtitle:'Presentado por Mike Eidsaune y Christina Best.',
                buttonText: 'Empezar a mirar'
            }
        },
        2021: {
            header:{
                title: 'Premios Cuidadores Visionarios 2021',
                sponsor: 'Patrocinado por:'
            },
            navPills:{
                label: 'Resumen de premios 2021'
            },
            awardRecipient :{
                title: 'Destinatarios del premio al cuidador',
                description: 'Conozca a los cuidadores familiares y profesionales cuyo trabajo inspira a otros y marca la diferencia en la vida de otros cuidadores.'
            },
            organizationWinners: {
                title: 'Soluciones para cuidadoras por cuidadoras',
                description: 'Inspirándose en sus propias experiencias de cuidado, nuestros ganadores del Premio Visionario han ideado formas innovadoras de apoyar a los cuidadores familiares actuales y anteriores. Obtenga más información sobre sus productos y servicios.'
            },
            nomineeStories: {
                title: 'Historias de nominados',
                description: 'Conozca a algunos de nuestros otros nominados que se preocupan por un ser querido y ofrecen apoyo a otras personas que se preocupan.'
            },
            articleJumbotron: {
                title:'Vea los premios visionarios de cuidado de 2021',
                subtitle:'Presentado por Mike Eidsaune y Christina Best.',
                buttonText: 'Empezar a mirar'
            },
            partnersSection: {
                title: 'Socios en el cuidado',
                body: 'Estas organizaciones brindan una amplia variedad de servicios para cuidadores familiares y son reconocidas por crear conexión y comunidad entre aquellos a quienes sirven.',
            }
        },
        2022: {
            header:{
                title: 'Premios Cuidadores Visionarios 2022',
                sponsor: 'Patrocinado por:'
            },
            navPills:{
                label: 'Resumen de premios 2022'
            },
            awardRecipient :{
                title: 'Destinatarios del premio al cuidador',
                description: 'Conozca a los cuidadores familiares y profesionales cuyo trabajo inspira a otros y marca la diferencia en la vida de otros cuidadores.'
            },
            organizationWinners: {
                title: 'Soluciones para cuidadoras por cuidadoras',
                description: 'Inspirándose en sus propias experiencias de cuidado, nuestros ganadores del Premio Visionario han ideado formas innovadoras de apoyar a los cuidadores familiares actuales y anteriores. Obtenga más información sobre sus productos y servicios.'
            },
            nomineeStories: {
                title: 'Historias de nominados',
                description: 'Conozca a algunos de nuestros otros nominados que se preocupan por un ser querido y ofrecen apoyo a otras personas que se preocupan.'
            },
            articleJumbotron: {
                title:'Vea los premios visionarios de cuidado de 2022',
                subtitle:'Presentado por Mike Eidsaune y Christina Best.',
                buttonText: 'Empezar a mirar'
            },
            partnersSection: {
                title: 'Socios en el cuidado',
                body: 'Estas organizaciones brindan una amplia variedad de servicios para cuidadores familiares y son reconocidas por crear conexión y comunidad entre aquellos a quienes sirven.',
            }
        }
    }
}