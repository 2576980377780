import Pencil from '@/assets/icons/pencil.svg';
import Image from '@/components/atoms/image';
import {ImageSizes} from '@/constants/image-sizes.constant';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import Skeleton from 'react-loading-skeleton';
import styles from './avatar.module.scss';
import classnames from 'classnames';

const AvatarComponent = ({photo, name, count, update, icon, setRefAvatar, lazyLoadAvatar, ...props}) => {
  const [acronym, setAcronym] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    name && setNameAcronym();
  }, [name]);

  useEffect(() => {
    if (ref && setRefAvatar) {
      setRefAvatar(ref);
    }
  }, [ref]);

  const setNameAcronym = () => {
    const names = name.split(' ');
    const initials = `${names[0][0]}${names.length === 2 && names[1].length > 0 ? names[1][0] : ''}`;
    setAcronym(initials);
  }
  
  return (
    <div {...props} className={classnames(styles.cgAvatar, props.className || '')} ref={ref}>
      {photo ?
        <Image className={`${styles.cgAvatar__frame} ${update && styles.cgAvatar__big}`} size={ImageSizes.THUMBNAIL} src={photo} eager={!lazyLoadAvatar}/> :
        !icon && <p className={`${styles.cgAvatar__frame} ${styles.cgAvatar__frame__initialsOnly} ${update && styles.cgAvatar__big}`}>{acronym}</p>}
      {icon}
      {count > 1 && <div className={styles.cgAvatar__authorsCount}>{count}</div>}
      {update && <div className={styles.cgAvatar__pencil} onClick={update}><Pencil/></div>}
    </div>
  )
};

AvatarComponent.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  update: PropTypes.func,
  icon: PropTypes.any,
  setRefAvatar: PropTypes.func,
  lazyLoadAvatar: PropTypes.bool,
  className: PropTypes.string,
};

const onErrorFallbackComponent = () => <div className={`mt-4 ${styles.cgAvatar}`}>
  <Skeleton circle height={36} width={36}/>
</div>

export default withErrorBoundary(AvatarComponent, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
});
