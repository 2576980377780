import { useEffect, useState } from 'react';

export const useScrollDirectionDetection = () => {
  const [down, setDown] = useState(false);
  const [up, setUp] = useState(false);
  const [scrollState, setScrollState] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', scrollDetect);
    return () => {
      window.removeEventListener('scroll', scrollDetect);
    }
  }, []);

  const scrollDetect = () => {
    const currentScroll = window.scrollY;
    if (currentScroll === 0) {
      // home
      setDown(false);
      setUp(false);
    } else if (currentScroll > scrollState) {
      // down
      setDown(true);
      setUp(false);
    } else {
      // up
      setDown(false);
      setUp(true);
    }
    setScrollState(currentScroll);
  }

  return { up, down };
};
