export const AwardPageTranslations = {
    awardPageTranslations: {
        2020: {
            navPills:{
                label: '2020 Awards Recap'
            },
        },
        2021: {
            navPills:{
                label: '2021 Awards Recap'
            },
        },
        2022: {
            navPills:{
                label: '2022 Awards Recap'
            },
        },
        bannerSection: {
            title: 'Watch the 2023 Caregiving Visionary Awards',
            subtitle: 'Hosted by Mike Eidsaune',
            buttonLabel: 'Start watching'
        },
        visionaryAwardsSection: {
            title: 'The Annual Caregiving Visionary Awards',
            sponsor: 'Sponsored By:',
            body: 'The Caregiving Visionary Awards honor individuals and organizations that act as beacons of hope for family caregivers and former family caregivers each year. We have recognized many members of our caregiver audience over the past several years, and we are looking forward to sharing even more stories and experiences that can help you in your journey going forward.',
        },
        quoteSection: {
            title: 'CEO, Caregiving.com',
            name: 'Mike Eidsaune',
            body: 'Every year we hold this event to recognize family caregivers where they are, where they are doing amazing work in our communities, providing care, this is not about honoring celebrities. It is about honoring the family caregivers that are out there every day, selflessly caring for your loved ones.',
        },
        recognitionSection: {
            title: 'The importance of recognition',
            body: {
                paragraph1: 'We know all too well that caregivers often work in the shadows — oftentimes without recognition, pay, or support for the work they do. Our Caregiving Visionary Awards bring an opportunity to highlight the incredible dedication and selfless service of caregivers and community partners.',
                paragraph2: 'Caregiving.com is eager to highlight current and former caregivers, caregiver coaches, community leaders, and organizations that are making a difference and positive impact.',
                paragraph3: 'Award recipients receive special recognition at our annual awards show as well as year-long coverage across our platforms to recognize our outstanding caregivers and partners.'
            },
        },
        inspirationSection: {
            title: 'Get inspired',
            body: 'Take a look at the nominees and recipients at the Caregiving Visionary Awards from the past few years:',
            recap2020Title: '2020 awards recap',
            recap2021Title: '2021 awards recap',
            recap2022Title: '2022 awards recap'
        },
        awardsCategorySection: {
            title: 'Caregiving.com award categories',
            categoryCaregiver: {
                title: 'Visionary Caregiver',
                body: 'This award will recognize an individual that has developed and implemented an innovative way to support family caregivers or former family caregivers.'
            },
            categoryAdvocate: {
                title: 'Caregiving Advocate',
                body: 'As a caregiver and advocate, this individual will be honored for providing support for family caregivers or former family caregivers in a way that makes the caregiving experience better and easier.'
            },
            categoryYoungCaregiver: {
                title: 'Young Adult or Youth Caregiver',
                body: 'A growing number of younger people are taking on caregiving responsibilities. This award will recognize a young nominee that either cares for a loved one or provides support to others who care.'
            },
            categoryFamilyCaregiver: {
                title: 'Family Caregiver of the Year',
                body: 'Honoring an individual who cares for a family member or friend, this award will spotlight a caregiver that complements caregiving with their own needs and interests while also inspiring others.'
            },
            categoryOrganization: {
                title: 'Caregiving Organization of the Year',
                body: 'This award will spotlight an organization that leads by example and inspires other service providers to make a difference in the lives of caregivers and their loved ones.'
            },
            categoryTandySpirit: {
                title: 'Tandy Spirit Award',
                body: {
                    text: 'Tandy Elisala demonstrated a true commitment to service in her work. She was a believer in the importance of supporting others and wanted people to succeed and achieve. In her memory and to honor her, this award will recognize an individual with like-minded spirit and commitment to empowering others. Learn more about ',
                    link: 'Tandy Elisala'
                }
            },
        },
        sponsorSection: {
            title: 'Thank you to our sponsors',
            body: {
                paragraph1: {
                    text1: 'We’re grateful for our headline sponsor, ',
                    link: 'Elevance Health',
                    text2: ', who shares in our mission: to provide valuable local resources and make the lives of caregivers just a little bit easier.'
                },
                paragraph2: 'Caregiving.com is proud to be the link between real-life caregivers and community organizations that are willing to go the extra mile to support families impacted by a variety of illnesses.',
                paragraph3: {
                    text1: 'If you’re a community resource looking to get involved and improve the lives of caregivers everywhere, check out the Family Caregiver Resource page where you can ',
                    link: 'list your company ',
                    text2: 'as a caregiver resource in your local area.'
                }
            }
        }
    }
}