import React, { memo } from 'react';
import styles from './article-jumbotron.module.scss';
import PropTypes from 'prop-types';
import { ImageSizes } from '@/constants/image-sizes.constant';
import Button from '@/components/atoms/button/index';
import Image from '@/components/atoms/image';
import { useTranslation, useWindowDimensions } from '@/hooks';
import { getParsedImageURL } from '@/utils/images';

const RightContainer = ({ className, title, subtitle, featured, buttonText, FaIcon, color, onClick }) => {
  const { t } = useTranslation();

  return <div className={className}>
      { featured && typeof featured == 'string' ?  
       <p className={styles.cgArticleJumbotron__featured}>{featured}</p> :
        <p className={styles.cgArticleJumbotron__featured}>{t('moleculeTranslations.articleJumbotron.featured')}</p>
      }
      <h2 className={styles.cgArticleJumbotron__title}>{title}</h2>
      <p className={styles.cgArticleJumbotron__subtitle}>{subtitle}</p>
      {buttonText && <Button className={`${styles.cgArticleJumbotron__button} d-block d-md-none`} FaIcon={FaIcon} color={color} onClick={onClick}>
          {buttonText}
      </Button>}
    </div>
}

const ArticleJumbotron = ({ className = '', img = '', title, subtitle, featured, color, imageCTAIcon, onClickCta, onClickBanner, FaIcon, buttonText, imageBackground = '', isCollection, ...props }) => {
  const { isMobile } = useWindowDimensions();

  // color starts with ##, remove one
  if (color[0] === '#' && color[1] === '#') {
    color = color.slice(1);
  }

  // if color doesn't start with # and has 6 characters or less, assume it should be a hex color and add #
  // the length check is to account for rgba color and linear-gradient that are using for some reason
  if (color && color[0] !== '#' && color.length <= 6) {
    color = `#${color}`;
  }

  return (
    <div
      className={`${isCollection && styles.cgArticleJumbotron__collection} ${styles.cgArticleJumbotron__container} ${className}`}
      onClick={onClickBanner}
      style={{ background: `url(${getParsedImageURL(imageBackground, ImageSizes.BANNER)})`, backgroundSize: `${isMobile ? 'cover' : 'calc(100% + 1px), cover'}`, backgroundPosition: 'center' }}>
      <div className={`${styles.cgArticleJumbotron} ${imageBackground && styles.cgArticleJumbotron__back}`} {...props}>
        <div
          className={styles.cgArticleJumbotron__infoContainer}
          style={{ background: `${!imageBackground && color}` }}>
          <RightContainer
            className="d-none flex-column align-items-left d-md-flex"
            title={title}
            subtitle={subtitle}
            featured={featured}
          />
          {/* Button shown on desktop */}
          {buttonText && <Button className={styles.cgArticleJumbotron__button} FaIcon={FaIcon} color={color} onClick={onClickCta}>
            {buttonText}
          </Button>}
        </div>
        {imageBackground ?
          <div className={`${styles.cgArticleJumbotron__imgContainer} ${styles.cgArticleJumbotron__column}`}>
            <Image className={styles.cgArticleJumbotron__imgBackground} src={imageBackground} size={ImageSizes.MEDIUM} />
            <RightContainer
              className="d-flex flex-column justify-content-center align-items-center d-md-none"
              title={title}
              subtitle={subtitle}
              featured={featured}
              buttonText={buttonText}
              FaIcon={FaIcon}
              color={color}
              onClick={onClickCta} />
          </div> :
          <div className={styles.cgArticleJumbotron__imgContainer} style={
            {
              background: `linear-gradient(${isCollection && isMobile ? '0deg' : '90deg'}, ${color} ${isCollection && isMobile ? '30%' : '7.64%'}, rgba(78, 97, 237, 0) 100%), url("${getParsedImageURL(img, ImageSizes.BANNER)}") no-repeat`,
              backgroundSize: `${isMobile ? 'cover' : 'calc(100% + 1px), cover'}`,
              backgroundPosition: 'center center'
            }
          }>
            <RightContainer
              className={`d-flex flex-column justify-content-center align-items-center d-md-none ${isCollection ? styles.cgArticleJumbotron__contentCollection: ''}`}
              title={title}
              subtitle={subtitle}
              featured={featured}
              buttonText={buttonText}
              FaIcon={FaIcon}
              color={color}
              onClick={onClickCta} />
            {imageCTAIcon &&
              <img onClick={onClickCta} className='btn p-0 d-none d-md-block' src={imageCTAIcon} alt="Play Icon" />
            }
          </div>
        }
      </div>
    </div>
  );
};

RightContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  featured: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClick: PropTypes.func,
  FaIcon: PropTypes.any,
  buttonText: PropTypes.string,
  color: PropTypes.string
};

ArticleJumbotron.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  featured: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.string,
  imageCTAIcon: PropTypes.string,
  onClickCta: PropTypes.func,
  onClickBanner: PropTypes.func,
  FaIcon: PropTypes.any,
  buttonText: PropTypes.string,
  imageBackground: PropTypes.string,
  isCollection: PropTypes.bool
};

export default memo(ArticleJumbotron);
