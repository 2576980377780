import React, {useEffect, useState} from 'react';
import { Image } from 'react-bootstrap';
import styles from './full-image-banner.module.scss';
import { useTranslation } from '@/hooks';
import Button from '@/components/atoms/button';
import StreamIcon from '@/assets/icons/stream.svg';
import PropTypes from 'prop-types';

const FullImageBanner = ({ children, className, classNameDescription, src, button, btnlink, CustomButton, nfcs, img, sponsor, tabletImg, mobileImg, buttonStyle }) => {
  const { t } = useTranslation();
  const [backgroundImg, setBackgroundImg] = useState({});
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let bgImg = src;
    if (mobileImg && dimensions.width < 768.98) {
      bgImg = mobileImg;
    } else if (tabletImg && dimensions.width < 992) {
      bgImg = tabletImg;
    }
    setBackgroundImg(bgImg)
  }, [dimensions]);

  const renderCTA = () => {
    if (CustomButton) {
      return <CustomButton />
    }
    if (button) {
      return (<Button className={`${styles.cgFullImageBanner__button} ${buttonStyle ? buttonStyle : ''}`} onClick={() => window.open(`${btnlink}`, '_blank')}>
        {button}
      </Button>);
    }
    return null;
  }
  return (
    <div className={`${styles.cgFullImageBanner} ${className}`} style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="d-flex flex-column w-100 align-items-center align-items-md-baseline">
        {img &&
          <>
            {sponsor ?
            (
                <div className={styles.cgFullImageBanner__header}>
                  <Image src={img} className={styles.cgFullImageBanner__header__image}/>
                  <div className={styles.cgFullImageBanner__header__sponsor}>
                    <div className={styles.cgFullImageBanner__header__sponsorText}>{t('moleculeTranslations.nfcs.sponsored')}</div>
                    <Image src={sponsor} className={styles.cgFullImageBanner__header__sponsorImage}/>
                  </div>
                </div>
            ) : (
                <Image src={img} className={styles.cgFullImageBanner__headerWithoutSponsor}/>)}</>
        }
        <div className={`${styles.cgFullImageBanner__description} ${classNameDescription}`}>
          { children }
        </div>
        <div className="d-flex flex-column-reverse flex-md-row">
          {renderCTA()}
          {nfcs && <div className="d-flex justify-content-center align-items-center mb-4 mb-md-0">
            <div className="d-flex align-items-center ml-3"><StreamIcon className="mr-2"/>
              <span> {t('moleculeTranslations.nfcs.stream')}</span></div>
          </div>}
        </div>
      </div>
    </div>
  );
};

FullImageBanner.propTypes = {
  className: PropTypes.string,
  classNameDescription: PropTypes.string,
  src: PropTypes.any,
  CustomButton: PropTypes.any,
  button: PropTypes.string,
  btnlink: PropTypes.string,
  nfcs: PropTypes.bool,
  img: PropTypes.string,
  sponsor: PropTypes.string,
  mobileImg: PropTypes.string,
  tabletImg: PropTypes.string,
  buttonStyle: PropTypes.string,
}
export default FullImageBanner;
