import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useObservable } from '@/hooks';
import { AlertObservable } from '@/observables/alert.observable';

// For usage, look for sweet-alert documentation
const AlertComponent = () => {
  const [config] = useObservable(AlertObservable.showAlert$);
  // eslint-disable-next-line no-unused-vars
  const { show: _, description, title = '', onConfirm, onCancel, ...rest } = config || {};

  const onActionClick = (action) => {
    AlertObservable.hideAlert();
    action?.();
  }

  return (
    <SweetAlert
      title={title}
      onConfirm={() => onActionClick(onConfirm)}
      onCancel={() => onActionClick(onCancel)}
      show={!!config}
      {...rest}>
      {description}
    </SweetAlert>
  );
}

export default AlertComponent;
