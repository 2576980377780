import React, { useState } from 'react';
import styles from './concert.module.scss';
import ConcertThumbnail from '@/assets/images/concert_thumbnail.png';
import { ResponsiveEmbed } from 'react-bootstrap';
import Back from '@/components/atoms/back';
import SignupEmailSection from '@/components/organisms/signup-mail-section';
import CgIcon from '@/components/atoms/cg-icon';
import {ImageSizes} from '@/constants/image-sizes.constant';
import { getParsedImageURL } from '@/utils/images';
import {useTranslation} from '@/hooks';

const ConcertComponent = function() {
  const [isPlayClicked, setIsPlayClicked] = useState(false);
  const [imgUrl, setImgUrl] = useState(ConcertThumbnail);
  const {t} = useTranslation();

  const onPlayClick = function() {
    setImgUrl(undefined);
    setIsPlayClicked(true);
  }

  return (
    <>
      <div className={`padding-x pt-4 pb-5 ${styles.cgConcert__media}`}>
        <Back className={`${styles.cgConcert__mediaButton} pb - 4`} text={'Back'}></Back>
        {isPlayClicked ?
          <ResponsiveEmbed aspectRatio="16by9" className="rounded">
            <iframe src="https://www.youtube.com/embed/gltSA0Nb84E?rel=0&autoplay=1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </ResponsiveEmbed>
          :
          <>
            <div className={styles.cgConcert__image} style={{background: `url(${getParsedImageURL(imgUrl, ImageSizes.BANNER)})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
            <CgIcon iconName="MdPlayCircleFilled" className={styles.cgConcert__play} onClick={onPlayClick}/>
          </>
        }
        <h4 className="pl-4 pt-3 font-weight-bold">{t('atomsTranslations.concert.title')}</h4>
        <p className="pl-4"><span className="pr-3 cg-dot__status cg-dot__status--red font-weight-500 spacing-2">{t('atomsTranslations.concert.paragraph1')}</span>{t('atomsTranslations.concert.paragraph2')}</p>
      </div>
      <SignupEmailSection headingNumber={2}></SignupEmailSection>
    </>
  )
}

export default ConcertComponent;
