import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useDataLayer = (pageTitle) => {
  const location = useLocation();

  useEffect(() => {
    dataLayer.push({pageTitle, url: location.pathname});
  }, [location?.pathname]);
}
