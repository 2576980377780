import { useEffect } from 'react';
import { LayoutObservable } from '@/observables/layout.observable';

export const useDisposeNavbar = () => {
  useEffect(() => {
    LayoutObservable.toggleDisplayNavbar(false);

    return () => LayoutObservable.toggleDisplayNavbar(true);
  }, []);
}
