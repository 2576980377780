import { useEffect } from 'react';

export const useScript = (urls, deps = [], async = true) => {
  const fullDeps = [urls, ...deps];
  useEffect(() => {
    urls?.forEach(url => {
      if (fullDeps.every((dep) => { return !!dep })) {
        const existing = document.getElementById(url);
        if (existing !== null) {
          return;
        }
        const script = document.createElement('script');

        script.src = url;
        script.async = async;
        script.id = url;

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        }
      }
    });
  }, fullDeps);
};
