import MoleculeTranslations from './molecules';
import HooksTranslations from './hooks';
import AtomsTranslations from './atoms';
import OrganismsTranslations from './organisms';
import FormTranslations from './forms';
import PageTranslations from './pages';
import ConstantsTranslations from './constants';

const Index = {
    copyright: '© 2022 Caregiving.com, Todos los derechos reservados.',
    loginEmailError: 'El correo electrónico o la contraseña son incorrectos',
    loginUsernameError: 'El nombre de usuario o contraseña son incorrectos',
    backButton: 'Atras',
    login : {
        title:'Bienvenido de nuevo',
    },
    registrationErrors: {
        name: 'Este campo es obligatorio',
        email: {
            required: 'Este campo es obligatorio',
            exists:'El correo electrónico ya existe.',
            invalidFormat: 'Formato del email invalido'
        },
        password: 'Este campo es obligatorio',
        invalidFormat: 'Formato de correo inválido'

    },
    subscribe: 'Suscríbeme al boletín de caregiving.com.',
    terms: {
        paragraph1: 'He ',
        paragraph2:'leido y acepto ',
        paragraph3: 'los términos y condiciones.'
    },
    noResults: 'No se han encontrado resultados.',
    formAccount: {
        title: 'Crea tu cuenta',
        name: 'Nombre completo',
        email: 'Correo electrónico',
        password: 'Contraseña',
        nextButton: 'Siguiente'
    },
    usernameValidation: {
        title: 'Crea tu cuenta',
        subtitle: 'Elija un nombre de usuario para su nueva cuenta. El nombre de usuario debe contener al menos 3 caracteres (alfanuméricos, puntos y guiones).',
        errors: {
            fieldRequired: 'Este campo es obligatorio',
            notAvailable: 'El usuario $user no esta disponible',
            noSpaces: 'El usuario no puede tener espacios',
            minLen: 'El usuario debe tener mínimo 3 caracteres.'
        },
        username: 'Usuario', 
        createButton: 'Crear cuenta'
    },
    caregivingPromise: {
        title: 'La promesa del cuidado',
        description: 'Nuestra promesa y compromiso con los cuidadores familiares es que su experiencia en Caregiving.com será útil, impactante y placentera. La información que comparte con nosotros solo se utilizará para mejorar su experiencia en el sitio y nunca compartiremos ni venderemos su información individual con ninguna empresa externa. Queremos conectarlo con la información o los recursos que necesita en sus términos. Nunca recibirá llamadas telefónicas o correos electrónicos de empresas que obtuvieron su información de nosotros. Eso no es lo que somos y sabemos que no es lo que quieres. Su experiencia y su confianza son nuestra máxima prioridad.',
        footer: 'Cada vez que vea esta insignia, queremos que sea un recordatorio de nuestra promesa y compromiso con usted, el cuidador de la familia.',
        startButton: 'Iniciar'
    }, 
    createCareProfile: {
        description: '¡Éxito! Tu cuenta ha sido creada. ¿Le gustaría configurar un perfil de cuidado para que caregiving.com pueda brindarle contenido relevante para su experiencia como cuidador?',
        buttonYes: 'Sí, me gustaría crear un perfil de cuidado',
        buttonNo: 'No gracias, lo haré más tarde.'
    },
    careMemberOnboard: {
        description: 'Respondamos algunas preguntas acerca de tu situación de cuidado para que caregiving.com pueda brindarte contenido relevante a tu experiencia de cuidado.',
        buttonYes: 'Si, hagámoslo',
        buttonNo: 'No gracias',
        external: {
            description: '¿Quieres agregar a {invite_value} como miembro de la red de cuido de {recipient_name}?',
            accept: 'Aceptar',
            skip: 'Ignorar',
        }
    },
    getStartedQuestions: {
        title: 'Algunas preguntas rápidas para empezar.',
        gender: {
            label: '¿Cuál es su género?'
        },
        race: {
            label: '¿Cómo describirías tu origen étnico?'
        },
        birthdate: {
            label: 'Fecha de nacimiento'
        },
        employmentStatus: {
            label: '¿Está usted empleado?'
        },
        nextButton: 'Siguiente',
        skipButton: 'Saltar'
    }, 
    linksSection: {
        signin: '¿Ya tienes una cuenta? Ingresa',
        noAccount: '¿No tienes una cuenta? Regístrate',
        forgotPassword: 'Olvidé mi contraseña',
        terms: 'Condiciones de uso',
        privacy: 'Políticas de privacidad'
    },
    noSearchResults: {
        text1: '¡Lo sentimos! No pudimos encontrar ningún resultado ',
        text2: 'Intenta ajustar tus filtros para ampliar tu búsqueda.'
    },
    popularArticles: 'Quizás estos artículos populares te interesen.',
    passwordValidation: {
        errors: {
            fieldRequired: 'Este campo es obligatorio',
            notMatch: 'La contraseña no coincide'
        }
    }
}

const En = Object.assign(
    Index,
    MoleculeTranslations,
    HooksTranslations,
    AtomsTranslations,
    OrganismsTranslations,
    FormTranslations,
    PageTranslations,
    ConstantsTranslations
)

export default En;