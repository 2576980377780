import React, {memo, useMemo, useEffect} from 'react';
import Avatar from '@/components/atoms/avatar';
import {ImageSizes} from '@/constants/image-sizes.constant';
import PropTypes from 'prop-types';
import {Image} from 'react-bootstrap';
import {withErrorBoundary} from 'react-error-boundary';
import LazyLoad, { forceCheck } from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';
import { getParsedImageURL } from '@/utils/images';

const ImageComponent = ({src, alt, size, onError, fullHeight, eager, linkFull, useALtFallback, ...rest}) => {
  const imageUrl = useMemo(() => {
    if (typeof src === 'string') {
      return getParsedImageURL(src, size);
    }
    return '';
  }, [size, src]);

  useEffect(() => {
    forceCheck();
  }, []);
  
  const imageFullUrl = useMemo(() => {
    if (typeof src === 'string') {
      return getParsedImageURL(src, ImageSizes.FULL);
    }
    return '';
  }, [src]);
  const webpImageUrl = useMemo(() => imageUrl.replace(/\.(png|jpe?g)$/i, '.webp'), [src, size]);
  const picture =
    (!imageUrl && useALtFallback) ? <Avatar name={alt} className='position-relative mt-3 mr-4'/> : <picture>
      {!(/^http/i).test(imageUrl) &&
      <source srcSet={webpImageUrl.replace(/ /g, '%20')} type='image/webp'/>
      }
      <source srcSet={imageUrl.replace(/ /g, '%20')} type={`image/${(/\.png$/i).test(imageUrl) ? 'png' : 'jpeg'}`}/>
      <Image {...rest} src={imageUrl} onError={onError} alt={alt}/>
    </picture>;

  const imageComponent = eager ? picture : (
      <LazyLoad className={fullHeight ? 'h-100' : ''}>
        {picture}
      </LazyLoad>
  );

  if (linkFull && imageFullUrl !== '') {
    return (<a href={imageFullUrl} target='_blank' rel='noopener noreferrer'>{imageComponent}</a>);
  }
  return imageComponent
}

ImageComponent.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.string,
  size: PropTypes.string,
  onError: PropTypes.func,
  fullHeight: PropTypes.bool,
  eager: PropTypes.bool, // Do not lazy load the image
  linkFull: PropTypes.bool,
  useALtFallback: PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool,
]),
}

const onErrorFallbackComponent = () =>
  <div className='h-100 w-100'>
    <Skeleton height='100%' width='100%'/>
  </div>

export default memo(withErrorBoundary(ImageComponent, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
}));
