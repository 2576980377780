import React from 'react';
import {UserObservable} from '@/observables/user.observable';
import {SupportLayerObservable} from '@/observables/support-layer.observable';
import LocalStorage from '@/observables/localStorage.observable';
import {LocalStorageKeys} from '@/constants/local-storage-keys.constant';

const LogoutComponent = () => {
  if (typeof window !== 'undefined') {
    UserObservable.setUser(undefined);
    SupportLayerObservable.updateSupportLayer({list: []})
    LocalStorage.removeItem(LocalStorageKeys.SUPPORT_LAYER_PERSONA_TOKEN);
    location.assign('/');
  }
  return (<></>);
}

export default LogoutComponent;
