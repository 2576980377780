//used in multiple files...
const Index = {
    formTitles: {
        profilePhoto: {
            title: 'Profile photo',
            description: 'Add a photo for your account.',
            updateTitle: 'Upload photo from your computer'
        },
        name:' Name',
        fullname: 'Full name',
        firstname: 'Firstname',
        lastname: 'Lastname',
        username: 'Username',
        email: 'Email address',
        emailUsername: 'Email',
        yourEmail: 'Your email',
        zipcode: 'Zip Code',
        race: 'Ethnicity',
        birthdate: 'Birthday',
        employmentStatus: 'Employment Status',
        gender: 'Gender',
        password: {
            title: 'Password',
            placeholder: 'Current password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password',
            updatePassword: 'Update password',
        },
        caregiverEmail: 'Caregiver\'s email',
        caregiverEmailPlaceholder: 'Enter the caregiver\'s email address',
    },
    buttons:{
        logout:'Log out',
        login: 'Sign in',
        edit:'Edit',
        accept: 'Accept',
        decline: 'Decline',
        remove: 'Remove',
        save: 'Save',
        cancel: 'Cancel',
        manageAccount: 'Manage your account',
        signOut: 'Sign out',
        addNew: 'Add New',
        resend: 'Resend',
        accountSignUp: 'Don’t have an account? Sign up',
        forgotPassword: 'I forgot my password',
        existingAccountSignIn: 'Already have an account? Sign in',
        update: 'Update',
        send: 'Send',
        back: 'Back',
        selectAll: 'select all',
        startWatching: 'Start Watching',
        next: 'Next',
        getResources: 'Get Resources',
    }
}

const FormTranslations = Object.assign(Index)

export default FormTranslations;
