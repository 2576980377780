export const MarketingTranslations = {
    marketingTranslations:{
        submitted: 'Presentada',
        submitDescription: 'Su solicitud ha sido enviada.',
        demoButton: 'Solicite una demostración',
        heroTitle: 'Presentamos la primera plataforma digital de cuidadores familiares.',
        paragraph1: 'Los cuidadores familiares son la columna vertebral de la atención médica en nuestra sociedad que envejece. En Caregiving.com, hemos construido un ecosistema de capacitación, contenido, comunidad y datos utilizando tecnología innovadora que coloca a los cuidadores familiares en el centro de la solución. ¿El resultado? La capa de soporte de Caregiving.com que garantiza que los cuidadores se conviertan en cuidadores familiares bien informados que puedan brindar la atención adecuada en el momento adecuado para sus seres queridos.',
        heroSubTitle: 'El ecosistema del cuidador ofrece valor',
        serviceRow1 :{
            title: 'Experiencia de usuario a medida',
            paragraph: 'La capa de soporte de Caregiving.com es una forma revolucionaria de personalizar el viaje de cada cuidador a través de perfiles innovadores dentro de nuestra primera plataforma digital. Hemos combinado nuestra profunda experiencia técnica y de diseño de productos con el conocimiento de primera mano del viaje del cuidado familiar para desarrollar una experiencia de usuario líder en la industria.'
        },
        serviceRow2 :{
            title: 'Amplia biblioteca de contenidos y formación',
            paragraph: 'Hemos trabajado con líderes en la industria del cuidado familiar durante más de 25 años para crear el contenido más relevante y atractivo. Los cuidadores familiares de todos los orígenes encontrarán lo que necesitan, cuando lo necesiten dentro del catálogo de contenido para cuidadores más grande y confiable de la industria. Además, nuestro equipo de más de 50 colaboradores activos garantiza que nuestra biblioteca se actualice y amplíe constantemente para satisfacer las demandas cambiantes de los consumidores y socios.'
        },
        serviceRow3 :{
            title: 'Comunidad comprometida',
            paragraph: 'Nos reunimos con los cuidadores donde están, en línea y fuera de línea. Además de un viaje en línea personalizado, brindamos comunidad y conexión en áreas locales de todo el país. El Programa Champions es una iniciativa local que trabaja con cuidadores familiares para ampliar nuestra misión de mejorar la educación, capacitación, confianza y autoeficacia de los cuidadores familiares. Tener presencia en las comunidades que los cuidadores llaman hogar es un componente central de nuestra visión.'
        },
        serviceRow4 :{
            title: 'Tecnología innovadora',
            paragraph: 'Nuestras bibliotecas de educación y capacitación cuentan con un sofisticado sistema de etiquetado y administración de contenido. Los cuidadores familiares organizan su perfil seleccionando preferencias clave específicas para su proceso de cuidado, de modo que el contenido se personalice para satisfacer sus necesidades. La plataforma Caregiving.com se puede integrar fácilmente en cualquier flujo de trabajo o sistema con One Link™ para brindar una experiencia personalizada incluso a las poblaciones de miembros más especializadas.'
        },
        serviceRow5 :{
            title: 'Datos robustos',
            paragraph: 'Los datos de usuario agregados lo ayudarán a comprender mejor a su comunidad para brindar los beneficios correctos y mejorar los resultados. El panel de datos rastrea una serie de campos de datos que le permiten comprender mejor cómo el contenido está resonando con los cuidadores familiares y su círculo de atención. Podemos integrarnos fácilmente con soluciones paralelas para admitir un entorno de circuito cerrado que proporcione conjuntos de datos óptimos a nuestros socios. Creemos firmemente que los datos de los cuidadores familiares tienen el potencial de mejorar drásticamente los resultados de la atención.'
        },
        partners: {
            title: 'Asociación con Caregiving.com',
            paragraph1: 'Asociarse con Caregiving.com como socio de capa de soporte le brinda una mejor presencia en el mercado de varias maneras:',
            paragraph2: 'Nuestros socios totalmente integrados disfrutan de un entorno de marca compartida y demuestra la experiencia de la organización patrocinadora.'
        },
        organizations: 'Organizaciones que se benefician de esta solución',
        scheduleDemo: 'Programe una demostración para ver cómo podemos ayudar a su organización.',
        workWith: 'Con quién trabajamos hoy',
        demoRequest: {
            heading: 'Solicite una demostración',
            name: 'Nombre',
            company: 'Empresa',
            title: 'Título',
            phoneNumber: 'Número de teléfono',
            emailAddress: 'Dirección de correo electrónico',
            submit: 'Enviar',
        },
    }
}