import { BehaviorSubject } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { LocalStorageKeys } from '@/constants/local-storage-keys.constant';
import LocalStorage from '@/observables/localStorage.observable';


const getTokenExpiration = (token) => {
  if (!token) {
    return 0
  }
  const decoded = jwtDecode(token);
  return decoded.exp;
}

const authData = LocalStorage.getItem(LocalStorageKeys.AUTH_INFO);
const userInfo = authData && JSON.parse(authData);

const userSubject = new BehaviorSubject(userInfo?.user);
const jwtExpiration = new BehaviorSubject(getTokenExpiration(userInfo?.access_token));

export const UserObservable = Object.freeze({
  setUser: (user) => {
    const credentials = LocalStorage.getItem(LocalStorageKeys.AUTH_INFO);
    const parsedCredentials = credentials && JSON.parse(credentials);
    if (parsedCredentials && user) {
      parsedCredentials.user = { ...user };
      LocalStorage.setItem(LocalStorageKeys.AUTH_INFO, JSON.stringify(parsedCredentials));
    } else if (!user) {
      LocalStorage.removeItem(LocalStorageKeys.AUTH_INFO);
      LocalStorage.removeItem(LocalStorageKeys.AT);
      jwtExpiration.next(0);
    }
    userSubject.next(user);
  },
  hasSavedCredentials: () => {
    return !!LocalStorage.getItem(LocalStorageKeys.AUTH_INFO);
  },
  getAccessToken: () => {
    const credentials = LocalStorage.getItem(LocalStorageKeys.AUTH_INFO);
    const parsedCredentials = credentials && JSON.parse(credentials);
    return parsedCredentials?.access_token;
  },
  updateUserCredentials: (data) => {
    if (data) {
      LocalStorage.setItem(LocalStorageKeys.AUTH_INFO, JSON.stringify(data));
      jwtExpiration.next(getTokenExpiration(data.access_token));
      userSubject.next(data.user);
    } else {
      LocalStorage.removeItem(LocalStorageKeys.AUTH_INFO);
      jwtExpiration.next(0);
      userSubject.next(undefined);
    }
  },
  isTokenExpired: () => {
    if (jwtExpiration.value === 0) {
      return false // 0 means that we don't have an access token at all. This most likely means the user was never logged in
    }
    // token is expired if the current time is greater than the expiration time
    return jwtExpiration.value < Date.now() / 1000;
  },
  user$: userSubject.asObservable(),
  userSubject: userSubject,
});
