export const AboutTranslations = {
    aboutTranslations:{
        banner: {
            title: 'Engage Today. Empower Tomorrow.',
        },
        LeftText:{
            title: 'Mission',
            text: 'Caregiving.com is committed to engaging and empowering family caregivers worldwide via a multi-faceted support model delivered through our leading-edge platform. We strive to inspire a sense of community that engages and supports caregivers in building resilience and sustainability as they navigate their caregiving journey. Likewise, we aim to be a safe, accessible space for all family caregivers to find comfort, support, and advice.',
        },
        RightText:{
            title: 'Vision',
            text: 'By engaging caregivers today, we can empower them for tomorrow’s journey.',
        },
        whyBanner:{
            title: 'Why?',
            text: 'Family caregivers are healthcare’s foundation, supporting aging populations and diverse conditions. We’re driven to transform their experience. Through community support, fresh content, incentives, health system partnerships, and top-notch design, we aim to revolutionize the caregiver experience worldwide, elevating healthcare overall.',
        },
        howTitle:{
            title: 'How?',
            howStandOut: 'At Caregiving.com, we stand out with a holistic approach to family caregiver support. We tackle caregiving challenges head-on, identify gaps, and offer solutions. Our extensive library forms the foundation, complemented by action-focused pieces, videos, community, and more.',
            howSupport: 'Caregiving.com offers a new realm of caregiver support, combining nurturing with insight. We create a personalized journey, addressing various aspects of caregiving, including training, skills, finances, emotions, connections, spirituality, culture, and more.',
            howFamily: 'We also include the whole family, acknowledging their impact on caregivers. We welcome and value everyone, regardless of their caregiving role’s size, making them an essential part of the team.',
                },
        caregivingPromise:{
            title: 'The Caregiving Promise',
            text: 'Our promise and commitment to family caregivers, is that your experience on Caregiving.com will be helpful, impactful, and enjoyable. The information you share with us will only be used to improve your experience on the site and we will never share or sell your individual information with any outside business. We want to connect you to the information or resources you need on your terms. You’ll never get phone calls or emails from business that got your information from us. That’s not who we are and we know that’s not want you want. Your experience and your trust are our top priority.',
        },
        companyTitle: 'Company News',
    }
}