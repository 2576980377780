export const VisionaryAwardsTranslations = {
    visionaryAwardsTranslations: {
        2020: {
            header:{
                title: 'Caregiving Visionary Awards 2020',
                sponsor: 'Sponsored By:'
            },
            navPills:{
                label: '2020 Awards Recap'
            },
            awardRecipient :{
                title: 'Caregiver award recipients',
                description: 'Meet the family and professional caregivers whose work inspires others and makes a difference in the lives of other caregivers.'
            },
            organizationWinners: {
                title: 'Caregiving organization winners',
                description: 'Learn more about how our organization award winners and finalists are making a difference in the lives of their caregiving employees.'
            },
            nomineeStories: {
                title: 'Nominee stories',
                description: 'Get to know some of our other nominees who care for a loved one and offer support to others who care.'
            },
            articleJumbotron: {
                title:'Watch the 2020 caregiving visionary awards',
                subtitle:'Hosted by Mike Eidsaune and Christina Best.',
                buttonText: 'Start watching'
            }
        },
        2021: {
            header: {
                title: 'Caregiving Visionary Awards 2021',
                sponsor: 'Sponsored By:'
            },
            navPills:{
                label: '2021 Awards Recap'
            },
            awardRecipient :{
                title: 'Caregiver award recipients',
                description: 'Meet the family and professional caregivers whose work inspires others and makes a difference in the lives of other caregivers.'
            },
            organizationWinners: {
                title: 'Solutions for caregivers by caregivers',
                description: 'Drawing inspiration from their own care experiences, our Visionary Award recipients have come up with innovative ways to support current and former family caregivers. Learn more about their products and services.'
            },
            nomineeStories: {
                title: 'Nominee stories',
                description: 'Get to know some of our other nominees who care for a loved one and offer support to others who care.'
            },
            articleJumbotron: {
                title:'Watch the 2021 caregiving visionary awards',
                subtitle:'Hosted by Mike Eidsaune and Christina Best.',
                buttonText: 'Start watching'
            },
            partnersSection: {
                title: 'Partners in care',
                body: 'These organizations provide a wide-variety of services for family caregivers and are recognized for creating connection and community among those they serve.',
            }
        },
        2022: {
            header: {
                title: 'Caregiving Visionary Awards 2022',
                sponsor: 'Sponsored By:'
            },
            navPills:{
                label: '2022 Awards Recap'
            },
            awardRecipient :{
                title: 'Caregiver award recipients',
                description: 'Meet the family and professional caregivers whose work inspires others and makes a difference in the lives of other caregivers.'
            },
            organizationWinners: {
                title: 'Solutions for caregivers by caregivers',
                description: 'Drawing inspiration from their own care experiences, our Visionary Award recipients have come up with innovative ways to support current and former family caregivers. Learn more about their products and services.'
            },
            nomineeStories: {
                title: 'Nominee stories',
                description: 'Get to know some of our other nominees who care for a loved one and offer support to others who care.'
            },
            articleJumbotron: {
                title:'Watch the 2022 caregiving visionary awards',
                subtitle:'Hosted by Mike Eidsaune and Christina Best.',
                buttonText: 'Start watching'
            },
            partnersSection: {
                title: 'Partners in care',
                body: 'These organizations provide a wide-variety of services for family caregivers and are recognized for creating connection and community among those they serve.',
            }
        }
    }
}