export const ChampionsTranslations = {
    championTranslations: {
        pageTitle: 'Champions',
        championBanner: {
            title: 'Become a Caregiving.com Champion',
            description: 'Tell us about yourself and a member from our Community Growth team will reach out to you.',
            buttonText: 'Learn more'
        },
        description: {
            title: 'Bringing caregivers and community resources together',
            paragraph1: 'Caregiving.com is dedicated to bringing the right resources via state-of-the-art technology to help millions of caregivers live a better life for themselves and their loved ones. We are doing this by:',
            list: {
                item1: 'Sharing caregiver stories in a way that lets people know they are not alone on their  journey',
                item2: 'Providing tips, tools, and practical advice for everyday challenges',
                item3: 'Building technology that connects caregivers with one another as well as with resources in their local communities'
            }
        }
    }
}