import React from 'react';
import styles from './collection-tab.module.scss';
import PropTypes from 'prop-types';
import {useTranslation} from '@/hooks';

const CollectionTabComponent = ({collection}) => {
    const {t} = useTranslation();
    const backgroundColor = collection.color.charAt(0) === '#' ? collection.color : `#${collection.color}`;

    return (<div className={styles.cgCollectionTab} style={{backgroundColor, bottom: 0}}>
        <p>{collection.name} {t('atomsTranslations.collection')}</p>
    </div>)
}

CollectionTabComponent.propTypes = {
    collection: PropTypes.object.isRequired,
    media: PropTypes.bool
}

export default CollectionTabComponent;