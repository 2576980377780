// Custom sort function for items so other key item is always at the bottom of the list
export const customSort = (a, b) => {
    if (a.label === 'Other') return 1;
    if (b.label === 'Other') return -1;
    // Otherwise, sort by label
    return a.label.localeCompare(b.label);
};

// Function to sort an array of items or object keys
export const sortItems = (items) => {
    if (!items) {
        return [];
    }

    if (Array.isArray(items)) {
        return [...items].sort(customSort);
    } else {
        return Object.keys(items)
            .sort((a, b) => items[a].label.localeCompare(items[b].label))
            .map(key => items[key]);
    }
};
