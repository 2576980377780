import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import {Resources} from '@/languages';

i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        resources: Resources,
        fallbackLng: 'en',
      detection: {
        order: ['navigator', 'localStorage'],
      },
        interpolation: {
            escapeValue: false
        }
    });

document.documentElement.lang = i18n.language;