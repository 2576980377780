export const SupportTranslations = {
    supportTranslations: {
        latest: 'Aquí está lo último en cuidados de hoy.',
        welcomeBack: 'Bienvenido de nuevo',
        completeAccountBanner: {
            title: 'Complete su cuenta para acceder al soporte.',
            subtitle: 'Para desbloquear los beneficios del soporte de Caregiving.com, deberá completar su cuenta',
            button: 'Completar mi cuenta',
            watch: 'Ver video',
        },
        noAccountBanner: {
            title: 'Parece que necesitas una cuenta.',
            subtitle: 'Regístrese o inicie sesión para acceder a excelentes contenidos y recursos que lo ayudarán en su proceso de cuidado.',
            button: 'Crear una cuenta',
            watch: 'Ver video',
        },
        informationSection: {
            title: 'Cada experiencia de cuidado es única',
            paragraph1: 'Sabemos que el camino que tomará como cuidador tiene muchos giros y vueltas que nadie puede anticipar. Nuestro objetivo es desarrollar y brindar los recursos, las herramientas y el apoyo que necesitará para brindar la mejor atención a su ser querido mientras equilibra su vida.',
            paragraph2: 'Inicie su perfil en Caregiving.com seleccionando la información que mejor se adapte a sus necesidades en este momento. Puede actualizar esta información a medida que continúa su viaje. Y continuaremos brindando herramientas e información útiles cuando más las necesite.'
        },
        categories: {
            item1: {
                title: 'Contenido seleccionado entregado a usted',
                description: 'Una vez que completes tu perfil, nuestro sistema de etiquetado de artículos te recomendará contenido que te ayudará en tu etapa de cuidado.'
            },
            item2: {
                title: 'Mantenga a todos en su viaje de atención en la misma página',
                description: 'Caregiving.com puede ayudarlo a mantener actualizado su círculo de atención. Invite a su familia u otros cuidadores al perfil de su ser querido para que puedan beneficiarse de las mismas actualizaciones, educación y apoyo que usted.',
            },
            item3: {
                title: 'Contenido actualizado cuando y donde lo necesites',
                description: 'Siempre agregamos nuevos artículos educativos, videos, podcasts y herramientas de capacitación a nuestra biblioteca digital. Una vez que haya creado su perfil, le ofreceremos el contenido más reciente sobre los temas que más le interesan.'
            },
            item4: {
                title: 'Conéctese con su proveedor de beneficios',
                description: 'Caregiving.com se asocia con proveedores de seguros, empleadores y sistemas de salud.'
            },
        },
        partnerBanner: {
            title: '¿Interesado en convertirse en socio de apoyo?',
            button: 'Contáctenos'
        },
        unAuth : {
            list: {
                body: 'Aproveche al máximo Caregiving.com creando un perfil de atención para su ser querido.',
            },
            title: 'Bienvenido',
            button: 'Agregar un receptor de cuidado'
        },
        noContent: 'No hay contenido disponible para este receptor de cuidado.',
    }
}
