import React from 'react';
import PropTypes from 'prop-types';
import styles from './user-dropdown-item.module.scss';
import AvatarComponent from '../../atoms/avatar';

const UserDropdownItemComponent = ({photo, icon, name, url}) => {

  return (
    <div className={styles.cgUserDropdownItem} onClick={url}>
      <div className={styles.cgUserDropdownItem__avatar}>
        <AvatarComponent
          name={name}
          photo={photo}
          icon={icon}
        />
      </div>
      <p className={styles.cgUserDropdownItem__name}>{name}</p>
    </div>
  );
}

UserDropdownItemComponent.propTypes = {
  photo: PropTypes.string,
  icon: PropTypes.any,
  name: PropTypes.string,
  url: PropTypes.any,
}

export default UserDropdownItemComponent;
