import {AwardPageTranslations} from './award';
import {HomeTranslations} from './home';
import {AboutTranslations} from './about';
import {VisionaryAwardsTranslations} from './visionary-awards';
import {ChampionsTranslations} from './champions';
import {CareRecipientTranslations} from './manage/care-recipients';
import {MembersTranslations} from './manage/members';
import {OneLinkTranslations} from './one-link';
import {PodcastsTranslations} from './podcasts';
import {VideosTranslations} from './videos';
import {CommunityNewsRoomTranslations} from './community-newsroom';
import {MarketingTranslations} from './marketing';
import {AccountTranslations} from './manage/account';
import {AuthenticationTranslations} from './authentication';
import {ArticlePageTranslations} from './article';
import {SupportTranslations} from './support';
import {ProviderPageTranslations} from './provider';
import {LibraryTranslations} from './library';
import {CollectionListTranslations} from './collections'
import {PartnerTranslations} from './partners';
import {RewardsTranslations} from './manage/rewards';

const Index = {
    follow: 'Subscribe',
    save: 'Saved',
    following: 'Subscriptions',
    saved: 'Saved',
    followTypes: {
        author: 'Contributor',
        brand: 'Partner',
    },
    savedTypes: {
        post: 'Article',
        brand: 'Partner',
    },
    selectRelationship: 'Select Relationship',
    all: 'All',
    articles: 'Articles',
    podcasts: 'Podcasts',
    videos: 'Videos',
    filter: 'Filter',
    category: 'Category',
    publishDate: 'Publish Date',
    author: 'Author',
    anytime: 'Anytime',
    last24: 'Last 24hrs',
    week: 'This week',
    month: 'This month',
    year: 'This year',
    noMatchesFound: 'No matches found.',
    promptText: 'Type to search...',
    searchText: 'Searching...',
    serviceAreas: 'Service areas:',
    mainLocation: 'Main Location:',
    servicesAmenities: 'Services and amenities',
    requestErrorMessage:'There was an error in processing your request:'
}

const PageTranslations = Object.assign(
    Index,
    HomeTranslations,
    CareRecipientTranslations,
    MembersTranslations,
    OneLinkTranslations,
    AwardPageTranslations,
    VisionaryAwardsTranslations,
    AboutTranslations,
    ChampionsTranslations,
    PodcastsTranslations,
    VideosTranslations,
    CommunityNewsRoomTranslations,
    MarketingTranslations,
    AccountTranslations,
    AuthenticationTranslations,
    ArticlePageTranslations,
    SupportTranslations,
    ProviderPageTranslations,
    LibraryTranslations,
    CollectionListTranslations,
    PartnerTranslations,
    RewardsTranslations
);

export default PageTranslations;
