import {TermsOfUseTranslations} from './terms-of-use';
import {PrivacyPolicyTranslations} from './privacy-policy';

const Index = {
  atomsTranslations: {
    back: 'Atrás',
    //begin collapsible tabs
    more: 'Más',
    articles: 'Artículos',
    community: 'Comunidad',
    caregivingStories: 'Historias de cuidadores',
    research: 'Investigar',
    awards2020: 'Premios 2020',
    chat: 'Chat',
    //end collapsible tabs
    concert: {
      title: 'Música que recordamos Ft. Juan Van Beek',
      paragraph1: 'VIVA',
      paragraph2:
        'Una serie exclusiva de conciertos en vivo con música de John Van Beek',
    },
    reprintPermission: {
      title: 'Permiso de reimpresión',
      paragraph1:
        '¿Leyó un artículo en Caregiving.com que le gustaría reimprimir en su publicación impresa? Nuestros artículos, a excepción de los blogs de nuestros cuidadores familiares, están disponibles para que los use en sus boletines y folletos.',
      instructions: {
        title: 'Aquí está cómo hacerlo:',
        step1:
          'Envíe un correo electrónico a Caregiving.com para informarnos qué artículo le gustaría usar.',
        step2:
          'Incluya este crédito con el artículo: “Reimpreso con permiso de Caregiving.com. Caregiving.com se preocupa por usted como usted se preocupa por su familia y amigos”. (Tenga en cuenta: debe incluir un enlace al artículo original en Caregiving.com).',
      },
      paragraph2: '¡Eso es!',
      paragraph3:
        'Muchas gracias; ¡Estamos encantados de que encuentre valioso nuestro sitio!',
    },
    resourceTypeSelector: {
      placeholder: 'p.ej. Vivienda para personas de la tercera edad, Servicios legales',
    },
    search: {
      search: 'Buscar',
      noResults: 'Sin resultados ...',
      searching: 'Buscando',
    },
  },
};

const AtomsTranslations = Object.assign(Index, TermsOfUseTranslations, PrivacyPolicyTranslations)

export default AtomsTranslations;
