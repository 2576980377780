export const MarketingTranslations = {
    marketingTranslations:{
        submitted: 'Submitted',
        submitDescription: 'Your request has been submitted.',
        demoButton: 'Request a demo',
        heroTitle: 'Introducing the first digital family caregiver platform.',
        paragraph1: 'Family caregivers are the backbone of healthcare in our aging society. At Caregiving.com, we have built an ecosystem of training, content, community and data utilizing innovative technology that puts family caregivers at the center of the solution. The result? The Caregiving.com Support Layer that ensures caregivers become well-informed family caregivers who can provide the right care at the right time for their loved ones.',
        heroSubTitle: 'Caregiver Ecosystem Delivers Value',
        serviceRow1 :{
            title: 'Tailored user experience',
            paragraph: 'The Caregiving.com Support Layer is a revolutionary way to personalize each caregiver’s journey through innovative profiling within our digital first platform. We have combined our deep technical and product design expertise with first-hand knowledge of the family caregiving journey in order to develop an industry leading user experience.'
        },
        serviceRow2 :{
            title: 'Extensive training and content library',
            paragraph: 'We have worked with leaders in the family caregiving industry for the past 25+ years in order to create the most relevant and engaging content. Family caregivers of all backgrounds will find what they need, when they need it within the largest and most trusted catalog of caregiver content in the industry. Additionally, our team of more than 50+ active contributors ensure that our library is constantly updated and expanded to meet shifting consumer and partner demands.'
        },
        serviceRow3 :{
            title: 'Engaging community',
            paragraph: 'We meet caregivers where they are -- online and off. In addition to a personalized online journey, we are providing community and connection in local areas around the country. The Champions Program is a local initiative working with family caregivers to amplify our mission of improving family caregiver education, training, confidence, and self efficacy. Having presence in communities that caregivers call home is a core component of our vision.'
        },
        serviceRow4 :{
            title: 'Innovative technology',
            paragraph: 'Our education and training libraries are supercharged by a sophisticated tagging and content management system. Family caregivers organize their profile by selecting key preferences specific to their caregiving journey so the content is customized to meet their needs. The Caregiving.com platform can be easily integrated into any workflow or system with One Link™ in order to deliver a tailored experience to even the most niche member populations.'
        },
        serviceRow5 :{
            title: 'Robust data',
            paragraph: 'Aggregated user data will help you better understand your community in order to provide the right benefits and improve outcomes. The data dashboard tracks a number of data fields which allows you to better understand how content is resonating with family caregivers and their care circle. We can easily integrate with parallel solutions to support a closed loop environment providing optimal data sets to our partners. We firmly believe that family caregiver data has the potential to dramatically improve care outcomes.'
        },
        partners: {
            title: 'Partnering with Caregiving.com',
            paragraph1: 'Partnering with Caregiving.com as a Support Layer Partner gives you an improved presence in the market in a number of ways:',
            paragraph2:'Our fully integrated partners enjoy an environment that is co-branded and demonstrates the experience of the sponsoring organization.'
        },
        organizations: 'Organizations that benefit from this solution',
        scheduleDemo: 'Schedule a demo to see how we can help your organization.',
        workWith: 'Who we work with today',
        demoRequest: {
            heading: 'Request a demo',
            name: 'Name',
            company: 'Company',
            title: 'Title',
            phoneNumber: 'Phone Number',
            emailAddress: 'Email Address',
            submit: 'Submit',
        },
    }
}