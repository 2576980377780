import React, {useEffect, useState, useRef} from 'react';
import AOS from 'aos';
import PropTypes from 'prop-types';
import Alert from '@/components/atoms/alert';
import HeaderComponent from '@/components/organisms/header';
import NavPillsBar from '@/components/organisms/nav-pills-bar';
import SideMenuComponent from '@/components/organisms/side-menu';
import {SearchTabs} from '@/constants/search-tabs.constant';
import {useIsomorphicLayoutEffect, useWindowDimensions} from '@/hooks';
import {useObservable} from '@/hooks/use-observable.hook';
import {LayoutObservable} from '@/observables/layout.observable';
import {Col, Container, Row} from 'react-bootstrap';
import {withErrorBoundary} from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './layout.module.scss';
import { URL_METASEO } from '@/constants/endpoints.constant';
import { useHttpGet } from '@/hooks/use-http';
import {META_FALLBACKS} from '@/constants/seo-fallbacks.constant';
import NFCSBar from '@/components/molecules/nfcs-bar';
import { ImageSizes } from '@/constants/image-sizes.constant';
import { getParsedImageURL } from '@/utils/images';

const MainComponent = ({showSideMenu, children}) => <main className={showSideMenu ? 'main-v2-content' : ''}>{children}</main>;
const onErrorFallbackComponent = () => <div className="p-5 mt-5">
  <Skeleton height={330}/>
  <Skeleton className="mt-3" count={10}/>
</div>;

const SafeMainComponent = withErrorBoundary(MainComponent, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
})

const LayoutComponent = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const { execute: getPageTitle } = useHttpGet();
  const [ urlMetadata, setUrlMetadata ] = useState({});
  const [showHeader] = useObservable(LayoutObservable.showHeader$);
  const [isNfcsHeroEnabled] = useObservable(LayoutObservable.nfcsHeroEnabled$)
  const [isNfcsBarEnabled] = useObservable(LayoutObservable.nfcsBarEnabled$)
  // const [showBanner] = useObservable(NavbarObservable.showNavbarBanner$); // commented out to include it next year
  const [showNavPills] = useObservable(LayoutObservable.showNavPills$);
  const [showSideMenu] = useObservable(LayoutObservable.showSideMenu$);
  const [showLayout] = useObservable(LayoutObservable.showLayout$);
  const [isShowingRightPanel] = useObservable(LayoutObservable.showRightPanel$);
  const [layoutRef] = useObservable(LayoutObservable.layoutRef$);
  const {isMobile, isTablet} = useWindowDimensions();
  const [close, setClose] = useState(isMobile || isTablet);
  const [block, setBlock] = useState(!isMobile);
  const [currentPath, setCurrentPath] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // if pathname has a prefix of 'manage'
    // then set the title to 'Manage Caregiving.com'
    // else get the title from the API
    let skip = false;
    if (pathname.startsWith('/manage')) {
      skip = true
    } else if (pathname.startsWith('/create-care-recipient')) { // TOOD: why the fuck doesn't this have a /manage prefix anyway?
      skip = true
    }

    if (skip) {
      setUrlMetadata({});
      setCurrentPath(pathname);
    } else {
      getPageTitle(URL_METASEO(pathname), { auth: false }).then((result) => {
        if (result.redirect) {
          // redirect to result.url
          return navigate(result.url, { replace: true});
        }
        setCurrentPath(pathname);
        setUrlMetadata(result);
      }).catch((result) => {
        if (result?.error === 'url_metaseo_not_found') {
          setCurrentPath(pathname); // failed to find route in metaseo, just set pathname so the 404 component can render
        }
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (!layoutRef && ref) {
      LayoutObservable.setLayoutRef(ref);
    }
  }, [layoutRef, ref])

  useIsomorphicLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]); 

  function handleClick() {
    setClose(!close);
    setBlock(close);
  }

  function searchTabOverride() {
    // There are cases where we want to force which search to render
    if (pathname.startsWith('/resources')) {
      return SearchTabs.RESOURCES;
    }
    return '';
  }

  return (
    <>
      <Helmet>
        <title>{urlMetadata?.title || 'Caregiving.com'}</title>
        <meta name="description" content={urlMetadata?.meta_desc || META_FALLBACKS.description} />
        <meta name="keywords" content={urlMetadata?.meta_keywords || ''} />
        <meta property="og:type" content={urlMetadata?.og_type || 'website'} />
        <meta property="og:title" content={urlMetadata?.og_title || META_FALLBACKS.title} />
        <meta property="og:description" content={urlMetadata?.og_desc || META_FALLBACKS.description} />
        <meta property="og:image" content={getParsedImageURL(urlMetadata?.og_image, ImageSizes.BANNER) || META_FALLBACKS.image} />
      </Helmet>
      <Alert />
      {showHeader && <NFCSBar />}
      <Container fluid className={`px-0 main ${isNfcsHeroEnabled ? styles.cgLayout__nfcsHero : ''} ${isNfcsBarEnabled ? styles.cgLayout__nfcsBar : ''}`}>
        {showHeader && (
          <HeaderComponent
            handleClick={handleClick}
            searchTab={searchTabOverride()}
          />
        )}
        <Row noGutters>
          {showSideMenu && (
            <SideMenuComponent
              closed={close || isShowingRightPanel}
              blocked={block}
              setClose={setClose}
            />
          )}
          {showLayout && (
            <Col
              className={
                showSideMenu &&
                `${
                  close ? styles.cgLayout__sidebarClosed : styles.cgLayout
                } ${
                  isShowingRightPanel ? styles.cgLayout__rightPanelOpen : ''
                } px-0  py-5`
              }
              ref={ref}
            >
              {showNavPills && <NavPillsBar sideMenuClosed={close} />}
              <SafeMainComponent showSideMenu={showSideMenu}>
                {pathname === currentPath && children}
              </SafeMainComponent>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

MainComponent.propTypes = {
  showSideMenu: PropTypes.bool
}

LayoutComponent.propTypes = {
  children: PropTypes.any
}

export default LayoutComponent;
