import {LanguageHelper} from '@/languages/helper';
import React, { useEffect, useState } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import { stringify, parse } from 'query-string';
import { UserObservable } from '@/observables/user.observable';
import { LayoutObservable } from '@/observables/layout.observable';
import {useObservable} from '@/hooks';
import SearchComponent from '@/components/atoms/search';
import PartnerButton from '@/components/molecules/partner-button';
import { SearchTabs } from '@/constants/search-tabs.constant';
import { SearchSettings } from '@/constants/search-settings.constant';
import HamburgerMenu from '@/assets/icons/hamburger-menu.svg';
import AvatarComponent from '@/components/atoms/avatar/index.jsx';
import CaregivingLogo from '@/assets/images/2023-caregiving-logo.png';
import CaregivingName from '@/assets/icons/caregiving-name.svg';
import CaregivingIcon from '@/assets/images/caregiving-icon.png';
import styles from './header.module.scss';
import Button from '@/components/atoms/button';
import { Row } from 'react-bootstrap'
import UserDropdown from '@/components/molecules/user-dropdown';
import {SearchFiltersObservable} from '@/observables/search-filter.observable';
import { LOGIN_ROUTE } from '@/constants/redirect-login';

const HeaderComponent = (props) => {
  const [user] = useObservable(UserObservable.user$);
  const [filterText] = useObservable(SearchFiltersObservable.filterText$);
  const [isNfcsHeroEnabled] = useObservable(LayoutObservable.nfcsHeroEnabled$)
  const [isNfcsHeroVisible] = useObservable(LayoutObservable.nfcsHeroInView$)
  const [isNfcsBarEnabled] = useObservable(LayoutObservable.nfcsBarEnabled$)
  const history = useNavigate();
  const [searchQuery, setSearchQuery] = useState({text: '', location: '', type: {label: ''}});
  const [userOptions, setUserOptions] = useState(false);
  const [refAvatar, setRefAvatar] = useState(null);
  const { pathname, search } = useLocation();
  const loginLink = LOGIN_ROUTE;

  useEffect(() => {
    // after loading, set searchQuery's default value to values from url
    if (pathname && pathname.length > 0) {
      const queryData = {
        type: {label: ''}
      }
      const results = pathname.match(/^\/resources\/([a-zA-Z-]+)/);
      if (results) {
        queryData.type.type = results[1];
      }

      const querystring = parse(search);
      if (querystring && querystring[SearchSettings.KeyTypeLabel]) {
        queryData.type.label = atob(querystring[SearchSettings.KeyTypeLabel] || '');
      }
      if (querystring && querystring[SearchSettings.KeyKeyword]) {
        queryData.location = atob(querystring[SearchSettings.KeyKeyword] || '');
      }
      setSearchQuery(Object.assign({}, searchQuery, queryData));
    }
  }, [pathname]);

  const onSearchClick = (type) => {
    const options = { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' };

    if (type === SearchTabs.RESOURCES) {
      const searchData = {};
      let url = '/resources'
      if (searchQuery.type && searchQuery.type.type) {
        url = `${url}/${searchQuery.type.type}`;
        searchData[SearchSettings.KeyTypeLabel] = btoa(searchQuery.type.label);
      }
      if (searchQuery.location && searchQuery.location.length > 0) {
        searchData[SearchSettings.KeyKeyword] = btoa(searchQuery.location);
      }

      // if no search data to encode, return
      if (Object.keys(searchData).length === 0) {
        return;
      }
      history(`${url}?${stringify(searchData, options)}`);
    } else {
      const searchData = {
        term: btoa(searchQuery.text),
      };
      history(`/search?${stringify(searchData, options)}`);
    }
  }

  const onSubmit = (type, e) => {
    e?.preventDefault();
    onSearchClick(type);
  }

  const onUserAvatarClick = () => {
    setUserOptions(!userOptions)
  }

  return (
    <div className={`${styles.cgHeader} ${isNfcsHeroEnabled && isNfcsHeroVisible ? styles.cgHeader__withNfcsHero : styles.cgHeader__withoutNfcsHero} ${isNfcsBarEnabled ? styles.cgHeader__withNfcsBar : ''}`}>
      <div className={`d-none d-md-flex d-lg-flex ${styles.cgHeader__menu}`}>
        <HamburgerMenu className={styles.cgHeader__hamburgerMenu} onClick={props.handleClick} />
        <Link to="/"><img alt="Caregiving.com Logo" src={CaregivingLogo} className={styles.cgHeader__logo} /></Link>
      </div>
      <HamburgerMenu className={`d-block d-md-none d-lg-none ${styles.cgHeader__hamburgerMenu}`} onClick={props.handleClick} />
      <Row className='d-block d-md-none d-lg-none' as={Link} to='/' aria-label="Caregiving.com Home">
        <img alt="Caregiving.com" src={CaregivingIcon} className={styles.cgHeader__caregivingIcon} />
        <CaregivingName className={styles.cgHeader__logo} />
      </Row>
      <div className={styles.cgHeader__search}>
        <SearchComponent
          onEnterPress={onSubmit.bind(this, SearchTabs.POSTS)}
          onChange={(text) => {
            SearchFiltersObservable.setFilterText(text);
            setSearchQuery({text: text})
          }}
          value={filterText || ''}
          onClear={() => {
            setSearchQuery({text: ''});
            SearchFiltersObservable.setFilterText('');
          }}
          hideAutoComplete />
      </div>
      <div className={`d-none d-md-block d-lg-block ${styles.cgHeader__user_auth_container}`}>
        <div className={styles.cgHeader__partner}>
          <PartnerButton />
        </div>
        <div className={styles.cgHeader__user_auth}>
          {user ?
            <AvatarComponent
              className={styles.cgHeader__avatar}
              photo={user.avatar?.location}
              name={`${user?.firstname} ${user?.lastname}` || user.fullname}
              onClick={onUserAvatarClick}
              setRefAvatar={(ref) => setRefAvatar(ref)}/> :
            <Button as='a' href={loginLink} className={styles.cgHeader__user_auth_login}>{LanguageHelper('buttons.login')}</Button>
          }
          {user && userOptions &&
            <UserDropdown user={user} refAvatar={refAvatar} showDropdown={(state) => setUserOptions(state)}/>}
        </div>
      </div>
    </div>
  )
};

HeaderComponent.propTypes = {
  handleClick: PropTypes.func,
  searchTab: PropTypes.string,
}

export default HeaderComponent;
