const API_VERSION = '/api/v1';

export const ARTICLE_GROUP = (key) => `${API_VERSION}/article_groups/${key}`;
export const HOMEPAGE = `${API_VERSION}/homepage`;
export const USER_BY_UUID = (uuid) => `${API_VERSION}/users/${uuid}`;
export const USER_CREATE = `${API_VERSION}/users/create`;
export const USER_CARE_RECIPIENTS = `${API_VERSION}/care_recipients`;
export const USER_CARE_RECIPIENTS_INVITES = `${API_VERSION}/care_recipients/invites`;
export const USER_CARE_RECIPIENTS_INVITES_ACCEPT = (uuid, inviteUuid) => `${API_VERSION}/care_recipients/${uuid}/invites/${inviteUuid}/accept`;
export const USER_CARE_RECIPIENTS_INVITES_DECLINE = (uuid, inviteUuid) => `${API_VERSION}/care_recipients/${uuid}/invites/${inviteUuid}/decline`;
export const CARE_RECIPIENT_BY_UUID = (uuid) => `${API_VERSION}/care_recipients/${uuid}`;
export const CARE_RECIPIENT_MEMBER_BY_UUID = (uuid, memberUuid) => `${API_VERSION}/care_recipients/${uuid}/members/${memberUuid}`;
export const CARE_RECIPIENT_PHOTO_UPLOAD = (uuid) => `${API_VERSION}/care_recipients/${uuid}/photo/upload`;
export const CARE_RECIPIENT_PHOTO_DELETE = (uuid) => `${API_VERSION}/care_recipients/${uuid}/photo/delete`;
export const CARING_FEELINGS_LIST = `${API_VERSION}/caring_feelings`;
export const CARING_TASKS_LIST = `${API_VERSION}/caring_tasks`;
export const RELATIONSHIPS_LIST = `${API_VERSION}/relationships`;
export const MEDIA_SERIES_SEASON = (series, season) => `${API_VERSION}/media_series/${series}/${season}`;
export const MEMBER_BY_UUID = (uuid, memberUuid) => `${API_VERSION}/care_recipients/${uuid}/members/${memberUuid}`;
export const MEMBER_BY_INVITE = (code) => `${API_VERSION}/care_recipients/invites/${code}`;
export const MEMBER_RESEND_INVITE = (uuid, memberUuid) => `${API_VERSION}/care_recipients/${uuid}/invites/${memberUuid}/resend`;
export const INVITE_NEW_MEMBERS = (uuid) => `${API_VERSION}/care_recipients/${uuid}/invites/create`;
export const SUPPORT_LAYER_CREATE_PERSONA_TOKEN = `${API_VERSION}/support_layer/identify`;
export const SUPPORT_LAYER_GROUPS = `${API_VERSION}/support_layer`;
export const SUPPORT_LAYER_GROUP = (groupKey) => `${API_VERSION}/support_layer/${groupKey}`;
export const CARE_CONDITIONS = `${API_VERSION}/care_conditions`;
export const MEDICATIONS = `${API_VERSION}/medications`;
export const TOPICS = `${API_VERSION}/topics`;
export const POST_BY_URI = (path) => `${API_VERSION}/content/fetch?uri=${encodeURIComponent(path)}`;
export const ONE_LINK = (source) => `${API_VERSION}/one_link/${encodeURIComponent(source)}`;
export const URL_METASEO = (pathname) => `${API_VERSION}/metadata/url?pathname=${encodeURIComponent(pathname)}`;
export const AUTH_LOGIN = `${API_VERSION}/auth/login`;
export const AUTH_REFRESH = `${API_VERSION}/auth/refresh`;
export const AUTH_FORGOT_PASSWORD = `${API_VERSION}/auth/forgot-password`;
export const SESSION_LOGOUT = `${API_VERSION}/session/logout`;
export const USER_SAVES = (uuid) => `${API_VERSION}/users/${uuid}/saves`;
export const USER_SAVES_DELETE = (uuid, saveUuid) => `${API_VERSION}/users/${uuid}/saves/${saveUuid}`;
export const USER_SAVES_CREATE = (uuid) => `${API_VERSION}/users/${uuid}/saves`;
export const USER_FOLLOWS = (uuid) => `${API_VERSION}/users/${uuid}/follows`;
export const USER_FOLLOWS_DELETE = (uuid, followUuid) => `${API_VERSION}/users/${uuid}/follows/${followUuid}`;
export const USER_FOLLOWS_CREATE = (uuid) => `${API_VERSION}/users/${uuid}/follows`;
export const CONTRIBUTORS = `${API_VERSION}/contributors`;
export const CONTRIBUTOR_DETAILS = (key) => `${API_VERSION}/contributors/${key}`;
export const CONTRIBUTOR_CONTACT = `${API_VERSION}/contact/contributors`;
export const CARE_CATEGORIES = `${API_VERSION}/care_categories`;
export const GLOBAL_SEARCH = `${API_VERSION}/search/full`;
export const GLOBAL_SEARCH_AUTOCOMPLETE = `${API_VERSION}/search/autocomplete`;
export const CHECK_USER = `${API_VERSION}/users/check`;
export const VALIDATE_USERNAME = (username) => `${API_VERSION}/users/search?username=${username}`;
export const BRAND = (key) => `${API_VERSION}/brands/${key}`;
export const BRAND_SURVEY = (uuid) => `${API_VERSION}/brands/${uuid}/survey`;
export const BRANDS = `${API_VERSION}/brands`;
export const BRANDS_BY_LIST = (list) => `${API_VERSION}/brands?list=${list.join(',')}`;
export const BRAND_CATEGORY = (key, category_type, category_key) => `${API_VERSION}/brands/${key}/${category_type}/${category_key}`;
export const COLLECTIONS = `${API_VERSION}/collections`;
export const UPLOAD_USER_PHOTO = (uuid) => `${API_VERSION}/users/${uuid}/photo`;
export const DELETE_USER_PHOTO = (uuid) => `${API_VERSION}/users/${uuid}/photo/delete`;
export const COLLECTIONS_FEATURED = `${API_VERSION}/collections?featured_only=true`;
export const COLLECTION = (key) => `${API_VERSION}/collections/${key}`;
export const USER_LIKES = (uuid) => `${API_VERSION}/users/${uuid}/likes`;
export const USER_LIKES_DELETE = (uuid, likeUuid) => `${API_VERSION}/users/${uuid}/likes/${likeUuid}`;
export const USER_LIKES_CREATE = (uuid) => `${API_VERSION}/users/${uuid}/likes`;
export const COMMENTS = (uuid) => `${API_VERSION}/content/${uuid}/comments`;
export const COMMENT_REPLIES = (uuid, comment_uuid) => `${API_VERSION}/content/${uuid}/comments/${comment_uuid}/replies`;
export const COMMENTS_CREATE = (uuid) => `${API_VERSION}/content/${uuid}/comments`;
export const COMMENTS_EDIT = (uuid, comment_uuid) => `${API_VERSION}/content/${uuid}/comments/${comment_uuid}`;
export const COMMENTS_LIKE = (uuid, comment_uuid) => `${API_VERSION}/content/${uuid}/comments/${comment_uuid}/like`;
export const COMMENTS_FLAG = (uuid, comment_uuid) => `${API_VERSION}/content/${uuid}/comments/${comment_uuid}/flag`;
export const COMMENTS_DELETE = (uuid, comment_uuid) => `${API_VERSION}/content/${uuid}/comments/${comment_uuid}`;
export const ARTICLES_INTERACTIONS = (uuid) => `${API_VERSION}/articles/${uuid}/interactions`
export const COMMENTS_UNLIKE = (uuid,comment_uuid) => `${API_VERSION}/content/${uuid}/comments/${comment_uuid}/like`
export const QUIZ_START = (quiz_uuid) => `${API_VERSION}/quizzes/${quiz_uuid}/start`
export const QUIZ_ANSWER = (response_uuid) => `${API_VERSION}/quizzes/response/${response_uuid}`
export const OAUTH_CLIENT = (client_id) => `${API_VERSION}/auth/oauth/clients/${client_id}`
export const OAUTH_AUTHORIZE_CODE = `${API_VERSION}/auth/oauth/authorize`
export const PARTNER = `${API_VERSION}/marketing/partners`;
export const ARTICLE_JOBS = `${API_VERSION}/article_groups/jobs`;
export const USER_PROGRESS = (uuid) => `${API_VERSION}/users/${uuid}/progress`
export const BADGES = `${API_VERSION}/badges?include_unconfigured=true`;
export const LIVING_SITUATIONS = `${API_VERSION}/living_situations`;
export const CREATE_CARE_RECIPIENT = `${API_VERSION}/care_recipients/create`;
export const CREATE_CARE_RECIPIENT_PERSONA = `${API_VERSION}/care_recipients/create-persona`;
export const MAILING_LIST_SUBSCRIBE = `${API_VERSION}/marketing/mailing_list/subscribe`;
export const GENDERS = `${API_VERSION}/genders`;
export const ETHNICITIES = `${API_VERSION}/ethnicities`;
export const EMPLOYMENT_STATUSES = `${API_VERSION}/employment_statuses`;
export const SECTIONS_BY_TYPE = (type) => `${API_VERSION}/sections/types/${type}`;
export const SECTION = (pathname) => `${API_VERSION}/sections/fetch?uri=${encodeURIComponent(pathname)}`;
export const SECTION_BY_UUID = (uuid) => `${API_VERSION}/sections/${uuid}`;
export const USER_SUBSCRIPTIONS = (uuid, subType) => `${API_VERSION}/users/${uuid}/follows?${subType ? `type=${subType}` : '' }`;
