import { useEffect, useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useHttpGet } from '@/hooks/use-http';
import { USER_CARE_RECIPIENTS} from '@/constants/endpoints.constant';
import { UserObservable } from './user.observable';

const careRecipientsSubject = new BehaviorSubject([]);
const careRecipientLoadedSubject = new BehaviorSubject(false);

let isCareRecipientsLoading = false;

export const useCareRecipientsObservable = (host) => {
  const { execute } = useHttpGet(host); // This is not valid since useHttpHook is a hook and cannot be called here
  const loadCareRecipients = useCallback((force = false) => {
    if (!force && (!UserObservable.userSubject.getValue() || careRecipientLoadedSubject.getValue() || isCareRecipientsLoading)) {
      return;
    }
    isCareRecipientsLoading = true;

    execute(USER_CARE_RECIPIENTS).then(data => {
      careRecipientsSubject.next(data.items);
      careRecipientLoadedSubject.next(true);
      isCareRecipientsLoading = false;
    }).catch(() => {
      // Handle error
      careRecipientLoadedSubject.next(true);
      isCareRecipientsLoading = false;
    });
  }, [careRecipientsSubject, careRecipientLoadedSubject]);

  // Initial fetch and setup
  useEffect(() => {
    loadCareRecipients(); // Call on mount and when url changes
  }, [loadCareRecipients]);

  return {
    careRecipients$: careRecipientsSubject,
    careRecipientLoaded$: careRecipientLoadedSubject,
    reload: () => { loadCareRecipients(true) },
    clear: () => {
      careRecipientsSubject.next([]);
      careRecipientLoadedSubject.next(false);
    }
  };
}
