import { BehaviorSubject } from 'rxjs';

/**
 * Store that helps 2-way binding between the search page and the search-filters bar/organism
 * Array of type {
 *   label: string,
 *   value: string,
 *   type: string of FilterSubTypes
 * }
 * @type {BehaviorSubject<*[]>}
 */
const filtersSubject = new BehaviorSubject([]);
const dismissedFiltersSubject = new BehaviorSubject([]);
const availableOptionsToFilterSubject = new BehaviorSubject({
  types: [],
  authors: [],
});
const filterTextSubject = new BehaviorSubject(undefined);

export const SearchFiltersObservable = Object.freeze({
  addFilter: (filter) => {
    filtersSubject.next([...filtersSubject.getValue(), filter]);
  },
  addFilters: (filters) => {
    filtersSubject.next([...filtersSubject.getValue(), ...filters]);
  },
  replaceFilter: (filter) => {
    filtersSubject.next([...filtersSubject.getValue().filter(f => f.type !== filter.type), filter]);
  },
  removeFilter: (filter) => {
    filtersSubject.next(filtersSubject.getValue().filter(f => f.value !== filter.value));
  },
  clearFiltersOfType: (type) => {
    filtersSubject.next(filtersSubject.getValue().filter(f => f.type !== type));
  },
  clearFilters: () => {
    filtersSubject.next([]);
  },
  // Track dismissed filters to ensure a 2way sync with multi-check dropdowns
  addDismissedFilter: (filter) => {
    dismissedFiltersSubject.next([...dismissedFiltersSubject.getValue(), filter]);
  },
  removeDismissedFilter: (filter) => {
    dismissedFiltersSubject.next(dismissedFiltersSubject.getValue().filter(f => f.value !== filter.value));
  },
  clearDismissedFilters: () => {
    dismissedFiltersSubject.next([]);
  },
  setAvailableOptionsToFilter: (types = [], authors = []) => {
    availableOptionsToFilterSubject.next({types, authors});
  },
  setFilterText: (text) => {
    filterTextSubject.next(text);
  },
  filters$: filtersSubject.asObservable(),
  dismissedFilters$: dismissedFiltersSubject.asObservable(),
  availableOptionsToFilter$: availableOptionsToFilterSubject.asObservable(),
  filterText$: filterTextSubject.asObservable(),
});
