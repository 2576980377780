export const AboutTranslations = {
    aboutTranslations:{
        banner: {
            title1: 'Participar hoy.',
            title2: 'Empoderar el mañana.',
        },
        LeftText:{
            title: 'Misión',
            text: 'Caregiving.com se compromete a involucrar y capacitar a los cuidadores familiares en todo el mundo a través de un modelo de apoyo multifacético brindado a través de nuestra plataforma de vanguardia. Nos esforzamos por inspirar un sentido de comunidad que involucre y apoye a los cuidadores en el desarrollo de resiliencia y sostenibilidad a medida que avanzan en su viaje de cuidado. Asimismo, pretendemos ser un espacio seguro y accesible para que todos los cuidadores familiares encuentren consuelo, apoyo y asesoramiento.',
        },
        RightText:{
            title: 'Visión',
            text: 'Al involucrar a los cuidadores hoy, podemos empoderarlos para el viaje del mañana.',
        },
        whyBanner:{
            title: 'Por qué?',
            text: 'Los cuidadores familiares son la base de la atención médica y apoyan a las poblaciones que envejecen y padecen diversas afecciones. Estamos impulsados a transformar su experiencia. A través del apoyo de la comunidad, contenido nuevo, incentivos, asociaciones con sistemas de salud y un diseño de primer nivel, nuestro objetivo es revolucionar la experiencia de los cuidadores en todo el mundo, elevando la atención médica en general.',
        },
        howTitle:{
            title: 'Cómo?',
            howStandOut: 'En Caregiving.com, nos destacamos por un enfoque holístico de apoyo a los cuidadores familiares. Afrontamos los desafíos del cuidado de frente, identificamos brechas y ofrecemos soluciones. Nuestra extensa biblioteca forma la base, complementada con piezas centradas en la acción, videos, comunidad y más.',
            howSupport: 'Caregiving.com ofrece un nuevo ámbito de apoyo a los cuidadores, que combina el cariño con el conocimiento. Creamos un viaje personalizado, abordando varios aspectos del cuidado, incluida la capacitación, las habilidades, las finanzas, las emociones, las conexiones, la espiritualidad, la cultura y más.',
            howFamily: 'También incluimos a toda la familia, reconociendo su impacto en los cuidadores. Damos la bienvenida y valoramos a todos, independientemente del tamaño de su función de cuidado, lo que los convierte en una parte esencial del equipo.',
        },
        caregivingPromise:{
            title: 'La promesa del cuidado',
            text: 'Nuestra promesa y compromiso con los cuidadores familiares es que su experiencia en Caregiving.com será útil, impactante y agradable. La información que comparta con nosotros solo se utilizará para mejorar su experiencia en el sitio y nunca compartiremos ni venderemos su información individual con ninguna empresa externa. Queremos conectarlo con la información o los recursos que necesita según sus propios términos. Nunca recibirá llamadas telefónicas ni correos electrónicos de empresas que obtuvieron su información de nosotros. Eso no es lo que somos y sabemos que eso no es lo que quieres. Tu experiencia y tu confianza son nuestra máxima prioridad.',
        },
        companyTitle: 'Company News',
    }
}