export const PrivacyPolicyTranslations = {
    //begin privacy policy page
    privacyPolicyTranslations: {
        title: 'Política de privacidad',
        effectiveDate: 'Fecha de vigencia: 27 de mayo de 2018',
        paragraph1: 'Esta página le informa sobre nuestras políticas con respecto a la recopilación, el uso y la divulgación de datos personales cuando utiliza nuestro Servicio y las opciones que tiene asociadas con esos datos.',
        paragraph2: 'Utilizamos sus datos para proporcionar y mejorar el Servicio. Al usar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta política. A menos que se defina lo contrario en esta Política de privacidad, los términos utilizados en esta Política de privacidad tienen los mismos significados que en nuestros Términos y condiciones, accesibles desde ',
        link: 'https://www.caregiving.com/',
        definitions: 'Definiciones',
        service: 'Servicio ',
        serviceText: 'El servicio es el ',
        website: ' sitio web.',
        personalData: 'Información personal ',
        personalDataText: 'Datos personales se refiere a los datos sobre una persona viva que puede identificarse a partir de esos datos (o de esa y otra información que tengamos en nuestro poder o que sea probable que llegue a nuestro poder).',
        usageData: 'Datos de uso ',
        usageDataText: 'Los Datos de uso son datos recopilados automáticamente, ya sea generados por el uso del Servicio o de la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).',
        cookies: 'Galletas ',
        cookiesText: 'Las cookies son pequeños fragmentos de datos almacenados en su dispositivo (ordenador o dispositivo móvil).',
        dataController: 'Controlador de datos ',
        dataControllerText: 'Controlador de datos significa la persona física o jurídica que (ya sea sola o conjuntamente o en común con otras personas) determina los propósitos para los cuales y la manera en que se procesa o se procesará cualquier información personal. A los efectos de esta Política de privacidad , somos un controlador de datos de sus datos personales.',
        dataProcessors: 'Procesadores de datos (o proveedores de servicios) ',
        dataProcessorsText: 'Procesador de datos (o Proveedor de servicios) significa cualquier persona física o jurídica que procesa los datos en nombre del Controlador de datos. Podemos utilizar los servicios de varios Proveedores de servicios para procesar sus datos de manera más efectiva.',
        dataSubject: 'Sujeto de datos (o usuario) ',
        dataSubjectText: 'Sujeto de datos es cualquier individuo vivo que utiliza nuestro Servicio y es sujeto de Datos personales.',
        collectedInformation: 'Recopilación y uso de información',
        collectedInformationText: 'Recopilamos varios tipos diferentes de información para varios propósitos para brindarle y mejorar nuestro Servicio.',
        personalDataText2: {
            paragraph1: 'Mientras usa nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede usar para contactarlo o identificarlo ("Datos personales"). La información de identificación personal puede incluir, entre otros:',
            listItem1: 'Dirección de correo electrónico',
            listItem2: 'Nombre y apellido',
            listItem3: 'Cookies y datos de uso',
            paragraph2: 'Podemos usar sus Datos personales para comunicarnos con usted con boletines informativos, materiales promocionales o de marketing y otra información que pueda ser de su interés. Puede optar por no recibir ninguna o todas estas comunicaciones de nuestra parte siguiendo el enlace para cancelar la suscripción o las instrucciones proporcionadas en cualquier correo electrónico que enviemos o poniéndose en contacto con nosotros.'
        },
        comments: 'Comentarios',
        commentsText: {
            paragraph1: 'Cuando los visitantes dejan comentarios en el sitio, recopilamos los datos que se muestran en el formulario de comentarios, y también la dirección IP del visitante y la cadena del agente de usuario del navegador para ayudar a la detección de spam. Hacemos esto para evitar que los spammers accedan a nuestro contenido. Se puede proporcionar una cadena anónima creada a partir de su dirección de correo electrónico (también llamada hash) al servicio Gravatar para ver si la está utilizando. La política de privacidad del servicio Gravatar está disponible aquí: ',
            link: 'https://automattic.com/privacy/',
            paragraph2: '. Después de la aprobación de su comentario, su foto de perfil es visible para el público en el contexto de su comentario. Los comentarios de los visitantes puede que los revise un servicio de detección automática de spam.'
        },
        media: 'Medios de comunicación',
        mediaText: 'Si subes imágenes al sitio web, debes evitar subir imágenes con datos de ubicación incrustados (GPS EXIF) incluidos. Los visitantes del sitio web pueden descargar y extraer cualquier dato de ubicación de las imágenes del sitio web.',
        contactForms: 'formularios de contacto',
        contactFormsText: 'Mantenemos los envíos de formularios de contacto con fines de servicio al cliente, pero no utilizamos la información enviada a través de ellos con fines de marketing.',
        embeddedContent: 'Contenido incrustado de otros sitios web',
        embeddedContentText: 'Los artículos en este sitio pueden incluir contenido incrustado (por ejemplo, videos, imágenes, artículos, etc.). El contenido incrustado de otros sitios web se comporta exactamente de la misma manera que si el visitante hubiera visitado el otro sitio web. Estos sitios web pueden recopilar datos sobre usted, usar cookies, incrustar un seguimiento adicional de terceros y monitorear su interacción con ese contenido incrustado, incluido el seguimiento de su interacción con el contenido incrustado si tiene una cuenta y ha iniciado sesión en ese sitio web.',
        usageDataText2: 'También podemos recopilar información sobre cómo se accede y utiliza el Servicio ("Datos de uso"). Estos Datos de uso pueden incluir información como la dirección del Protocolo de Internet de su computadora (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, único identificadores de dispositivos y otros datos de diagnóstico.',
        cookieData: 'Datos de seguimiento y cookies',
        cookieDataText: {
            paragraph1: 'Usamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y conservar cierta información.',
            paragraph2: 'Las cookies son archivos con una pequeña cantidad de datos que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. Las tecnologías de seguimiento que también se utilizan son balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar nuestro Servicio.',
            paragraph3: 'Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio.',
            examples: 'Ejemplos de Cookies que utilizamos:',
            example1: 'Cookies de sesión. ',
            example1Text: 'Usamos Cookies de Sesión para operar nuestro Servicio.',
            example2: 'Cookies de preferencia. ',
            example2Text: 'Usamos cookies de preferencia para recordar sus preferencias y varias configuraciones.',
            example3:'Cookies de seguridad. ',
            example3Text:'Utilizamos cookies de seguridad por motivos de seguridad.'
        },
        useOfData: 'Uso de datos',
        useOfDataText: {
            paragraph1: 'Caregiving.com utiliza los datos recopilados para diversos fines:',
            listItem1: 'Para proporcionar y mantener nuestro Servicio',
            listItem2: 'Para notificarle sobre cambios en nuestro Servicio',
            listItem3: 'Para permitirle participar en funciones interactivas de nuestro Servicio cuando elija hacerlo',
            listItem4: 'Para proporcionar soporte al cliente',
            listItem5: 'Para recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio',
            listItem6: 'Para monitorear el uso de nuestro Servicio',
            listItem7: 'Para detectar, prevenir y abordar problemas técnicos',
            listItem8: 'Para brindarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó, a menos que haya optado por no recibir dicha información.',
        },
        legalBasis: 'Base legal para el procesamiento de datos personales según el Reglamento general de protección de datos (GDPR)',
        legalBasisText: {
            paragraph1: 'Si usted es del Espacio Económico Europeo (EEE), la base legal de Tad Publishing & Consulting para recopilar y usar la información personal descrita en esta Política de privacidad depende de los Datos personales que recopilamos y el contexto específico en el que los recopilamos. Caregiving.com puede procesar sus datos personales porque:',
            listItem1: 'Necesitamos realizar un contrato con usted',
            listItem2: 'Nos has dado permiso para hacerlo.',
            listItem3: 'El procesamiento está en nuestros intereses legítimos y no está anulado por sus derechos.',
            listItem4: 'Para fines de procesamiento de pagos',
            listItem5: 'Para cumplir con la ley'
        },
        retentionOfData: 'Retención de datos',
        retentionOfDataText: {
            paragraph1: 'Caregiving.com conservará sus Datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus Datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestras políticas y acuerdos legales.',
            paragraph2: 'Tad Publishing & Consulting también conservará los Datos de uso para fines de análisis interno. Los datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se usan para fortalecer la seguridad o mejorar la funcionalidad de nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.'
        },
        transferOfData: 'Transferencia de datos',
        transferOfDataText: 'Su información, incluidos los Datos personales, puede transferirse y mantenerse en computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción. Si se encuentra fuera de los Estados Unidos y elige proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los Datos personales, a los Estados Unidos y los procesamos allí. Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia. Tad Publishing & Consulting tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus Datos personales a una organización o país a menos que existan controles adecuados establecidos, incluido el seguridad de sus datos y otra información personal.',
        disclosureOfData: 'Divulgación de datos',
        outsideParties: 'Partes externas',
        outsidePartiesText:'No vendemos, intercambiamos ni transferimos a terceros su información de identificación personal. Esto no incluye a los terceros de confianza que nos ayudan a operar nuestro sitio web, llevar a cabo nuestro negocio o prestarle servicios, siempre que esas partes acuerden mantener esta información confidencial.',
        businessTransaction: 'Transacción de negocios',
        businessTransactionText: 'Caregiving.com está involucrado en una fusión, adquisición o venta de activos, sus Datos personales pueden transferirse. Le enviaremos un aviso antes de que sus Datos personales se transfieran y queden sujetos a una Política de privacidad diferente.',
        disclosureForLawEnforcement: 'Divulgación para la aplicación de la ley',
        disclosureForLawEnforcementText: 'En determinadas circunstancias, es posible que se le solicite a Tad Publishing & Consulting que divulgue sus Datos personales si así lo requiere la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).',
        legalRequirements: 'Requerimientos legales',
        legalRequirementsText: {
            paragraph1: 'Caregiving.com puede divulgar sus Datos personales de buena fe cuando considere que esta acción es necesaria para lo siguiente:',
            listItem1: 'Para cumplir con una obligación legal',
            listItem2: 'Para proteger y defender los derechos o propiedad de Tad Publishing & Consulting',
            listItem3: 'Para prevenir o investigar posibles irregularidades en relación con el Servicio',
            listItem4: 'Para proteger la seguridad personal de los usuarios del Servicio o del público',
            listItem5: 'Para protegerse contra la responsabilidad legal',
        },
        securityData: 'Seguridad de datos',
        securityDataText: 'La seguridad de sus datos es importante para nosotros. Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal cuando realiza un pedido o ingresa, envía o accede a su información personal. Recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos personales, no podemos garantizar su seguridad absoluta. Usamos Sucuri, una plataforma de seguridad de sitios web, para mantener nuestro sitio web protegido y responder a cualquier compromiso.',
        doNotTrack: 'Señales de "No rastrear"',
        doNotTrackText: 'No apoyamos Do Not Track ("DNT"). No rastrear es una preferencia que puede configurar en su navegador web para informar a los sitios web que no desea que lo rastreen. Puede habilitar o deshabilitar No rastrear visitando la página de Preferencias o Configuración de su navegador web.',
        dataProtections: 'Sus derechos de protección de datos según el Reglamento general de protección de datos (GDPR)',
        dataProtectionText: {
            paragraph1: 'Si es residente del Espacio Económico Europeo (EEE), tiene ciertos derechos de protección de datos. Tad Publishing & Consulting tiene como objetivo tomar medidas razonables para permitirle corregir, modificar, eliminar o limitar el uso de sus Datos personales.',
            paragraph2: 'Si desea que se le informe qué datos personales tenemos sobre usted y si desea que se eliminen de nuestros sistemas, comuníquese con nosotros.',
            paragraph3: 'En determinadas circunstancias, usted tiene los siguientes derechos de protección de datos:',
            listItem1: {
                title: 'Derecho a acceder, actualizar o eliminar la información que tenemos sobre usted.',
                text: 'Siempre que sea posible, puede acceder, actualizar o solicitar la eliminación de sus Datos personales directamente en la sección de configuración de su cuenta. Si no puede realizar estas acciones usted mismo, comuníquese con nosotros para ayudarlo.'
            },
            listItem2: {
                title: 'El derecho de rectificación.',
                text: 'Tiene derecho a que se rectifiquen sus datos si son inexactos o están incompletos.'
            },
            listItem3: {
                title: 'El derecho a oponerse.',
                text: 'Tiene derecho a oponerse a que procesemos sus Datos personales.'
            },
            listItem4: {
                title: 'El derecho de restricción.',
                text: 'Tiene derecho a solicitar que restrinjamos el procesamiento de su información personal.'
            },
            listItem5: {
                title: 'El derecho a la portabilidad de los datos.',
                text: 'Tiene derecho a recibir una copia de la información que tenemos sobre usted en un formato estructurado, legible por máquina y de uso común.'
            },
            listItem6: {
                title: 'El derecho a retirar el consentimiento.',
                text: 'También tiene derecho a retirar su consentimiento en cualquier momento cuando Tad Publishing & Consulting se haya basado en su consentimiento para procesar su información personal.'
            },
            paragraph4: 'Tenga en cuenta que podemos pedirle que verifique su identidad antes de responder a dichas solicitudes.',
            paragraph5: 'Tiene derecho a quejarse ante una autoridad de protección de datos sobre nuestra recopilación y uso de sus datos personales. Para obtener más información, comuníquese con su autoridad local de protección de datos en el Espacio Económico Europeo (EEE).',
        },
        serviceProviders: 'Proveedores de servicio',
        serviceProvidersText: 'Podemos emplear empresas e individuos de terceros para facilitar nuestro Servicio ("Proveedores de servicios"), para proporcionar el Servicio en nuestro nombre, para realizar servicios relacionados con el Servicio o para ayudarnos a analizar cómo se utiliza nuestro Servicio. Estos terceros tienen acceso a sus Datos personales solo para realizar estas tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para ningún otro propósito.',
        analytics: 'Analítica',
        analyticsText: {
            paragraph1: 'Podemos utilizar Proveedores de Servicios de terceros para monitorear y analizar el uso de nuestro Servicio.',
            listItem: 'Google analitico ',
            listItemText: 'Google Analytics es un servicio de análisis web ofrecido por Google que rastrea e informa el tráfico del sitio web. Google utiliza los datos recopilados para rastrear y monitorear el uso de nuestro Servicio. Estos datos se comparten con otros servicios de Google. Google puede utilizar los datos recopilados para contextualizar y personalizar los anuncios de su propia red de publicidad. Puede optar por no permitir que su actividad en el Servicio esté disponible para Google Analytics instalando el complemento de inhabilitación para navegadores de Google Analytics. El complemento evita que el JavaScript de Google Analytics (ga.js, analytics.js y dc.js) comparta información con Google Analytics sobre la actividad de las visitas. Para obtener más información sobre las prácticas de privacidad de Google, visite Privacidad y términos de Google página web: ',
            link: 'https://policies.google.com/privacy?hl=en'
        },
        payments: 'Pagos',
        paymentsText: {
            paragraph1: 'Podemos proporcionar productos y/o servicios pagos dentro del Servicio. En ese caso, utilizamos servicios de terceros para el procesamiento de pagos (por ejemplo, procesadores de pagos).',
            paragraph2: 'No almacenaremos ni recopilaremos los datos de su tarjeta de pago. Esa información se proporciona directamente a nuestros procesadores de pago de terceros, cuyo uso de su información personal se rige por su Política de privacidad. Estos procesadores de pago se adhieren a los estándares establecidos por PCI-DSS administrados por el PCI Security Standards Council, que es un esfuerzo conjunto de marcas como Visa, Mastercard, American Express y Discover. Los requisitos de PCI-DSS ayudan a garantizar el manejo seguro de la información de pago.',
            paragraph3: 'Los procesadores de pago con los que trabajamos son:',
            listItem1: {
                title: 'Stripe. ',
                text: 'Su política de privacidad se puede ver en ',
                link: 'https://stripe.com/us/privacy'
            },
            listItem2: {
                title: 'Square. ',
                text: 'Su política de privacidad se puede ver en ',
                link: 'https://squareup.com/legal/privacy-no-account'
            },
            listItem3: {
                title: 'PayPal. ',
                text: 'Su política de privacidad se puede ver en ',
                link: 'https://www.paypal.com/us/webapps/mpp/ua/privacy-full'
            },
        },
        otherSites: 'Enlaces a otros sitios',
        otherSitesText : 'Nuestro Servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite. No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.',
        childrenPrivacy: 'Privacidad de los niños',
        childrenPrivacyText: 'Nuestro Servicio no se dirige a ninguna persona menor de 18 años ("Niños"). No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 18 años. Si es padre o tutor y sabe que su hijo nos ha proporcionado Datos personales, comuníquese con nosotros. Si nos damos cuenta de que hemos recopilado Datos personales de niños sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.',
        changePolicy: 'Cambios a esta Política de Privacidad',
        changePolicyText: 'Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página. Le informaremos por correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la "fecha de vigencia" en la parte superior de esta Política de privacidad. Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.',
    },
    //end of privacy policy page
}