import React from 'react';
import PropTypes from 'prop-types';
import styles from '../videos.module.scss';
import CardShelf from '@/components/molecules/card-shelf';

const VideosListComponent = ({section}) => {

  const displayContent = () => {
    if (section?.content?.length === 0) {
      return null;
    }
    return (<CardShelf
      title={section.label} description={section.description}
      list={section.content} showMoreLink={section.url?.url}/>)
  }

  return (<div className={styles.cgVideo__videosList}>
    {displayContent()}
  </div>);
}

VideosListComponent.propTypes = {
  section: PropTypes.any,
};

export default VideosListComponent;
