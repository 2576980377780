import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import Dropdown from '@/components/atoms/dropdown';
import {CareRecipientData} from '@/constants/care_recipients';
import {useHttpGet, useHttpPatch, useTranslation} from '@/hooks';
import RelationshipsSelect from '@/components/molecules/relationships-select';
import {isEmpty} from 'validator';
import CgIcon from '@/components/atoms/cg-icon';
import {useNavigate, useParams} from 'react-router-dom';
import {CARE_RECIPIENT_BY_UUID, MEMBER_BY_INVITE} from '@/constants/endpoints.constant';
import styles from './care-situation.module.scss';

const CareSituation = () => {
    const [formData, setFormData] = useState({
            living_situation: '',
            creator: {
                relationship: '',
            }
        }
    );
    const [inviteName, setInviteName] = useState('');
    const [inviteUuid, setInviteUuid] = useState(null);
    const {execute: getMemberInvite} = useHttpGet();
    const {code} = useParams();
    const {t} = useTranslation();
    const LIVING_SITUATIONS = CareRecipientData.living_situations();
    const livingSituation = LIVING_SITUATIONS[formData.living_situation]?.label || formData.living_situation;
    const {execute: patchCareRecipient} = useHttpPatch();
    const history = useNavigate();
    const isRequired = () => {
        return <CgIcon iconName="BsAsterisk" color="#fe3232" size={8}/>;
    }

    useEffect(() => {
        getMemberInvite(MEMBER_BY_INVITE(code)).then(r => {
            setInviteName(r.invited_by.fullname);
            setInviteUuid(r.invited_by.uuid);
        })
    }, []);

    const onInputChange = (value) => {
        setFormData({...formData, ...value});
    }

    const submitForm = () => {
        patchCareRecipient(CARE_RECIPIENT_BY_UUID(inviteUuid), formData, { isJSON: false }).then(() => {
            history('/support');
        });
    }

    return (
        <Row className={styles.cgCareSituation}>
            <h1 className={styles.cgCareSituation__title}>{t('careRecipientTranslations.careSituation.title')} {inviteName}</h1>
            <p className={styles.cgCareSituation__subtitle}>{t('careRecipientTranslations.careSituation.subtitle')}</p>
            <Col xs={12} lg={6}>
                <p className={styles.cgCareSituation__inputTitle}>{t('careRecipientTranslations.livingSituation')} {isRequired()}</p>
                <Dropdown className="pb-3" placeholder={livingSituation || t('constantsTranslations.pleaseSelect')}
                          items={LIVING_SITUATIONS}
                          onChange={(label, value) => onInputChange({living_situation: value})}/>
            </Col>
            <Col xs={12} lg={6}>
                <p className={styles.cgCareSituation__inputTitle}>{t('careRecipientTranslations.relationship')} {isRequired()}</p>
                <RelationshipsSelect value={formData.creator?.relationship}
                                     onChange={(label, value, key) =>
                                         onInputChange({
                                             creator: {
                                                 ...formData.creator,
                                                 relationship: key
                                             }
                                         })}
                                     className={styles.cgCareSituation__input}
                                     dropdownClassName={styles.cgCareSituation__dropdownOption}
                                     ariaLabel="Select the relationship to the care recipient"
                                     defaultOptionName={`${t('careRecipientTranslations.relationshipPlaceholder.text1')} ${inviteName} ${t('careRecipientTranslations.relationshipPlaceholder.text2')}`}/>

            </Col>
            <Button disabled={isEmpty(formData.living_situation) || isEmpty(formData.creator.relationship)}
                    className={styles.cgCareSituation__button} onClick={submitForm}>Done</Button>
        </Row>
    )
}

export default CareSituation;
