import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@/components/atoms/dropdown';
import {useHttpGet} from '@/hooks/use-http'
import {useEffect} from 'react';
import {RELATIONSHIPS_LIST} from '@/constants/endpoints.constant';
import {useTranslation} from '@/hooks';
import {isEmpty, trim} from 'validator';
import styles from '@/components/pages/manage/care-recipients/create/form/form.module.scss';
import {sortItems} from '@/utils/sortList';

const RelationshipsSelect = ({value, onChange, className, ariaLabel, defaultOptionName, error}) => {
  const {execute: loadRelationships, data: relationships} = useHttpGet();
  const {t} = useTranslation();
  const [formTouched, setFormTouched] = useState(false);
  const [formErrors, setFormErrors] = useState('');

  useEffect(() => {
    loadRelationships(RELATIONSHIPS_LIST);
  }, []);

  useEffect(() => {
    if (error) {
      handleRelationshipBlur()
    }
  }, [error]);

  const handleRelationshipBlur = () => {
    setFormTouched(formTouched);
    validateField(value);
  };

  const validateField = (value) => {
    const errorMsg = getValidationError(value);
    setFormErrors(errorMsg);
  };

  const getValidationError = (value) => {
    if (isEmpty(trim(value))) {
      return t('careRecipientTranslations.required');
    }
    return;
  };

  const placeholder = relationships?.items?.find(({key})=> key === value)?.label || defaultOptionName || t('selectRelationship')

  return (
      <>
        <Dropdown
          className={formErrors ? `${styles.cgCreateCareRecipient__dropdownError}` : className} aria-label={ariaLabel}
          placeholder={placeholder} 
          items={sortItems(relationships?.items)}
          onChange={onChange}
          onBlur={handleRelationshipBlur}
          />
        {formErrors && (
            <div className={`${styles.cgCreateCareRecipient__dropdownError__text} text-danger`}>{formErrors}</div>
        )}
      </>
  );
}

RelationshipsSelect.propTypes = {
  ariaLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  defaultOptionName: PropTypes.string,
  error: PropTypes.bool,
}

export default RelationshipsSelect;
