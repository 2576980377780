import React from 'react';
import styles from '@/styles/_legal.module.scss';
import {useDataLayer, useTranslation} from '@/hooks';
import { Pages } from '@/constants/pages.constant';
import {Container} from 'react-bootstrap';
import SignupMailSection from '@/components/organisms/signup-mail-section';

const TermsOfUseComponent = () => {
  useDataLayer(Pages.TERMS_OF_USE);
  const {t} = useTranslation();

  return (
    <>
      <Container className="my-5">
        <div className={`${styles.cgLegal__section} justify-content-md-center mt-5 py-5`}>
          <div className={styles.cgLegal__subSection}>
            <h1 className={`${styles.cgLegal__title} pb-5`}>{t('termsOfUseTranslations.pageTitle')}</h1>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph1')}</p>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph2')}</p>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph3')}</p>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph4')}</p>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph5')}</p>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph6')}</p>
            <p className={`${styles.cgLegal__description} pb-3`}>{t('termsOfUseTranslations.paragraph7')}</p>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph9.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph9.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph10.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph10.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph11.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph11.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph12.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph12.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph13.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph13.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph14.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph14.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph15.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection3')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection4')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection5')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item3')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item4')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item5')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item6')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item7')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item8')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item9')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph15.subsection6.item10')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph16.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection3')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection4')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection5')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection6')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection7')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection8')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph16.subsection9')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph17.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph17.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph17.subsection2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph17.subsection3')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph18.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph18.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph19.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph19.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph19.subsection2')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph20.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph20.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph21.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection3')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection4')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection5')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection6')}</p>
              <p className={`${styles.cgLegal__interlinedBold} p-0`}>{t('termsOfUseTranslations.paragraph21.subsection7')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph21.subsection8')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph22.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph22.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph23.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph23.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph24.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph24.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph24.subsection2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph24.subsection3')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph25.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph25.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph26.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph26.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph27.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph27.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph28.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph28.description')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph29.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph29.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph29.subsection2')}</p>
            </div>

            <div className={`${styles.cgLegal__description} pb-3`}>
              <p className={`${styles.cgLegal__subtitle} p-0 m-0`}>{t('termsOfUseTranslations.paragraph30.title')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection1')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection2')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection3')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection4')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection5')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection6')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection7')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection8')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection9')}</p>
              <p className={`${styles.cgLegal__interlinedBold} p-0 m-0`}>{t('termsOfUseTranslations.paragraph30.subsection10')}</p>
              <p className='p-0 m-0'>{t('termsOfUseTranslations.paragraph30.subsection11')}</p>
            </div>

          </div>
        </div>
      </Container>
      <SignupMailSection headingNumber={2} />
    </>
  );
}

export default TermsOfUseComponent;