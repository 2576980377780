export const PodcastsTranslations = {
    podcastsPageTranslations:{
        recommended: 'Recommended',
        title: 'Podcasts',
        sponsoredBy: 'Sponsored by',
        hosts: 'Host',
        sendStory: {
            heading: 'The world needs to hear your caregiving story.',
            body: 'We’re featuring the story of a different family caregiver on each episode in a segment called How Did You Get Here. To celebrate. To relate. And to make the 53 million caregiving stories in the U.S. heard. For the chance to have your story featured, create a video or voice recording telling us your first name, who you care for, how you came to be a caregiver, and what it means to you to care for your loved one. Send us your submission using the button below.',
            send: 'Send your story',
        },
        displayTemporaryHardcodedRealTalkSection: {
            heading: 'About Our Champions',
            body1: 'Caregiving.com Champions are current or former family caregivers based in the U.S. and Canada who are dedicated to the mission of helping family caregivers feel seen, heard and valued.',
            body2: 'Through their work with Caregiving.com, these real life individuals are making a positive change for family caregivers in their local areas by building support networks, helping caregivers redefine normal throughout their caregiving journey, providing caregivers with a voice, engaging and empowering the local community to care for caregivers and sharing their own individual stories.',
            body3: 'The Champions share similar caregiving challenges to the 53 million unpaid family caregivers throughout the U.S. Our mission is to impact the lives of as many caregivers as possible and to help them not feel as isolated and alone.',
        },
    }
}
