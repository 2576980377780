import { LanguageHelper } from '@/languages/helper';

export const CareRecipientData = Object.freeze({
    insurances: function() {
        return {
            'eligible_plan': { value: 'eligible_plan', label: LanguageHelper('constantsTranslations.insurances.item1') },
            'based_plan': { value: 'based_plan', label: LanguageHelper('constantsTranslations.insurances.item2') },
            'long_term': { value: 'long_term', label: LanguageHelper('constantsTranslations.insurances.item3') },
            'medicaid': { value: 'medicaid', label: LanguageHelper('constantsTranslations.insurances.item4') },
            'medicare': { value: 'medicare', label: LanguageHelper('constantsTranslations.insurances.item5') },
            'medicare_advantage': { value: 'medicare_advantage', label: LanguageHelper('constantsTranslations.insurances.item6') },
            'private': { value: 'private', label: LanguageHelper('constantsTranslations.insurances.item7') },
            'tricare': { value: 'tricare', label: LanguageHelper('constantsTranslations.insurances.item8') },
            'uninsured': { value: 'uninsured', label: LanguageHelper('constantsTranslations.insurances.item9') },
            'veteran_admin': { value: 'veteran_admin', label: LanguageHelper('constantsTranslations.insurances.item10') },
        };
    },
})

export const CareRecipientSteps = {
    GENERAL_INFO: 0,
    CARE_SITUATION: 1,
    TOPICS: 2,
    INVITE: 3,
    FINAL: 4
}
