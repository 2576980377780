import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-bootstrap';

const Loading = () => (
  <>
    <Skeleton height='17em'></Skeleton>
    <Row className='py-4 justify-content-center align-items-center'>
      <Col md={1} xs={2} className='mr-4'>
        <Skeleton className='rounded-circle' height='60px' width='60px'></Skeleton>
      </Col>
      <Col md={3} xs={4}>
        <Skeleton count={2}></Skeleton>
      </Col>
    </Row>
    <Skeleton count={10}></Skeleton>
  </>
)

export default Loading;