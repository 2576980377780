import { useEffect, useState } from 'react';

export const useObservable = (observable, defaultValue) => {
  const [data, setData] = useState(defaultValue);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const subscription = observable.subscribe((value) => {
      setData(value);
      if (!initialized) {
        setInitialized(true);
      }
    });

    return () => subscription?.unsubscribe?.();
  }, []);

  return [data, initialized];
}
