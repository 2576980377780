export const MembersTranslations = {
    membersTranslations: {
        inviteSent: 'Invite has been sent.',
        addMember: 'Add Member',
        addNewMember: 'Add New Members',
        pageTitle: 'Members',
        name: 'Name',
        role: 'Role',
        relationship: 'Relationship',
        activity: 'Activity',
        dateAdded: 'Date added',
        inviteDescription: 'Invite new members to ',
        profile: 'profile',
        active: 'Active',
        removed: 'Removed',
        invited: 'Invited',
        dismissed: 'Dismissed',
        missingData: 'Missing Data',
        roles: {
            name: {
                owner: 'Owner',
                admin: 'Admin',
                viewer: 'Viewer'
            },

            description: {
                owner: 'Owns and manages this care recipient.',
                admin: 'Manages this care recipient.',
                viewer: 'Can view this care recipient.'
            }
        }
    }
}