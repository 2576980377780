export const getParsedImageURL = (url, size) => {
  if (typeof url !== 'string') {
    return '';
  }
  // anything hosted on google cloud storage will not have the pr: prefix
  if (url.includes('https://storage.googleapis.com') || url.includes('local.caregiving.com')) {
    size = size.replace('pr:', '')
  }
  return url.replace('{preset}', size);
}
