export const RewardsTranslations = {
    rewardsTranslations: {
        myRewards: 'Mi recompensa',
        collections: 'Colecciones',
        collectionText: 'Gane insignias respondiendo los cuestionarios al final del contenido de nuestra colección.',
        caregiving: 'cuidado',
        caregivingText: '¡Obtenga insignias maximizando el potencial de su perfil de Caregiving.com!',
        waysToEarn: 'Formas de ganar',
        earnText: 'Gane estrellas respondiendo los cuestionarios al final del contenido de nuestra colección y más opciones a continuación.',
        earn: 'Ganar',
        completed: 'Terminado',
        stars: 'Estrellas',
        badges: 'Insignias',
        congrats:'Felicidades, te has ganado tu',
        share: 'Compartir'

    }
}
