import { BehaviorSubject } from 'rxjs';

const showNavbarBanner = new BehaviorSubject(true);

export const NavbarObservable = Object.freeze({
  toggleDisplayBanner: (shouldShow) => {
    showNavbarBanner.next(shouldShow);
  },
  showNavbarBanner$: showNavbarBanner.asObservable(),
});
