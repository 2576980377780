import CaregivingLogo from '@/assets/icons/caregiving-logo.svg';
import AnthemBlueLogo from '@/assets/images/anthem-blue-logo.png';
import ElevanceHealthLogo from '@/assets/images/awards/elevance.png';
import CaregivingWhiteLogo from '@/assets/images/caregiving_logo_white.png';
import CaresourceLogo from '@/assets/images/caresource-logo.png';
import MarketingCommunity from '@/assets/images/marketing-community.png';
import MarketingContentLibrary from '@/assets/images/marketing-content-library.png';
import MarketingData from '@/assets/images/marketing-data.png';
import MarketingEmployers from '@/assets/images/marketing-employers.png';
import MarketingGraphDesktop from '@/assets/images/marketing-graph-desktop.png';
import MarketingGraphMobile from '@/assets/images/marketing-graph-mobile.png';
import MarketingHealthPlans from '@/assets/images/marketing-health-plans.png';
import MarketingHospitalSystems from '@/assets/images/marketing-hospital-systems.png';
import MarketingPartnerCommunity from '@/assets/images/marketing-partner-community.png';
import MarketingPartnerConnections from '@/assets/images/marketing-partner-connections.png';
import MarketingPartnerOutcomes from '@/assets/images/marketing-partner-outcomes.png';
import MarketingStatePrograms from '@/assets/images/marketing-state-programs.png';
import MarketingExperience from '@/assets/images/marketing-taylored-experience.png';
import MarketingTechnology from '@/assets/images/marketing-technology.png';
import OhiosHospiceLogo from '@/assets/images/ohios-hospice-logo.png';
import Button from '@/components/atoms/button';
import CarouselComponent from '@/components/molecules/carousel';
import homeStyles from '@/components/pages/home/home.module.scss';
import {useDisposeFooter, useDisposeHeader, useDisposeNavbarBanner, useDisposeSideMenu, useTranslation} from '@/hooks';
import {useHttpPost} from '@/hooks/use-http';
import {AlertObservable} from '@/observables/alert.observable';
import CgIcon from '@/components/atoms/cg-icon';
import React, {useEffect, useState} from 'react';
import {Button as RBButton, Card, Col, Container, Form, Image, Row} from 'react-bootstrap';
import {HashLink} from 'react-router-hash-link';
import {isEmail} from 'validator';
import styles from './marketing.module.scss';

const initialDemoRequestState = {
  name: '',
  company: '',
  title: '',
  phone: '',
  email: '',
};

const MarketingPage = () => {
  useDisposeHeader();
  useDisposeNavbarBanner();
  useDisposeFooter();
  useDisposeSideMenu();
  const {execute: post} = useHttpPost();
  const [demoRequest, setDemoRequest] = useState(initialDemoRequestState);
  const {t} = useTranslation();
  const [isResized, setIsResized] = useState({});
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // width set to 768.98 due to bootstrap break point restrictions.
    setIsResized(dimensions.width < 768.98);
  }, [dimensions]);


  const onInputChange = (value) => {
    setDemoRequest({...demoRequest, ...value})
  }

  const onSubmit = () => {
    post('/api/v1/marketing/partner/contact', demoRequest, { auth: false });
    AlertObservable.fireSuccess({
      title: t('marketingTranslations.submitted'),
      description: t('marketingTranslations.submitDescription')
    })
    setDemoRequest(initialDemoRequestState);
  }

  const disabledSubmit = !(demoRequest.name && demoRequest.company && demoRequest.title && demoRequest.phone && demoRequest.email && isEmail(demoRequest.email))

  return (
    <div className={styles.cgMarketing}>
      <div className={styles.cgMarketing__hero}>
        <div className={styles.cgMarketing__header}>
          <div className={styles.cgMarketing__header__logo}>
            <CaregivingLogo/>
          </div>
          <HashLink to="#demo"><Button
            className={styles.cgMarketing__header__button}>{t('marketingTranslations.demoButton')}</Button></HashLink>
        </div>
        <Container>
          <p className={styles.cgMarketing__hero__title}>
            {t('marketingTranslations.heroTitle')}
          </p>
          <p className={`${styles.cgMarketing__paragraph} text-center`}>
            {t('marketingTranslations.paragraph1')}
          </p>
          <p className={styles.cgMarketing__hero__subtitle}>
            {t('marketingTranslations.heroSubTitle')}
          </p>
        </Container>
      </div>
      <Container className="mt-5">
        <Image fluid src={MarketingGraphDesktop} className="d-none d-md-flex"/>
        <Image fluid src={MarketingGraphMobile} className="d-flex d-md-none"/>
      </Container>
      <Container>
        <Row className={styles.cgMarketing__serviceRow}>
          <Col xs={12} md={6}>
            <Image fluid src={MarketingExperience}/>
          </Col>
          <Col xs={12} md={6}>
            <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow1.title')}</p>
            <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow1.paragraph')}</p>
          </Col>
        </Row>
        {!isResized ? <Row className={styles.cgMarketing__serviceRow}>
            <Col xs={12} md={6}>
              <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow2.title')}</p>
              <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow2.paragraph')}</p>
            </Col>
            <Col xs={12} md={6}>
              <Image fluid src={MarketingContentLibrary}/>
            </Col>
          </Row>
          :
          <Row className={styles.cgMarketing__serviceRow}>
            <Col xs={12} md={6}>
              <Image fluid src={MarketingContentLibrary}/>
            </Col>
            <Col xs={12} md={6}>
              <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow2.title')}</p>
              <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow2.paragraph')}</p>
            </Col>
          </Row>
        }
        <Row className={styles.cgMarketing__serviceRow}>
          <Col xs={12} md={6}>
            <Image fluid src={MarketingCommunity}/>
          </Col>
          <Col xs={12} md={6}>
            <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow3.title')}</p>
            <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow3.paragraph')}</p>
          </Col>
        </Row>
        {!isResized ?
          <Row className={styles.cgMarketing__serviceRow}>
            <Col xs={12} md={6}>
              <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow4.title')}</p>
              <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow4.paragraph')}</p>
            </Col>
            <Col xs={12} md={6}>
              <Image fluid src={MarketingTechnology}/>
            </Col>
          </Row>
          :
          <Row className={styles.cgMarketing__serviceRow}>
            <Col xs={12} md={6} style={{paddingBottom: '30px'}}>
              <Image fluid src={MarketingTechnology}/>
            </Col>
            <Col xs={12} md={6}>
              <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow4.title')}</p>
              <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow4.paragraph')}</p>
            </Col>
          </Row>
        }
        <Row className={styles.cgMarketing__serviceRow}>
          <Col xs={12} md={6}>
            <Image fluid src={MarketingData}/>
          </Col>
          <Col xs={12} md={6}>
            <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.serviceRow5.title')}</p>
            <p className={styles.cgMarketing__paragraph}>{t('marketingTranslations.serviceRow5.paragraph')}</p>
          </Col>
        </Row>
      </Container>
      <div className={styles.cgMarketing__partneringBlock}>
        <Container>
          <p
            className={`${styles.cgMarketing__subtitle} text-center mt-5`}>{t('marketingTranslations.partners.title')}</p>
          <p
            className={`${styles.cgMarketing__paragraph} w-75 mr-auto ml-auto text-center`}>{t('marketingTranslations.partners.paragraph1')}</p>
          <Row>
            <Col className="mt-3" xs={12} md={4}><Image fluid src={MarketingPartnerCommunity}/></Col>
            <Col className="mt-3" xs={12} md={4}><Image fluid src={MarketingPartnerConnections}/></Col>
            <Col className="mt-3" xs={12} md={4}><Image fluid src={MarketingPartnerOutcomes}/></Col>
          </Row>
          <p className={`${styles.cgMarketing__paragraph} w-75 mr-auto ml-auto text-center mt-4`}>
            {t('marketingTranslations.partners.paragraph2')}
          </p>
        </Container>
      </div>
      <Container className="mt-5">
        <Row className="justify-content-center">
          <p className={styles.cgMarketing__subtitle}>{t('marketingTranslations.organizations')}</p>
        </Row>
        <Row>
          <Col xs={6} md={3}>
            <Image fluid className="w-100" src={MarketingHealthPlans}/>
          </Col>
          <Col xs={6} md={3}>
            <Image fluid className="w-100 mt-5" src={MarketingStatePrograms}/>
          </Col>
          <Col xs={6} md={3}>
            <Image fluid className="w-100" src={MarketingHospitalSystems}/>
          </Col>
          <Col xs={6} md={3}>
            <Image fluid className="w-100" src={MarketingEmployers}/>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4 text-center">
          <p
            className={`${styles.cgMarketing__subtitle} w-75 mr-auto ml-auto text-center`}>{t('marketingTranslations.scheduleDemo')}</p>
        </Row>
        <Row className="justify-content-center mt-4 text-center">
          <HashLink to="#demo"><Button>{t('marketingTranslations.demoButton')}</Button></HashLink>
        </Row>
      </Container>
      <div className={styles.cgMarketing__footer}>
        <div className={styles.cgMarketing__footer__blurred}>
          <Container className={`${styles.cgMarketing__carousel}`}>
            <Row className="justify-content-center mt-4 text-center">
              <p className={`${styles.cgMarketing__subtitle} text-center`}>{t('marketingTranslations.workWith')}</p>
            </Row>
            <CarouselComponent
              className={styles.cgMarketing__footer__carousel}
              itemsDesktop={4}
              itemsDesktopSmall={3}
              itemsMobile={3}
              partialVisibilityGutterMobile={215}
              autoPlaySpeed={5000}
              infinite
              showDots
              arrows={false}
              dotListClass={homeStyles.cgHome__carousel__list}>
              <Image fluid className="w-75" src={AnthemBlueLogo}/>
              <Image fluid className="w-75" src={ElevanceHealthLogo}/>
              <Image fluid className="w-75" src={OhiosHospiceLogo}/>
              <Image fluid className="w-75" src={CaresourceLogo}/>
            </CarouselComponent>
          </Container>
        </div>
        <div className={styles.cgMarketing__footer__form}>
          <Row className="flex flex-md-row-reverse">
            <Col xs={12} md={7}>
              <Card>
                <Card.Title className={styles.cgMarketing__footer__form__title}>
                  <Container>
                    <Row>
                      <Col className="pt-4 text-center">{t('marketingTranslations.demoRequest.heading')}</Col>
                    </Row>
                  </Container>
                </Card.Title>
                <Card.Body>
                  <Form>
                    <Container>
                      <Row>
                        <Col>
                          <Form.Label
                            className={styles.cgMarketing__footer__form__formLabel}>{t('marketingTranslations.demoRequest.name')}</Form.Label>
                          <Form.Control type="text" value={demoRequest.name}
                                        onChange={(e) => onInputChange({name: e.target.value})}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Label
                            className={styles.cgMarketing__footer__form__formLabel}>{t('marketingTranslations.demoRequest.company')}</Form.Label>
                          <Form.Control type="text" value={demoRequest.company}
                                        onChange={(e) => onInputChange({company: e.target.value})}/>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label
                            className={styles.cgMarketing__footer__form__formLabel}>{t('marketingTranslations.demoRequest.title')}</Form.Label>
                          <Form.Control type="text" value={demoRequest.title}
                                        onChange={(e) => onInputChange({title: e.target.value})}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Label
                            className={styles.cgMarketing__footer__form__formLabel}>{t('marketingTranslations.demoRequest.phoneNumber')}</Form.Label>
                          <Form.Control type="text" value={demoRequest.phone}
                                        onChange={(e) => onInputChange({phone: e.target.value})}/>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label
                            className={styles.cgMarketing__footer__form__formLabel}>{t('marketingTranslations.demoRequest.emailAddress')}</Form.Label>
                          <Form.Control type="text" value={demoRequest.email}
                                        onChange={(e) => onInputChange({email: e.target.value})}/>
                        </Col>
                      </Row>
                      <Row id="demo">
                        <Col>
                          <RBButton className={`btn-primary w-100 ${styles.cgMarketing__footer__form__submitButton}`}
                                    onClick={onSubmit}
                                    disabled={disabledSubmit}>{t('marketingTranslations.demoRequest.submit')}</RBButton>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={5} className={styles.cgMarketing__footer__form__contact}>
              <Image fluid className={styles.cgMarketing__footer__form__logo} src={CaregivingWhiteLogo}/>
              <p className="pt-3"><a href="mailto:partners@caregiving.com"
                                     className="text-white">partners@caregiving.com</a><br/>
                Alpha Ohio 45301 <br/>
                United States
              </p>
              <div>
                <a rel="noreferrer" target="_blank" href="https://www.facebook.com/CaregivingSupport/"
                   className="text-white"><CgIcon iconName="BsFacebook" className="mr-2"/></a>
                <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/caregiving"
                   className="text-white"><CgIcon iconName="BsLinkedin" className="mr-2"/></a>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/caregiving"
                   className="text-white"><CgIcon iconName="BsTwitter" className="mr-2"/></a>
                <a rel="noreferrer" target="_blank" href="https://www.instagram.com/caregivingsupport/"
                   className="text-white"><CgIcon iconName="RiInstagramFill" className="mr-2"/></a>
                <a rel="noreferrer" target="_blank" href="https://www.youtube.com/c/Caregiving"
                   className="text-white"><CgIcon iconName="BsYoutube"/></a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default MarketingPage;
