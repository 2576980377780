import React from 'react';
import styles from '@/styles/_legal.module.scss';
import { Pages } from '@/constants/pages.constant';
import {useDataLayer, useTranslation} from '@/hooks';
import { Container } from 'react-bootstrap';
import SignupMailSection from '@/components/organisms/signup-mail-section';

const ReprintPermissionComponent = () => {
  useDataLayer(Pages.REPRINT_PERMISSION);
  const {t} = useTranslation();

  return (
    <>
      <Container className="my-5">
        <div
          className={`${styles.cgLegal__section} justify-content-md-center`}
        >
          <div className={styles.cgLegal__subSection}>
            <h1 className={`${styles.cgLegal__title} pb-5`}>
              {t('atomsTranslations.reprintPermission.title')}
            </h1>
            <p className={`${styles.cgLegal__description} pb-4`}>
              {t('atomsTranslations.reprintPermission.paragraph1')}
            </p>
            <div className={`${styles.cgLegal__description} pb-5`}>
              {t('atomsTranslations.reprintPermission.instructions.title')}
              <br></br>
              <br></br>
              <ol className="mb-5">
                <li>
                  {t('atomsTranslations.reprintPermission.instructions.step1')}
                </li>
                <li>
                  {t('atomsTranslations.reprintPermission.instructions.step2')}
                </li>
              </ol>
              {t('atomsTranslations.reprintPermission.paragraph2')}
              <br></br>
              <br></br>
              {t('atomsTranslations.reprintPermission.paragraph3')}
            </div>
          </div>
        </div>
      </Container>
      <SignupMailSection headingNumber={2} />
    </>
  );
}

export default ReprintPermissionComponent;
