const Index = {
  moleculeTranslations: {
    partnerButton: 'Partner with us',
    partnerBannerHeader: 'Let\'s Work Together!',
    partnerBannerDescLong: 'Join the growing number of Health Plans, Medicaid / Medicare organizations, Retail Health organizations, State / Local Government Agencies, and Employers  partnering with Caregiving.com to engage and empower family caregivers in their organizations.',
    partnerBannerDescShort: 'Join the growing number of organizations partnering with Caregiving.com to engage and empower family caregivers.',
    dosage: 'Dosage',
    frequency: 'Frequency',
    addConditionsSearch: {
      placeholder: 'Search conditions',
      addButton: 'Add another Condition',
      singleAddButton: 'Add'
    },
    addMedicationsSearch: {
      placeholder: 'Search by medication name or NDC',
      addButton: 'Add another Medication',
      singleAddButton: 'Add'
    },
    articleJumbotron: {
      featured: 'Featured',
    },
    seeAll: 'See all',
    sendNow: 'Send now',
    calculatorBanner: {
      paragraph1: 'Calculate your',
      paragraph2: 'caregiver salary',
      paragraph3: 'Invoice your senators for your',
      paragraph4: 'unpaid family caregiving.',
    },
    careBinder: 'CareBinder',
    careBinderBanner: {
      description: 'A downloadable guide to customize your caregiving needs.',
      button: 'Get the free workbook',
    },
    carePromise: {
      title: 'The Caregiving Promise',
      paragraph1:
        'Our promise and commitment to family caregivers, is that your experience on Caregiving.com will be helpful, impactful, and enjoyable. The information you share with us will only be used to improve your experience on the site and we will never share or sell your individual information with any outside business. We want to connect you to the information or resources you need on your terms. You‘ll never get phone calls or emails from business that got your information from us. That‘s not who we are and we know that‘s not want you want. Your experience and your trust are our top priority.',
      paragraph2:
        'Whenever you see this badge, we want it to be a reminder of our promise and commitment to you, the family caregiver.',
    },
    addCareRecipient: 'Add Care Recipient',
    expoBanner: {
      title: 'Senior and Family Caregiver Expo',
      button: 'Join us in Indianapolis',
    },
    rsvpBanner: {
      title: 'Save the Date!',
      description: 'Tune in and join us LIVE on December 13th at 8PM ET',
      button: 'RSVP Today!',
    },
    contributorBanner: {
      title: 'Join our community of creators',
      description:
        'Tell us a little about yourself, and a member of our editorial team will reach out to you. Please note, our form may take a few minutes to complete.',
      button: 'Learn more',
    },
    hwghBanner: {
      title: 'How We Got Here',
      description: 'A podcast about what it means to give and receive care.',
      button: 'Listen now',
    },
    nominateBanner: {
      title: 'Submit a nomination!',
      description:
        'To nominate an individual or organization you know or to nominate yourself, please complete our application. Deadline to submit a nomination is Friday, November 18.',
      button: 'Get started',
    },
    progressCard:
      'Get the most out of your caregiving.com experience by completing these items:',
    completed: 'Completed',
    nfcs: {
      description: '<strong>Missed the National Family Caregiver Summit? We got you.</strong><br>You can still register to access this content from over 30 speakers and experts.',
      register: 'Access today',
      dates: 'Nov 10 & 11',
      stream: 'Stream On Demand',
      stream_live: 'Stream it live!',
      watch_today_friday: 'Watch today at 1 p.m. EST.',
      watch_today_saturday: 'Watch today at 10 a.m. EST.',
      alert: 'Join us for our first National Family Caregiver Summit!',
      sponsored: 'Presented by:'
    },
    safetyChecklist: {
      title: 'Safety Checklist',
      description:
        'Download this assessment tool and evaluate your loved one’s safety at home.',
      button: 'Get the free checklist!',
    },
    selfCareWorkbookBanner: {
      title: 'Self-Care Workbook',
      description: 'Your guide to thriving through meaningful self-care',
      button: 'Read now',
    },
    profilePhoto: {
      addAPhoto: 'Upload photo',
      updatePhoto: 'Update photo',
      removePhoto:'Remove photo',
    },
    inviteMembers: {
      action: {
        addAnotherMember: 'Add Another Member',
        save: 'Save',
        remove: 'Remove',
      },
      email: {
        placeholder: 'Email',
        error: {
          required: 'This field is required',
          invalid: 'Invalid email format',
        }
      },
      name: {
        placeholder: 'Name',
        error: {
          required: 'This field is required',
        }
      },
      saveMember: {
        error: {
          nameEmpty: 'Name cannot be empty.',
          emailEmpty: 'Email cannot be empty.',
          relationshipEmpty: 'Relationship cannot be empty.',
          allFieldsEmpty: 'Fields cannot be empty'
        }
      },
    },
    relationshipPlaceholder: {
      text1: 'This is ',
      text2: "'s..."
    },
    topicChooser: {
      heading: 'Choose Topics',
      description: 'Topics are used to personalize your experience and will be visible on your profile.',
    },
    caringFor: 'Who are you caring for today?',
    noProfile: 'You have not created a care recipient profile.',
    myCareRecipients: 'My Care Recipients',
    followUs: 'FOLLOW US ON',
    collectionBanner: {
      browseCollection: 'Browse the collection',
      readArticle: 'Read Now',
      shelfTitle: 'Collections',
      shelfDescription: 'Discover curated content by topic.',
      otherCollection: 'Other collections',
      hover: 'Go to Collection'
    },
    comments: {
        responesesTo: 'Responses to:',
        noComments: 'No comments yet.',
        addComment: 'Add a comment',
        reply: 'reply',
        cancel: 'Cancel',
        post: 'Post',
        deleteHeader: 'Delete This Comment',
        deleteDescription: 'Deleted comments are gone forever. Are you sure you want to delete?',
        delete: 'Delete',
        update: 'Update',
        flags: {
          item1: 'It’s Off Topic',
          item2: 'It’s Inappropriate',
          item3: 'It’s Spam',
          item4: 'Something Else',
        },
        flagComment: 'Flag This Comment',
        flag: 'Flag',
        flagPlaceholder: 'Let us know specifically what you are concerned about, and provide relevant links and examples where possible.',
        tooltip: 'You can’t like your own comment',
        signIn: {
          header: 'Sign in to get the most out of the caregiving.com experience',
          description: 'By signing into your account you’ll be able to bookmark, like, comment and earn rewards for engaging with the community!',
          button: 'Sign In'
        },
        view: 'View',
        replies: 'replies',
        moreReplies: 'View more replies',
        hide: 'Hide comments'
    },
    signup_modal: {
      heading: 'Don\'t lose your progress!',
      body: 'Join the many family caregivers using Caregiving.com to access resources like these, engage in conversations, and empower each other on their care journeys.',
      button: 'Create my free account',
      existing_account: 'Already have an account?',
      signin: 'Sign in',
    }
  },
};

const MoleculeTranslations = Object.assign(Index)

export default MoleculeTranslations;
