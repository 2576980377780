const Index = {
    organismsTranslations: {
        missingContent: 'Lo sentimos, no pudimos encontrar ese contenido.',
        backHome: 'Regresar al inicio',
        backToResults: 'Regresar a los resultados',
        backToEditing: 'Regresar a editar',
        privacyPolicy: 'Política de privacidad',
        reprintPermission: 'Permiso de reimpresión',
        termsOfUse: 'Condiciones de uso ',
        moreToDiscover: 'Descubre más',
        caregiverResources: 'Recursos para cuido',
        sideMenu:{
            discover: 'Descubrir',
            support: 'Mi apoyo',
            collections: 'Colecciones',
            resources: 'Recursos',
            community: 'Comunidad',
            careStories: 'Historias de cuidadores',
            forum: 'Foro',
            awards: 'Premios',
            newsroom: 'Sala de prensa',
            research: 'Investigar',
            mediaLibrary: 'Mediateca',
            podcasts: 'Podcasts',
            videos: 'Videos',
            about: 'Acerca de',
            team: 'Nuestro equipo',
            contributors: 'Nuestras colaboradoras',
            champions:'Nuestros Campeones',
            partner: 'Conviértete en un compañero',
        },
        mailingList: {
            title: 'Únete a nuestra lista de correos',
            description: 'Regístrese para recibir nuestro boletín que incluye consejos para el cuidado, noticias, apoyo y más.',
            button: 'Regístrate',
            errorMessage: '¡Ups! Parece que ya tenemos su dirección de correo electrónico registrada. Revise su correo electrónico para nuestro boletín semanal para todo lo relacionado con el cuidado. Mientras tanto, echa un vistazo a nuestros artículos de tendencias'
        },
        follow: {
            name: 'Nombre',
            date: 'Fecha',
            type: 'Tipo'
        },
        whyCreateAccount: {
            title: '¿Por qué crear una cuenta?',
            learnMore: 'Aprende más',
            items: {
                item1: { title: 'Contenido adaptado a ti a través de tu perfil de cuidados', description: 'Una vez que completes tu perfil, nuestro sistema de etiquetado de artículos te recomendará contenido que te ayude en donde estés en tu papel de cuidador.' },
                item2: { title: 'Mantén a todos en tu camino de cuidados en la misma página', description: 'Caregiving.com puede ayudarte a mantener a tu círculo de cuidados actualizado. Invita a tu familia u otros cuidadores a la perfil de tu ser querido para que puedan recibir las mismas actualizaciones, educación y apoyo que tú.' },
                item3: { title: 'Últimas actualizaciones, historias, herramientas y recursos', description: 'Siempre estamos agregando nuevos artículos educativos, videos, podcast y herramientas de capacitación a nuestra biblioteca digital. Una vez que se haya creado tu perfil, te serviremos el contenido más reciente en torno a los temas que más te importan.' },
                item4: { title: 'Conectarse con proveedores de seguros, empleadores y sistemas de salud', description: 'Caregiving.com está asociándose con proveedores de seguros, empleadores y sistemas de salud.' }
            }
        },
        relationships: {
            aunt: 'Tía',
            boyfriend: 'Novio',
            brother: 'Hermano',
            brotherInLaw: 'Cuñado',
            careGiver: 'Cuidador',
            cousin: 'Primo',
            daughter: 'Hija',
            daughterInLaw: 'Nuera',
            doctor: 'Doctor',
            father: 'Padre',
            fatherInLaw: 'Suegro',
            fiancé: 'Novio',
            fiancée: 'Novia',
            friend: 'Amigo',
            girlfriend: 'Novia',
            granddaughter: 'Nieta',
            grandfather: 'Abuelo',
            grandmother: 'Abuela',
            grandson: 'Nieto',
            husband: 'Esposo',
            mother: 'Madre',
            motherInLaw: 'Suegra',
            neighbor: 'Vecino',
            nephew: 'Sobrino',
            niece: 'Sobrina',
            sister: 'Hermana',
            sisterInLaw: 'Cuñada',
            son: 'Hijo',
            sonInLaw: 'Yerno',
            stepdaughter: 'Hija adoptiva',
            stepfather: 'Padrastro',
            stepmother: 'Madrastra',
            stepson: 'Hijo adoptivo',
            uncle: 'Tío',
            wife: 'Esposa',
        }
    }
}

const OrganismsTranslations = Object.assign(Index)

export default OrganismsTranslations;