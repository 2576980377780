import React, { useState, useEffect } from 'react';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './dropdown.module.scss';

const Dropdown = ({ placeholder = '', items = [], onChange = () => {}, active, required = false, className, ...props }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (label, value, key) => {
        onChange(label, value, key);
        setSelectedItem({ label, value, key });
        setError('');
    };

    const handleBlur = () => {
        setTouched(true);
        if (required && !selectedItem) {
            setError('This field is required.');
        }
    };

    const mapItems = (itemsToMap) => itemsToMap.map((item, index) => (
        <BsDropdown.Item key={item.value || index} onClick={() => handleChange(item.label, item.value, item.key)} className='p-3'>
            {item.label}
        </BsDropdown.Item>
    ));

    useEffect(() => {
        if (required && touched && !selectedItem) {
            setError('This field is required.');
        } else {
            setError('');
        }
    }, [required, touched, selectedItem]);

    return (
        <div onBlur={handleBlur}>
            <BsDropdown className={className ? className : `${styles.cgDropdown}`} {...props}>
                <BsDropdown.Toggle className={`${styles.cgDropdown__toggle} ${active ? styles.cgDropdown__toggleActive : ''}`}>
                    <span className={styles.cgDropdown__safePlaceholderArea}>{placeholder}</span>
                </BsDropdown.Toggle>
                <BsDropdown.Menu className={styles.cgDropdown__menu}>
                    {mapItems(items)}
                </BsDropdown.Menu>
            </BsDropdown>
            {error && <div className={`${styles.cgDropdown__error} text-danger`}>{error}</div>}
        </div>
    );
};

Dropdown.propTypes = {
    sort: PropTypes.bool,
    placeholder: PropTypes.any,
    items: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    onChange: PropTypes.func,
    active: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
};

export default Dropdown;