export const CareRecipientTranslations = {
    careRecipientTranslations: {
        careSituation: {
            title: 'Tell us about your care situation with ',
            subtitle: 'Tell us about your care situation so that we can provide you with content relevant to your caregiving.'
        },
        name: 'Name',
        completion: 'Completion',
        dateAdded: 'Date added',
        profilePhoto: 'Profile photo',
        fullName: 'Full name',
        first_name: 'First Name',
        last_name: 'Last Name',
        birthdate: 'Birthdate',
        zipcode: 'Zip code',
        zipcodeError: {
            required: 'This field is required',
            invalid: 'Must be alphanumeric',
        },
        livingSituation: 'Which best describes the care recipients living situation?',
        careTopics: 'Do any of the following describe the person you are caring for?',
        insurance: 'Insurance',
        careCategories: 'Categories of Care',
        relationship: 'The care recipient is your:',
        listRelationship: 'Relationship',
        feelings: {
            text1: 'How does caring for ',
            text2: ' make you feel?'
        },
        careTasks: 'Care Tasks',
        challenges: 'Caregiving Challenges',
        challengePlaceholder: 'Optional. In your own words, tell us about the challenges you\'re currently facing as a caregiver to ',
        inviteMembers: 'Invite Members',
        back: 'Back',
        next: 'Next',
        done: 'Done',
        more: 'More',
        error: 'There was an error in processing your request. Please check that you filled out all information properly.',
        example: {
            title: 'Example',
            paragraph1: 'Caregiving for ',
            paragraph2: ' as led to less time for me and other family members. I often spend so much time on caregiving duties that I end up sacrificing the things that I enjoy, like hobbies or vacations. I am having trouble balancing work schedules around caregiving.',
            paragraph3:'Oftentimes, caregiving duties take up so much of my time that I can no longer maintain social connections outside of the home. This lack of time with friends and family has begun to lead to depression and anxiety.'
        },
        photoTitle: 'Care recipient photo',
        insuranceCoverage: 'Insurance coverage',
        careExperience: 'Your Caregiving Experience',
        careConditions: 'Conditions',
        conditionDescription: 'What conditions does your care recipient have?',
        mentalIllness: 'What are some conditions caused by your care recipient’s Mental Illness?',
        topics: 'Topics of focus',
        updateTitle: 'Upload photo from your computer',
        editHandler: {
            title: 'Care recipient photo',
            description: 'Add a photo for your care recipient.'
        },
        conditions: {
            title: 'Conditions',
            addButton: 'Add New',
            addAnotherButton: 'Add another Condition',
            searchText: 'Start typing to search conditions.',
            noConditions: 'No conditions found.',
            subtitle: {
                paragraph1:'Add a new condition to ',
                paragraph2: ' profile'
            },
            back: 'Profile'
        },
        medications: {
            placeHolder: 'Start typing to search medications by name or NDC.',
            backButton: 'Medications',
            title: 'Add new medication',
            noMedications: 'No medications found.',
            subtitle: {
                paragraph1:'Add a new medication to ',
                paragraph2: ' profile'
            },
            header: 'Medications',
            back: 'Profile'
        },
        optional: 'Optional',
        progressBar: {
            step1: 'General Information',
            step2: 'Care Situation',
            step3: 'Choose Topics',
            step4: 'Invite Members',
            step5: 'Final Details'
        },
        pageTitle: 'Who are you caring for?',
        pageDescription: 'Create a care profile so we can provide you with content relevant to your caregiving.',
        pageTip: {
            title: 'Tip:',
            description: 'Fill out as much as you can. You can always add more details in the user preferences later.',
            button: 'Got it'
        },
        careRecipients: 'Care Recipients',
        pendingInvites: 'Pending Invites:',
        relationshipPlaceholder: {
            text1: 'I am ',
            text2:"'s..."
        },
        conditionsPlaceholder: 'E.g. Hypertension',
        medicationsPlaceHolder: 'E.g. Ibuprofen',
        medicationsDosagePlaceHolder: 'Add dosage',
        medicationsFrequencyPlaceHolder: 'Frequency',
        edit: {
            title: 'Profile Basics',
            categoryAtentionPlaceholder: 'These categories help...'
        },
        members: {
            title: 'Members',
            anchor: 'Manage'
        },
        required: 'Please fill out this field!',
        requiredMarker: 'Required field',
        describeCareRecipient: 'Do any of the following describe the person you are caring for?',
        careRecipientConditions: 'What conditions does your care recipient have?',
        careRecipientConditionFollowup: 'What are some conditions caused by your care recipient\'s {{condition}}?'
    }
}
