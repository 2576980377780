import React, {useEffect} from 'react';
import ArticleJumbotron from '@/components/molecules/article-jumbotron';
import OurStoryImg from '@/assets/images/our-story.jpg';
import styles from './our-story.module.scss';
import { useHttpGet } from '@/hooks/use-http';
import {ARTICLE_GROUP} from '@/constants/endpoints.constant';
import {Row} from 'react-bootstrap';
import ArticleCard from '@/components/molecules/article-card';
import {ArticleCardSizes} from '@/constants/article-card-sizes.constant';
import {LayoutObservable} from '@/observables/layout.observable';
import Loading from '@/components/atoms/loading';
const OurStory = () => {
  const {execute: getCompanyNews, data, loading, error} = useHttpGet();

  useEffect(() => {
    LayoutObservable.toggleDisplayNavbar(false);
  }, []);

  useEffect(() => {
      getCompanyNews(ARTICLE_GROUP('company-news'))
  }, [])

  if (loading || !!error) {
    return <Loading/>
  }

  return (
    <div className={styles.cgOurStory}>
      <h1 className={`${styles.cgOurStory__title} d-none d-md-block`}>About</h1>
      <div className={styles.cgOurStory__hero}>
        <ArticleJumbotron
          title='Caregiving.com is your free resource for care information'
          img={OurStoryImg}
          subtitle='Wherever you’re at in your caregiving journey, we’re here to help.'
          color='#6064ec'
        />
      </div>
      <h2 className={`${styles.cgOurStory__title} d-md-none`}>About</h2>
      <p className={styles.cgOurStory__bodyText}>
        As soon as people could use the Internet to search for answers to their caregiving questions, Caregiving.com was
        there. Established in 1996, we were one of the first online communities to offer companionship and comfort to
        family caregivers. Over the years, we grew to feature the blogs of professional and family caregivers, free
        webinars and online support groups, annual in-person gatherings, and more.
        <br/>
        <br/>
        In 2020, Caregiving.com gained new leadership. As part of the Carely, Inc. family, our goal is to further
        improve our offerings to better support your evolving needs. Whether you need guidance on selecting professional
        care services, advice for your latest challenge, or simply words of support from someone who&apos;s been there,
        you&apos;ll find it at Caregiving.com.
      </p>
      {!!data?.articles?.length && <>
        <h2 className={styles.cgOurStory__title}>Company News</h2>
        <Row className='justify-content-center justify-content-md-start'>
          {data.articles.map((article, index) => (
            <div key={index} className={styles.cgOurStory__card}>
              <ArticleCard
                title={article.title}
                bodyText={article.excerpt}
                img={article?.listing_image?.url || article?.listing_image}
                imgAlt={article?.listing_image?.alt}
                size={ArticleCardSizes.MD}
                to={article.url?.url || article.url}
                type={article.type}
                readTime={article.completion_time}/>
            </div>
          ))}
        </Row>
      </>}
    </div>
  )
};

export default OurStory;
