import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap'
import {useLocation} from 'react-router-dom';
import CgIcon from '@/components/atoms/cg-icon';
import styles from './nav-pills-bar.module.scss';
import NavPill from '@/components/atoms/nav-pill';
import StackedNavPills from '@/components/molecules/stacked-nav-pills';
import { useObservable } from '@/hooks';
import { LayoutObservable } from '@/observables/layout.observable';
import { NavPills } from '@/constants/nav-pills.constant';

const SCROLL_DIRECTIONS = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
});

const NavPillsBar = ({sideMenuClosed = false, ...props}) => {
  const ref = useRef(null);
  const [navPillsLinks] = useObservable(LayoutObservable.navPillsLinks$);
  const [isNfcsHeroEnabled] = useObservable(LayoutObservable.nfcsHeroEnabled$)
  const [isNfcsHeroVisible] = useObservable(LayoutObservable.nfcsHeroInView$)
  const [isNfcsBarEnabled] = useObservable(LayoutObservable.nfcsBarEnabled$)
  const [inactiveLibraries, setInactiveLibraries] = useState([]);
  const [activeLibrary, setActiveLibrary] = useState(null);
  const [activeSubLibrary, setActiveSubLibrary] = useState(null);
  const [isNested, setIsNested] = useState(false);
  const [shouldDisplayRightBtn, setShouldDisplayRightBtn] = useState(false);
  const [shouldDisplayLeftBtn, setShouldDisplayLeftBtn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (navPillsLinks && navPillsLinks.length) {
      setInactiveLibraries(navPillsLinks.map((link) => {
        // TODO: Sort out all this href/url spaghetti
        // At some point there was code the added href property, but I'm not seeing it anymore
        // lets make sure href is set when only a url is defined
        if (!link.href && link.url) {
          link.href = link.url;
        }
        if (!!link.subLibraries) {
          link.subLibraries = link.subLibraries.map((subLink) => {
            if (!subLink.href && subLink.url) {
              subLink.href = subLink.url;
            }
            return subLink;
          })
        }
        return link;
      }));
    }
  }, [navPillsLinks]);

  /**
   * This method initializes library and sub library highlighting based on match with path name
   */
  useEffect(() => {
    const active = findActive(inactiveLibraries);
    if (active) {
      swapActiveLibrary(active);
    } else {
      inactiveLibraries.forEach((library) => {
        if (library.subLibraries) {
          const activeSub = findActive(library.subLibraries);
          if (activeSub) {
            setIsNested(true);
            setActiveLibrary(library);
            setActiveSubLibrary(activeSub);
          }
        }
      })
    }
  }, [inactiveLibraries]);

  useEffect(() => {
    setShouldDisplayRightBtn(ref.current.offsetWidth + ref.current.scrollLeft < ref.current.scrollWidth);
  }, [ref.current]);


  const scrollTo = (direction) => {
    if (ref) {
      ref.current.scrollTo({
        left: direction === SCROLL_DIRECTIONS.LEFT ? ref.current.scrollLeft - 100 : ref.current.scrollLeft + 100,
        behavior: 'smooth'
      })
    }
  }

  const onScroll = function (e) {
    const displayRightButton = e.currentTarget.offsetWidth + e.currentTarget.scrollLeft < e.currentTarget.scrollWidth;
    const displayLeftButton = e.currentTarget.scrollLeft > 0;
    setShouldDisplayRightBtn(displayRightButton);
    setShouldDisplayLeftBtn(displayLeftButton);
  }

  const clearActiveLibrary = () => {
    if (activeLibrary) {
      setIsNested(false);
      setActiveLibrary(null);
      clearActiveSubLibrary();
    }
  };

  const clearActiveSubLibrary = () => {
    setActiveSubLibrary(null);
  };

  const swapActiveLibrary = (library) => {
    clearActiveLibrary();
    setActiveLibrary(library);
    setIsNested(library?.subLibraries?.length > 0);
  }

  const isActive = (data) => {
    if (data.type === NavPills.TYPE_SEPARATOR) {
      return false;
    }
    if (location.hash !== '') {
      return data.href === location.pathname + location.hash;
    }
    if (data.href === location.pathname) {
      return true;
    }
    // check after removing querystring
    return data.href.substring(0, data.href.indexOf('?')) === location.pathname;
  }

  const findActive = (list) => {
    for (const item of list) {
      if (isActive(item)) {
        return item;
      }
    }
    return null
  }

  const renderPill = (library, i) => {
    if (library.type && library.type === NavPills.TYPE_SEPARATOR) {
      return (<span key={i} className={`${styles.cgNavPillsBar__separator}`}></span>)
    }
    return (<NavPill
        key={i}
        to={library.href}
        active={isActive(library)}
        onClick={() => {
          swapActiveLibrary(library)
        }}
    >
      {library.label}
    </NavPill>);
  }

  const renderNodePills = (library, i) =>
      <NavPill
          key={i}
          to={library.href}
          onClick={() => {
            setActiveSubLibrary(library)
          }}
      >
        {library.label}
      </NavPill>;

  const renderPills = (list) => {
    let pillList = [];

    list.forEach((item, i) => {
      // if there is an active library and not a static item, skip non active default types
      if (activeLibrary && activeLibrary.type !== NavPills.TYPE_STATIC) {
        if (item.type === NavPills.TYPE_DEFAULT && item.href !== activeLibrary.href) {
          return;
        }
      }
      if (activeLibrary?.href === item.href && activeSubLibrary) {
        pillList.push(<StackedNavPills key={i}
            parent={<NavPill to={activeLibrary.href} onClick={clearActiveSubLibrary}>{activeLibrary.label}</NavPill>}
            child={<NavPill to={activeSubLibrary.href}>{activeSubLibrary.label}</NavPill>}/>);
      } else {
        pillList.push(renderPill(item, i));
      }
    })
    if (isNested && activeLibrary.subLibraries) {
      pillList.push(<span key="sep" className={`${styles.cgNavPillsBar__separator}`}></span>);
      activeLibrary.subLibraries.forEach((item, i) => {
        if (activeSubLibrary && !!activeSubLibrary.href && activeSubLibrary.href === item.href) {
          return; // skip the active item
        }
        pillList.push(renderNodePills(item, 'sub' + i));
      })
    }
    return pillList;
  }

  const displayRightButton = shouldDisplayRightBtn || ref && ref.current && (ref.current.offsetWidth + ref.current.scrollLeft < ref.current.scrollWidth);

  return (
    <div className={`${styles.cgNavPillsBar} ${sideMenuClosed ? styles.cgNavPillsBar__sideMenuClosed : '' } ${isNfcsHeroEnabled ? (isNfcsHeroVisible ? styles.cgNavPillsBar__withNfcsHero : styles.cgNavPillsBar__withoutNfcsHero) : ''} ${isNfcsBarEnabled ? styles.cgNavPillsBar__withNfcsBar : ''}`}>
      {shouldDisplayLeftBtn &&
      <Button
        className={`${styles.cgNavPillsBar__button} ${styles.cgNavPillsBar__prev} d-none d-md-block`}
        variant="light"
        onClick={() => scrollTo(SCROLL_DIRECTIONS.LEFT)} >
        <CgIcon iconName="MdKeyboardArrowLeft" />
      </Button>
      }
      <div ref={ref} {...props} onScroll={onScroll}
           className={`${styles.cgNavPillsBar__container} d-flex flex-nowrap overflow-auto h-100 align-items-center`}>
        {renderPills(inactiveLibraries)}
      </div>
      {displayRightButton &&
      <Button
        className={`${styles.cgNavPillsBar__button} ${styles.cgNavPillsBar__full__next} ${styles.cgNavPillsBar__next} d-none d-md-block`}
        variant="light"
        onClick={() => scrollTo(SCROLL_DIRECTIONS.RIGHT)}>
        <CgIcon iconName="MdKeyboardArrowRight" />
      </Button>
      }
    </div>
  )
}

NavPillsBar.propTypes = {
  sideMenuClosed: PropTypes.bool,
}

export default NavPillsBar;
