import React, {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {Row, Col} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useLocation} from 'react-router-dom';
import {useHttpGet} from '@/hooks/use-http';
import {useNavPills} from '@/hooks/use-nav-pills.hook';
import {ArticleCardSizes} from '@/constants/article-card-sizes.constant';
import {SECTION} from '@/constants/endpoints.constant';
import {LayoutObservable} from '@/observables/layout.observable';
import styles from '../videos.module.scss';
import ImageDefault from '@/assets/images/media-placeholder@2x.png';
import VideosList from '../videos-list';
import ArticleCard from '@/components/molecules/article-card';
import ArticleBanner from '@/components/molecules/article-banner';

const VideoProfileComponent = () => {
  const {pathname} = useLocation();
  const {execute: getVideoSeries, data, loading, error} = useHttpGet();
  const [navPills, setNavPills] = useState([]);
  const hasMore = data?.pagination?.page < data?.pagination?.total_pages;

  useNavPills('/videos', navPills);

  useEffect(() => {
    LayoutObservable.toggleDisplayNavbar(false);
    getVideoSeries(SECTION(pathname))
  }, [pathname]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const currentPill = {
      label: data.label,
      url: data.url?.url,
    }

    if (data.parent) {
      let siblingPills = []
      if (data.siblings) {
        data.siblings.forEach((item) => {
          siblingPills.push({
            label: item.label,
            url: item.url?.url,
          });
        });
      }
      setNavPills([{
        label: data.parent.label,
        url: data.parent.url.url,
        subLibraries: [currentPill, ...siblingPills]
      }]);
    } else {
      // otherwise it's top level, set children if any
      currentPill.subLibraries = [];
      data?.children?.forEach((item) => {
        currentPill.subLibraries.push({
          label: item.label,
          url: item.url?.url,
        });
      });
      setNavPills([currentPill]);
    }
  }, [data]);


  const nextPage = () => {
    getVideoSeries(SECTION(pathname) + `?page=${data?.pagination?.page + 1}`)
  }

  const displayLoading = () => {
    if (loading || !!error) {
      return (
        <Col>
          <Col>
            <Skeleton width={150}/>
            <Skeleton height={250} width={'100%'}/>
          </Col>
          <br/>
          <Row>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <br/>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
            <Col md={3}>
              <Skeleton height={150}/>
              <Skeleton count={3}/>
            </Col>
          </Row>
        </Col>
      )
    }
  }

  const displayHeader = () => {
    if (!data) {
      return null;
    }

    if (!data.settings.has_banner) {
      return <h1 className={styles.cgVideo__contentHeader}>{data?.label}</h1>
    }

    return (
      <div className={styles.cgVideo__header}>
        <ArticleBanner
          title={data?.label || ''}
          img={data?.settings?.banner || ''}
          subtitle={data?.settings.banner_description || ''}
          color={data?.settings?.color || '#000000'}
          EmbeddedButton={() => <></>}
        />
      </div>
    )
  }

  const displayChildSections = () => {
    if (!data) {
      return null;
    }
    return (
      data?.children?.map((section, index) => {
        return (<VideosList key={index} section={section} className={styles.cgVideo__videoCardShelf}/>)
      })
    );
  }

  const displayContent = () => {
    if (!data) {
      return null;
    }
    return (
      <InfiniteScroll
        dataLength={data?.content?.length || 0}
        next={nextPage}
        loader={<div className="w-100 text-center">
          <div className="paginated-loader lds-dual-ring"></div>
        </div>}
        hasMore={hasMore}
        className={styles.cgVideo__list}
      >
        {data?.content?.map((item, index) => {
            let author = {};
            if (item.authors?.length) {
              author = item.authors[0];
            }
            return (
              <div className={styles.cgVideo__subSection} key={index}>
                <ArticleCard
                  key={index}
                  title={item?.title}
                  authorCount={item?.authors?.length}
                  author={author?.title || ''}
                  authorImg={author?.avatar?.location || ''}
                  bodyText={item?.excerpt}
                  img={item?.listing_image?.url}
                  imgAlt={item?.listing_image?.alt}
                  link={item?.url?.url || item?.url || '#'}
                  imageDefault={ImageDefault}
                  size={window.innerWidth < 400 ? ArticleCardSizes.MD : ArticleCardSizes.SM}
                  type={item.type}
                  readTime={item.completion_time}
                  sectionRef={item?.section_uuid || ''}
                />
              </div>
            )
          }
        )}
      </InfiniteScroll>
    )
  }

  return (
    <div className={styles.cgVideo}>
      {displayLoading()}
      {displayHeader()}
      {displayChildSections()}
      {displayContent()}
    </div>
  );
}

export default VideoProfileComponent;
