const Index = {
    constantsTranslations: {
        pleaseSelect: 'Por favor seleccione todas las respuestas válidas',
        pleaseSelectAlt: 'Por favor seleccione',
        designer: 'Diseñadora',
        developer: 'Desarrolladora',
        ceo: 'Directora ejecutiva',
        cto: 'Directora de tecnología',
        eventManager: 'Administrador de evento',
        headOfDesign: 'Jefa de diseño',
        comGrowth: 'Director de Crecimiento Comunitario',
        editorial: 'Directora de editorial',
        engineer: 'Ingeniera de experiencia del cliente',
        engagement: 'Jefe de Participación Comunitaria',
        scrumMaster: 'Maestro Scrum',
        seniorDeveloper: 'Desarrolladora Senior de Plataformas',
        production: 'Jefa de producción de video',
        asocEditor: 'Editora asociada',
        asocComGrowth: 'Coordinadora del Programa de Crecimiento Comunitario',
        caregiverSolutionsDirector: 'Directora de Soluciones de Cuido',
        insurances: {
            item1: 'Plan de doble elegibilidad',
            item2: 'Plan basado en el empleador',
            item3: 'Seguro de atención a largo plazo (LTCI)',
            item4: 'Medicaid',
            item5: 'Medicare',
            item6: 'Ventaja de Medicare',
            item7: 'Seguro Privado de Salud',
            item8: 'TriCare',
            item9: 'Sin seguro',
            item10: 'Administración de Veteranos (VA)'
        },
        roles: {
            name: {
                owner: 'Dueño',
                admin: 'Administrador',
                viewer: 'Espectador'
            },
            description: {
                owner: 'Posee y administra este destinatario de cuidados.',
                admin: 'Administra este destinatario de la atención.',
                viewer: 'Puede ver a este destinatario de cuidados.'
            }
        }
    }
}

const ConstantsTranslations = Object.assign(Index)

export default ConstantsTranslations;
