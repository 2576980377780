export const PodcastsTranslations = {
    podcastsPageTranslations:{
        recommended: 'Recomendado',
        title: 'Podcasts',
        sponsoredBy: 'Patrocinado por',
        hosts: 'Anfitriones',
        sendStory: {
            heading: 'El mundo necesita escuchar tu historia de cuidado.',
            body: 'Estamos presentando la historia de un cuidador familiar diferente en cada episodio en un segmento llamado Cómo llegaste aquí. Celebrar. para relacionarse Y hacer que se escuchen los 53 millones de historias de cuidado en los EE. UU. Para tener la oportunidad de que se presente su historia, cree un video o una grabación de voz que nos diga su nombre, a quién cuida, cómo llegó a ser un cuidador y qué significa para usted cuidar a su ser querido. Envíenos su presentación usando el botón de abajo.',
            send: 'Envíe su historia',
        },
        displayTemporaryHardcodedRealTalkSection: {
            heading: 'Sobre nuestros campeones',
            body1: 'Los campeones de Caregiving.com son cuidadores familiares actuales o anteriores con sede en EE. UU. y Canadá que se dedican a la misión de ayudar a los cuidadores familiares a sentirse vistos, escuchados y valorados.',
            body2: 'A través de su trabajo con Caregiving.com, estas personas de la vida real están logrando un cambio positivo para los cuidadores familiares en sus áreas locales al crear redes de apoyo, ayudar a los cuidadores a redefinir lo normal a lo largo de su trayectoria de cuidado, brindarles una voz, involucrar y empoderar a la comunidad local. cuidar a los cuidadores y compartir sus propias historias individuales.',
            body3: 'Los Campeones comparten desafíos de cuidado similares a los 53 millones de cuidadores familiares no remunerados en todo Estados Unidos. Nuestra misión es impactar las vidas de tantos cuidadores como sea posible y ayudarlos a no sentirse tan aislados y solos.',
        },
    }
}
