import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import {withErrorBoundary} from 'react-error-boundary';
import Skeleton from 'react-loading-skeleton';
import styles from './form-control.module.scss';

const TriggerOnChange = 'onChange';
const TriggerOnBlur = 'onBlur';

const FormControlComponent = function FormControlComponent({
  multiline,
  validClass = '',
  name,
  value,
  label,
  labelClass,
  validators = [],
  onChange,
  onValidate,
  className,
  inputClass,
  validateError,
  showErrorOnSubmit = false,
  ...rest
}) {
  const [error, setError] = useState(undefined);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [componentId] = useState(Symbol(name));
  const ref = useRef(null);

  useEffect(() => {
    validateInput(value || '', true); // let initial validation run without debounce
  }, []);

  useEffect(() => {
    validateInput(value || '', false, TriggerOnChange);
  }, [value]);

  useEffect(() => {
    if (validateError?.errorMsg) {
      setError(validateError);
    }
  }, [validateError]);

  useEffect(() => {
    if (showErrorOnSubmit) {
      setShouldDisplayError(true);
    }
  }, [showErrorOnSubmit]);

  const validateInput = async (value, isFirstValidate = false, trigger = 'all') => {
    let validationError = null;

    // Sequentially check each validator
    for (const validatorObj of validators) {
      switch (trigger) {
        case TriggerOnChange:
          if (validatorObj.onChange === false) {
            continue;
          }
          break;
        case TriggerOnBlur:
          if (validatorObj.onBlur === false) {
            continue;
          }
          break;
      }

      // handle synchronous and asynchronous validators by wrapping them in a Promise chain
      // eslint-disable-next-line no-undef
      const validationResult = await Promise.resolve().then(() => validatorObj.validator(value) ).then((result) => {
        // allow async functions to abort validation with (Promise.resolve(undefined))
        if (result === undefined) {
          return true;
        }
        return result;
      });
      if (!validationResult) {
        validationError = validatorObj;
        break; // Stop on the first validation error
      }
    }

    if (error !== validationError) {
      setError(validationError);
      onValidate && onValidate(componentId, validationError, isFirstValidate);
    }
  };

  const onInputChange = (event) => {
    onChange && onChange(event);
    !shouldDisplayError && setShouldDisplayError(true);
  }

  const onBlur = () => {
    !shouldDisplayError && setShouldDisplayError(true);
    validateInput(ref.current.value, false, TriggerOnBlur)
  }

  return (
    <Form.Group className={`text-left ${className || ''}`}>
      {label && <Form.Label className={`${labelClass || ''}`}>{label}</Form.Label>}
      <Form.Control as={multiline ? 'textarea' : undefined} ref={ref} name={name} value={value} className={`${styles.cgFormControl} ${inputClass} ${!!error && shouldDisplayError ? 'is-invalid' : (!error && validClass)}`} onBlur={onBlur} onChange={onInputChange} {...rest} />
      {shouldDisplayError && error?.errorMsg &&
        <Form.Text className='text-danger'>
          {error.errorMsg}
        </Form.Text>
      }
    </Form.Group>
  );
}

FormControlComponent.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onValidate: PropTypes.func,
  validClass: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.shape({
    validator: PropTypes.func,
    errorMsg: PropTypes.string,
  })),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputClass: PropTypes.string,
  validateError: PropTypes.any,
  showErrorOnSubmit: PropTypes.any,
}

const onErrorFallbackComponent = () =>
  <div>
    <Skeleton width={40} className='mb-1'/>
    <Skeleton height={50} className='mb-2'/>
  </div>

export default withErrorBoundary(FormControlComponent, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
});
