import { BehaviorSubject } from 'rxjs';

const partnerSubject = new BehaviorSubject([]);

export const PartnerObservable = Object.freeze({
  setPartners: (partnerList) => {
    partnerSubject.next(partnerList);
  },
  addPartner: (partner) => {
    const partners = partnerSubject.value || [];
    partnerSubject.next([...partners, partner]);
  },
  removePartner: (partnerSlug) => {
    const partners = partnerSubject.value || [];
    const newPartners = partners.filter((partner) => partner.slug !== partnerSlug);
    partnerSubject.next(newPartners);
  },
  hasPartner: (partnerSlug) => {
    const partners = partnerSubject.value || [];
    return partners.some((partner) => partner.slug === partnerSlug);
  },
  partners$: partnerSubject.asObservable(),
});
