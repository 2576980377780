export const AwardPageTranslations = {
    awardPageTranslations: {
        2020: {
            navPills:{
                label: 'Resumen de premios 2020'
            },
        },
        2021: {
            navPills:{
                label: 'Resumen de premios 2021'
            },
        },
        2022: {
            navPills:{
                label: 'Resumen de premios 2022'
            },
        },
        bannerSection: {
            title: 'Vea los premios Caregiving Visionary Awards 2023',
            subtitle: 'Presentado por Mike Eidsaune',
            buttonLabel: 'Empezar a mirar'
        },
        visionaryAwardsSection: {
            title: 'Los premios anuales de cuidado visionario',
            sponsor:'Patrocinado por:',
            body: 'Los premios Caregiving Visionary Awards honran a las personas y organizaciones que actúan como faros de esperanza para los cuidadores familiares y ex cuidadores familiares cada año. Hemos reconocido a muchos miembros de nuestra audiencia de cuidadores en los últimos años, y esperamos compartir aún más historias y experiencias que puedan ayudarlo en su viaje hacia el futuro.',
        },
        quoteSection: {
            title: 'CEO, Caregiving.com',
            name:'Mike Eidsaune',
            body: 'Todos los años llevamos a cabo este evento para reconocer a los cuidadores familiares donde están, donde están haciendo un trabajo increíble en nuestras comunidades, brindando atención, no se trata de honrar a las celebridades. Se trata de honrar a los cuidadores familiares que están ahí todos los días, cuidando desinteresadamente a sus seres queridos.',
        },
        recognitionSection: {
            title: 'La importancia del reconocimiento',
            body: {
                paragraph1:'Sabemos muy bien que los cuidadores a menudo trabajan en la sombra, a menudo sin reconocimiento, pago o apoyo por el trabajo que realizan. Nuestros premios Caregiving Visionary Awards brindan la oportunidad de resaltar la increíble dedicación y el servicio desinteresado de los cuidadores y los socios comunitarios.',
                paragraph2:'Caregiving.com está ansioso por destacar a los cuidadores, entrenadores de cuidadores, líderes comunitarios y organizaciones actuales y anteriores que están marcando una diferencia y tienen un impacto positivo.',
                paragraph3:'Los ganadores de los premios reciben un reconocimiento especial en nuestra entrega anual de premios, así como cobertura durante todo el año en nuestras plataformas para reconocer a nuestros cuidadores y socios destacados.'
            },
        },
        inspirationSection:{
            title:'Inspírate',
            body:'Eche un vistazo a los nominados y ganadores de los premios Caregiving Visionary Awards de los últimos años:',
            recap2020Title: 'Resumen de premios 2020',
            recap2021Title: 'Resumen de premios 2021',
            recap2022Title: 'Resumen de premios 2022'
        },
        awardsCategorySection:{
            title:'Categorías de premios de Caregiving.com',
            categoryCaregiver:{
                title:'Cuidador visionario',
                body:'Este premio reconocerá a una persona que haya desarrollado e implementado una forma innovadora de apoyar a los cuidadores familiares o ex cuidadores familiares.'
            },
            categoryAdvocate:{
                title:'Abogada cuidadora',
                body:'Como cuidador y defensor, esta persona será honrada por brindar apoyo a los cuidadores familiares o ex cuidadores familiares de una manera que hace que la experiencia de cuidado sea mejor y más fácil.'
            },
            categoryYoungCaregiver:{
                title:'Adulto joven o cuidador de jóvenes',
                body:'Un número creciente de jóvenes está asumiendo responsabilidades de cuidado. Este premio reconocerá a un joven nominado que se preocupa por un ser querido o brinda apoyo a otras personas que se preocupan.'
            },
            categoryFamilyCaregiver:{
                title:'Cuidadora familiar del año',
                body:'En honor a una persona que cuida a un familiar o amigo, este premio destacará a un cuidador que complemente el cuidado con sus propias necesidades e intereses al mismo tiempo que inspira a otros.'
            },
            categoryOrganization:{
                title:'Organización de cuidado del año',
                body:'Este premio destacará a una organización que predica con el ejemplo e inspira a otros proveedores de servicios a marcar una diferencia en las vidas de los cuidadores y sus seres queridos.'
            },
            categoryTandySpirit:{
                title:'Premio Espíritu Tandy',
                body:{
                    text: 'Tandy Elisala demostró un verdadero compromiso de servicio en su trabajo. Ella creía en la importancia de apoyar a los demás y quería que las personas tuvieran éxito y logros. En su memoria y para honrarla, este premio reconocerá a una persona con un espíritu afín y compromiso para empoderar a otros. Aprender más acerca de ',
                    link: 'Tandy Elisala'
                }
            },
        },
        sponsorSection:{
            title: 'Gracias a nuestros patrocinadores',
            body:{
                paragraph1:{
                    text1:'Estamos agradecidos por nuestro patrocinador principal, ',
                    link:'Elevación de Salud',
                    text2:', que comparte nuestra misión: proporcionar valiosos recursos locales y hacer que la vida de los cuidadores sea un poco más fácil.'
                },
                paragraph2:'Caregiving.com se enorgullece de ser el vínculo entre los cuidadores de la vida real y las organizaciones comunitarias que están dispuestas a hacer un esfuerzo adicional para apoyar a las familias afectadas por una variedad de enfermedades.',
                paragraph3:{
                    text1: 'Si usted es un recurso comunitario que busca involucrarse y mejorar la vida de los cuidadores en todas partes, consulte la página de recursos para cuidadores familiares donde puede ',
                    link: 'enumera tu empresa ',
                    text2:'como recurso para cuidadores en su área local.'
                }
            }
        }
    }
}