const Index = {
    constantsTranslations: {
        pleaseSelect: 'Please select all that apply',
        pleaseSelectAlt: 'Please select',
        designer: 'Designer',
        developer: 'Developer',
        ceo: 'Chief Executive Officer',
        cto: 'Chief Technology Officer',
        eventManager: 'Event Manager',
        headOfDesign: 'Head of Design',
        comGrowth: 'Director of Community Growth',
        editorial: 'Director of Editorial',
        engineer: 'Customer Experience Engineer',
        engagement: 'Head of Community Engagement',
        scrumMaster: 'Scrum Master',
        seniorDeveloper: 'Senior Platform Developer',
        production: 'Head of Video Production',
        asocEditor: 'Associate Editor',
        asocComGrowth: 'Community Growth Program Coordinator',
        caregiverSolutionsDirector: 'Caregiver Solutions Director',
        insurances: {
            item1: 'Dual Eligible Plan',
            item2: 'Employer Based Plan',
            item3: 'Long Term Care Insurance (LTCI)',
            item4: 'Medicaid',
            item5: 'Medicare',
            item6: 'Medicare Advantage',
            item7: 'Private Health Insurance',
            item8: 'TriCare',
            item9: 'Uninsured',
            item10: 'Veteran Administration (VA)'
        },
        roles: {
                item1: 'Owner',
                item2: 'Admin',
                item3: 'Viewer'
            },
        description: {
            owner: 'Owns and manages this care recipient.',
            admin: 'Manages this care recipient.',
            viewer: 'Can view this care recipient.'
        }
    }
}

const ConstantsTranslations = Object.assign(Index)

export default ConstantsTranslations;
