let isLocalStorageSupported = true
const testKey = 'ls_test';

try {
  if (window === undefined || window.localStorage === undefined) {
    isLocalStorageSupported = false
  } else {
    window.localStorage.setItem(testKey, 'test');
    window.localStorage.removeItem(testKey);
  }
} catch (e) {
  isLocalStorageSupported = false;
}

const inMemoryStorage = {};

const LocalStorage = {
  getItem: (key) => {
    if (isLocalStorageSupported) {
      return window.localStorage.getItem(key);
    }
    return inMemoryStorage[key] || null;
  },

  setItem: (key, value) => {
    if (isLocalStorageSupported) {
      return window.localStorage.setItem(key, value);
    }
    inMemoryStorage[key] = value;
  },

  removeItem: (key) => {
    if (isLocalStorageSupported) {
      return window.localStorage.removeItem(key);
    }
    delete inMemoryStorage[key];
  },
};

export default LocalStorage;
