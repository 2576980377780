import {useEffect, useState} from 'react';
import {LayoutObservable} from '@/observables/layout.observable';
import {NavPills} from '@/constants/nav-pills.constant';
import {LanguageHelper} from '@/languages/helper';

const mapLibrariesToPillsLinks = libraries => libraries.map(({label, url, type, subLibraries}) => ({
    label,
    href: url,
    type: !!type ? type : NavPills.TYPE_DEFAULT,
    subLibraries
})).sort((la, lb) => la.order - lb.order);

export const useNavPills = (baseUrl, links, enableDefault = true, addAll = true) => {
    const [libraries, setLibraries] = useState([]);

    useEffect(() => {
        LayoutObservable.toggleDisplayNavPills(true);

        return () => {
            LayoutObservable.toggleDisplayNavPills(false);
            LayoutObservable.setNavPillsLinks([]);
        }
    }, []);

    useEffect(() => {
        if (libraries?.length > 0) {
            LayoutObservable.setNavPillsLinks(mapLibrariesToPillsLinks(libraries));
        }
    }, [libraries]);

    useEffect(() => {
        if (baseUrl && links && (links.length || !enableDefault)) {
            if (addAll) {
                setLibraries([{
                    label: LanguageHelper('hooksTranslations.addAll'),
                    url: baseUrl,
                    type: NavPills.TYPE_STATIC
                }, ...links])
            } else {
                setLibraries(links)
            }

        }
    }, [links]);
}