import React from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import PropTypes from 'prop-types';
import Mapping from './mapping';

const CgIcon = ({iconName, ...props}) => {
  const ElementIcon = Mapping[iconName];
  if (!ElementIcon) throw new Error(`Icon ${iconName} not found, make sure it is in cg-icon/mapping.jsx`);
  return <ElementIcon {...props} />;
};

CgIcon.propTypes = {
  iconName: PropTypes.string.isRequired
}

const onErrorFallbackComponent = () => <div>x</div>

export default withErrorBoundary(CgIcon, {
  FallbackComponent: onErrorFallbackComponent, onError: console.debug,
});
