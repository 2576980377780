export const MembersTranslations = {
    membersTranslations: {
        inviteSent: 'La invitación ha sido enviada.',
        addMember: 'Añadir miembro',
        addNewMember: 'Agregar nuevas miembros',
        pageTitle: 'Miembros',
        name: 'Nombre',
        role: 'Rol',
        relationship: 'Relación',
        activity: 'Actividad',
        dateAdded: 'Fecha Agregada',
        inviteDescription: 'Invitar a nuevas miembros a ',
        profile: 'perfil',
        active: 'Activo',
        removed: 'Eliminado',
        invited: 'Invitado',
        dismissed: 'Rechazado',
        missingData: 'No encontrado',
        roles: {
            name: {
                owner: 'Dueño',
                admin: 'Administrador',
                viewer: 'Espectador'
            },
            description: {
                owner: 'Posee y administra este destinatario de cuidados.',
                admin: 'Administra este destinatario de la atención.',
                viewer: 'Puede ver a este destinatario de cuidados.'
            }
        }
    }
}