export const OneLinkTranslations = {
    oneLinkTranslations: {
        welcome: 'BIENVENIDOS!',
        title: 'Empecemos',
        description: {
            paragraph1: 'Nos hemos asociado con ',
            paragraph2: ' para asegurarse de tener acceso a la última educación, capacitación y recursos para cuidadores familiares. Active su cuenta hoy para recibir una experiencia personalizada de soporte para cuidadores al alcance de su mano.',
        },
        button : '¡Vamos!',
        footer: 'Durante más de 25 años, Caregiving.com ha sido la principal comunidad en línea para cuidadores familiares. Los ayudamos a encontrar las respuestas a sus desafíos más urgentes y los conectamos con otras personas que entienden por lo que están pasando.'
    }
}