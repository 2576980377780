import React, {cloneElement, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './stacked-nav-pills.module.scss';

const StackedNavPills = ({parent, child, ...props}) => {
  const parentClone = cloneElement(parent, {active: true});
  const childClone = cloneElement(child, {active: true, style: {boxShadow: '0px 0px 0px 2px #FFFFFF'}});
  const parentRef = useRef(null);
  const childRef = useRef(null);

  return (
    <div className={styles.cgStackedNavPills} {...props}>
      <div ref={parentRef} className={styles.cgStackedNavPills__parent}>{parentClone}</div>
      <div ref={childRef} className={styles.cgStackedNavPills__child}>{childClone}</div>
    </div>
  )
};

StackedNavPills.propTypes = {
  parent: PropTypes.element,
  child: PropTypes.element,
};

export default StackedNavPills;
