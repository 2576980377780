import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/atoms/button/index';
import CgIcon from '@/components/atoms/cg-icon';
import styles from './partner-button.module.scss';
import {useTranslation} from '@/hooks';

export const BUTTON_VARIANT = {
  primary: 'primary',
  link: 'link'
};

// $color-carely-blue: #4e61ed;
const PartnerBoltIcon = () => {
  return <CgIcon iconName="HiSolidBolt" className={styles.cgPartnerButton__icon}/>
}
const PartnerButton = ({className}) => {
  const {t} = useTranslation();
  return <Button as='a' href="https://partner.caregiving.com/" target="_blank" className={`${styles.cgPartnerButton} ${className}`} FaIcon={PartnerBoltIcon} backgroundColor={null}>
    <span className={styles.cgPartnerButton__text}>{t('moleculeTranslations.partnerButton')}</span>
  </Button>
};

PartnerButton.propTypes = {
  className: PropTypes.string,
};

export default PartnerButton;
