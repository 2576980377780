export const SupportTranslations = {
    supportTranslations: {
        latest: 'Here is your latest in caregiving today.',
        welcomeBack: 'Welcome back',
        completeAccountBanner: {
            title: 'Complete your account to access support.',
            subtitle: 'To unlock the benefits of Caregiving.com support, you’ll need to complete your account.',
            button: 'Complete my account',
            watch: 'Watch Video',
        },
        noAccountBanner: {
            title: 'Looks like you need an account.',
            subtitle: 'Sign-up or login to access great content and resources to help support you on your caregiving journey.',
            button: 'Create an account',
            watch: 'Watch Video',
        },
        informationSection: {
            title: 'Every caregiving experience is unique',
            paragraph1: 'We know the caregiving path you will take has a lot of twists and turns that no one can anticipate. Our goal is to develop and deliver the resources, tools, and support you will need to provide the best care for your loved one while balancing your life.',
            paragraph2: 'Start your profile on Caregiving.com by selecting the information that best suits your needs at this point in time. You can update this information as your journey continues. And we will continue to deliver helpful tools and information when you need it most.'
        },
        categories: {
            item1: {
                title: 'Curated content delivered to you',
                description: 'Once you complete your profile, our article-tagging system will recommend content that helps you where you are in your caregiving.'
            },
            item2: {
                title: 'Keep everyone in your care journey on the same page',
                description: 'Caregiving.com can help you keep your circle of care updated. Invite your family or other caregivers to your loved one’s profile so they can benefit from the same updates, education, and support as you do.'
            },
            item3: {
                title: 'Refreshed content when and where you need it',
                description: 'We are always adding new educational articles, videos, podcasts, and training tools to our digital library. Once your profile is created, we will serve you the latest content surrounding topics that matter to you most.'
            },
            item4: {
                title: 'Connect to your benefits provider',
                description: 'Caregiving.com is partnering with insurance providers, employers, and health systems.'
            },
        },
        partnerBanner: {
            title: 'Interested in becoming a support partner?',
            button: 'Contact us'
        },
        unAuth : {
            list: {
                body: 'Get the most out of caregiving.com by creating a care profile for your loved one.',
                button: 'Add a Care Recipient'
            },
            title: 'Welcome!',
            button: 'Add a Care Recipient'
        },
        noContent: 'No content available for this care recipient.',
        noCareRecipientBanner: {
            title: 'Unlock everything caregiving.com has to offer.',
            subtitle: 'Create a care recipient profile to get curated content relevant to your unique care situation.'
        }
    }
}
