export const PartnerTranslations = {
    partnerTranslations:{
        submitted: '¡Gracias!',
        submitDescription: '¡Su propuesta ha sido recibida!',
        error: '¡Ups! Algo salió mal al enviar el formulario.',
        headingTitle: 'Desbloquee el poder de la asociación',
        paragraph1: '¿Está listo para revolucionar la forma en que apoya a los cuidadores en su comunidad? ¡No busque más! Caregiving.com se complace en extender una invitación exclusiva a las Agencias del Área sobre el Envejecimiento para unir fuerzas con nosotros en nuestra misión de transformar el panorama del cuidado.',
        subtitle: '¿Por qué asociarse con Caregiving.com?',
        serviceRow1 :{
            title: 'Amplifica tu impacto:',
            paragraph: 'Al unir fuerzas, podemos combinar nuestra experiencia y recursos para mejorar exponencialmente los servicios y el apoyo que ofrecemos a los cuidadores. Juntos, podemos empoderar a los cuidadores y mejorar las vidas de aquellos a quienes cuidan.'
        },
        serviceRow2 :{
            title: 'Acceda a soluciones innovadoras:',
            paragraph: '¡Diga adiós a los enfoques obsoletos! Nuestras herramientas y recursos de vanguardia empoderan a los cuidadores, ofreciéndoles un apoyo vital adaptado a sus necesidades únicas.'
        },
        serviceRow3 :{
            title: 'Muestre su experiencia:',
            paragraph: 'se dedica a promover la excelencia en la atención. Como socio, tiene la oportunidad de mostrar la experiencia y los valiosos recursos de su agencia a nuestra audiencia comprometida de cuidadores. Fortalezca su reputación y conviértase en un recurso de referencia en su comunidad.'
        },
        serviceRow4 :{
            title: 'Fuente de financiación sostenible:',
            paragraphPart1: 'Entendemos los desafíos de asegurar una financiación fiable para su importante trabajo.',
            paragraphPart2: 'ofrece una oportunidad única para desbloquear una fuente de financiación sostenible y expansiva. Como nuestro socio, tendrá acceso a oportunidades de financiamiento, subvenciones y asociaciones que pueden reforzar su estabilidad financiera y permitirle continuar sirviendo a su comunidad con excelencia.',
        },
        footer: {
            title: 'Listo para dar el primer paso?',
            paragraph:'Creemos que toda asociación comienza con una conversación. Nuestro equipo se pondrá en contacto con usted en breve para analizar las infinitas posibilidades que se avecinan. Únase a nosotros en este viaje transformador y sea una fuerza impulsora para un cambio positivo en el cuidado. Juntos, podemos crear un futuro mejor para los cuidadores y sus seres queridos.'
        },
        contactRequest: {
            heading: 'Vamos a conectar',
            firstName: 'Nombre de pila',
            lastName: 'Apellido',
            commentsOrQuestions: 'Comentarios o Preguntas',
            email: 'Correo electrónico',
            submit: 'Entregar',
            wait: 'Espere por favor...',
            placeholder: 'Escribe tu mensaje...',
        },
    }
}