const Index = {
    organismsTranslations: {
        missingContent: "Sorry, we couldn't find that content",
        backHome: 'Back to home',
        backToResults: 'Back to results',
        backToEditing: 'Back to editing',
        privacyPolicy: 'Privacy Policy',
        reprintPermission: 'Reprint Permission',
        termsOfUse: 'Terms Of Use ',
        moreToDiscover: 'More to discover',
        caregiverResources: 'Caregiver Resources',
        sideMenu:{
            discover: 'Discover',
            support: 'My Support',
            collections: 'Collections',
            resources: 'Resources',
            community: 'Community',
            careStories: 'Caregiver Stories',
            forum: 'Forum',
            awards: 'Awards',
            newsroom: 'Newsroom',
            research: 'Research',
            mediaLibrary: 'Media Library',
            podcasts: 'Podcasts',
            videos: 'Videos',
            about: 'About',
            team: 'Our Team',
            contributors: 'Our Contributors',
            champions:'Our Champions',
            partner: 'Become a partner',
        },
        mailingList: {
            title: 'Join our mailing list',
            description: 'Sign up to receive our newsletter that includes caregiving tips, news, support and more.',
            button: 'Sign up',
            errorMessage: 'Oops! Looks like we already have your email address on file. Check your email for our weekly newsletter for everything about Caregiving.  In the meantime, check out our trending articles'
        },
        follow: {
            name: 'Name',
            date: 'Date',
            type: 'Type'
        },
        whyCreateAccount: {
            title: 'Why create an account?',
            learnMore: 'Learn More',
            items: {
                item1: { title: 'Content catered to you via your care profile', description: 'Once you complete your profile, our article-tagging system will recommend content that helps you where you are in your caregiving.' },
                item2: { title: 'Keep everyone in your care journey on the same page', description: 'Caregiving.com can help you keep your circle of care updated. Invite your family or other caregivers to your loved one’s profile so they can benefit from the same updates, education, and support as you do.' },
                item3: { title: 'Latest updates, stories, tools, and resources', description: 'We are always adding new educational articles, videos, podcasts, and training tools to our digital library. Once your profile is created, we will serve you the latest content surrounding topics that matter to you most.' },
                item4: { title: 'Connect to insurance providers, employers, and health systems', description: 'Caregiving.com is partnering with insurance providers, employers, and health systems.' }
            }
        },
        relationships: {
            aunt: 'Aunt',
            boyfriend: 'Boyfriend',
            brother: 'Brother',
            brotherInLaw: 'Brother-in-law',
            careGiver: 'Caregiver',
            cousin: 'Cousin',
            daughter: 'Daughter',
            daughterInLaw: 'Daughter-in-law',
            doctor: 'Doctor',
            father: 'Father',
            fatherInLaw: 'Father-in-law',
            fiancé: 'Fiancé',
            fiancée: 'Fiancée',
            friend: 'Friend',
            girlfriend: 'Girlfriend',
            granddaughter: 'Granddaughter',
            grandfather: 'Grandfather',
            grandmother: 'Grandmother',
            grandson: 'Grandson',
            husband: 'Husband',
            mother: 'Mother',
            motherInLaw: 'Mother-in-law',
            neighbor: 'Neighbor',
            nephew: 'Nephew',
            niece: 'Niece',
            sister: 'Sister',
            sisterInLaw: 'Sister-in-law',
            son: 'Son',
            sonInLaw: 'Son-in-law',
            stepdaughter: 'Stepdaughter',
            stepfather: 'Stepfather',
            stepmother: 'Stepmother',
            stepson: 'Stepson',
            uncle: 'Uncle',
            wife: 'Wife',  
        }
    }
}

const OrganismsTranslations = Object.assign(Index)

export default OrganismsTranslations;