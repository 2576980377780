export const OneLinkTranslations = {
    oneLinkTranslations: {
        welcome: 'WELCOME!',
        title: "Let's Get Started",
        description: {
            paragraph1: "We've partnered with ",
            paragraph2: ' to make sure that you have access to the latest family caregiver education, training, and resources. Activate your account today to receive a customized caregiver support experience right at your fingertips.',
        },
        button : 'Join now',
        footer: 'For more than 25 years, Caregiving.com has been the premier online community for family caregivers. We help them find the answers to their most urgent challenges and connect them to others who understand what they\'re going through.'
    }
}