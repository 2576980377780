import React, { memo } from 'react';

// https://www.npmjs.com/package/react-multi-carousel
import Carousel from 'react-multi-carousel';
import './carousel.css';

import CarouselArrowSVG from '@/assets/icons/carousel-arrow.svg';
import styles from './carousel.module.scss';

import PropTypes from 'prop-types';

const CustomRightArrow = ({ onClick }) => {
  return <CarouselArrowSVG
    className={`d-none d-md-block ${styles.cgCarousel__arrow} ${styles.cgCarousel__arrowRight}`}
    onClick={() => onClick()} />;
};

const CustomLeftArrow = ({ onClick }) => {
  return <CarouselArrowSVG
    className={`d-none d-md-block ${styles.cgCarousel__arrow} ${styles.cgCarousel__arrowLeft}`}
    onClick={() => onClick()} />;
};

const CarouselComponent = ({ itemsDesktop = 4, itemsDesktopSmall = 2, itemsTablet = 2, slidesToSlideDesktop = 1,
  partialVisibilityGutterDesktop = 0, partialVisibilityGutterMobile = 40, centerMode = false,
  itemsMobile = 1, slidesToSlideMobile = 1, autoPlaySpeed = 0, ssr = true,
  infinite = false, children, ...props }) => {
  
  // partialVisibilityGutter: this is needed to tell the amount of px that should be visible.
  const responsiveDefault = {
    desktop: {
      breakpoint: { max: 5000, min: 1200 },
      items: itemsDesktop,
      slidesToSlide: slidesToSlideDesktop,
      partialVisibilityGutter: partialVisibilityGutterDesktop
    },
    desktopSmall: {
      breakpoint: { max: 1199, min: 992 },
      items: itemsDesktopSmall,
      slidesToSlide: slidesToSlideDesktop,
      partialVisibilityGutter: partialVisibilityGutterDesktop
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: itemsTablet
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: itemsMobile,
      slidesToSlide: slidesToSlideMobile,
      partialVisibilityGutter: partialVisibilityGutterMobile
    }
  };

  return <div style={{ position: 'relative' }}>
    <Carousel
      responsive={responsiveDefault}
      ssr={ssr}
      centerMode={centerMode}
      infinite={infinite && children.length > 1}
      autoPlay={autoPlaySpeed > 0}
      autoPlaySpeed={autoPlaySpeed}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      {...props}
    >
      {children}
    </Carousel>
  </div>
};

CarouselComponent.propTypes = {
  autoPlaySpeed: PropTypes.number,
  infinite: PropTypes.bool,
  centerMode: PropTypes.bool,
  itemsDesktop: PropTypes.number,
  itemsDesktopSmall: PropTypes.number,
  itemsTablet: PropTypes.number,
  itemsMobile: PropTypes.number,
  onlyOneElementMobile: PropTypes.number,
  slidesToSlideDesktop: PropTypes.number,
  slidesToSlideMobile: PropTypes.number,
  partialVisibilityGutterDesktop: PropTypes.number,
  partialVisibilityGutterMobile: PropTypes.number,

  ssr: PropTypes.bool
};

CustomRightArrow.propTypes = {
  onClick: PropTypes.func
};

CustomLeftArrow.propTypes = {
  onClick: PropTypes.func
};

export default memo(CarouselComponent);
