import { useEffect } from 'react';
import { NavbarObservable } from '@/observables/navbar.observable';

export const useDisposeNavbarBanner = () => {
  useEffect(() => {
    NavbarObservable.toggleDisplayBanner(false);

    return () => NavbarObservable.toggleDisplayBanner(true);
  }, []);
}
