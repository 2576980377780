import MoleculeTranslations from './molecules';
import HooksTranslations from './hooks';
import AtomsTranslations from './atoms';
import OrganismsTranslations from './organisms';
import FormTranslations from './forms';
import PageTranslations from './pages';
import ConstantsTranslations from './constants';

const Index = {
    copyright: '© 2022 Caregiving.com, All rights reserved.',
    loginEmailError: 'The email or password is incorrect',
    loginUsernameError: 'The username or password is incorrect',
    backButton: 'Back',
    login : {
      title:'Welcome back',
    },
    registrationErrors: {
        name: 'This field is required',
        firstname: 'Firstname is required',
        lastname: 'Lastname is required',
        email: {
            required: 'This field is required',
            exists:'The email already exists.',
            invalidFormat: 'Invalid email format'
        },
        password: 'This field is required'
    },
    subscribe: 'Subscribe me to the caregiving.com newsletter.',
    terms: {
        paragraph1: 'I have ',
        paragraph2: 'read and agree ',
        paragraph3: 'to the terms and conditions.'
    },
    noResults: 'No results found.',
    formAccount: {
        title: 'Create your user account',
        name: 'Your full name',
        firstname: 'First name',
        lastname: 'Last name',
        email: 'Email',
        password: 'Password',
        nextButton: 'Next'
    },
    usernameValidation: {
        title: 'Create username',
        subtitle: 'Pick a username for your new account. Username must contain at least 3 characters (alphanumeric, periods, and hyphens).',
        errors: {
            fieldRequired: 'This field is required',
            notAvailable: 'The username {{username}} is not available.',
            noSpaces: 'Username must not contain spaces.',
            minLen: 'Username must contain at least 3 characters.'
        },
        username: 'Username', 
        createButton: 'Create account',
    },
    caregivingPromise: {
        title: 'The Caregiving Promise',
        description: 'Our promise and commitment to family caregivers, is that your experience on Caregiving.com will be helpful, impactful, and enjoyable. The information you share with us will only be used to improve your experience on the site and we will never share or sell your individual information with any outside business. We want to connect you to the information or resources you need on your terms. You\'ll never get phone calls or emails from business that got your information from us. That\'s not who we are and we know that\'s not want you want. Your experience and your trust are our top priority.',
        footer: 'Whenever you see this badge, we want it to be a reminder of our promise and commitment to you, the family caregiver. ',
        startButton: 'Get Started'
    }, 
    createCareProfile: {
        description: 'Success! Your account has been created. Would you now like to setup a care profile so that caregiving.com can provide you with content relevant to your caregiving experience?',
        buttonYes: 'Yes, I’d like to create a care profile',
        buttonNo: 'No Thanks, I’ll do it later'
    },
    careMemberOnboard: {
        description: 'Let’s answer some questions about your care situation so that caregiving.com can provide you with content relevant to your caregiving experience?',
        buttonYes: 'Yes, let’s do it',
        buttonNo: 'No Thanks, I’ll do it later',
        external: {
            description: 'Do you want to add {invite_value} as a member of {recipient_name} care circle?',
            accept: 'Accept',
            skip: 'Skip',
        }
    },
    getStartedQuestions: {
        title: 'A few quick questions to get started.',
        gender: {
            label: 'What is your gender?'
        },
        race: {
            label: 'How would you describe your ethnicity?'
        },
        birthdate: {
            label: 'Birthdate'
        },
        employmentStatus: {
            label: 'Are you employed?'
        },
        nextButton: 'Next',
        skipButton: 'Skip'
    }, 
    linksSection: {
        signin: 'Already have an account? Sign in',
        noAccount: 'Don’t have an account? Sign up',
        forgotPassword: 'I forgot my password',
        terms: 'Terms of Use',
        privacy: 'Privacy Policy'
    },
    noSearchResults: {
        text1: "We're sorry! We couldn't find any results ",
        text2: 'Try adjusting your filters to broaden your search.'
    },
    popularArticles: 'Maybe these popular articles will interest you.',
    passwordValidation: {
        errors: {
            fieldRequired: 'This field is required',
            notMatch: "Password don't match"
        }
    }
    
}

const En = Object.assign(
    Index,
    MoleculeTranslations,
    HooksTranslations,
    AtomsTranslations,
    OrganismsTranslations,
    FormTranslations,
    PageTranslations,
    ConstantsTranslations
)

export default En;
