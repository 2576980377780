export const RewardsTranslations = {
    rewardsTranslations: {
        myRewards: 'My Rewards',
        collections: 'Collections',
        collectionText: 'Earn badges by taking the quizzes at the end of our collection content.',
        caregiving: 'Caregiving',
        caregivingText: 'Earn badges by maximizing your Caregiving.com profile’s potential!',
        waysToEarn: 'Ways to Earn',
        earnText: 'Earn stars by taking the quizzes at the end of our collection content and more options below.',
        earn: 'Earn',
        completed: 'Completed',
        stars: 'Stars',
        badges: 'Badges',
        congrats: 'Congrats You’ve Earned Your',
        share: 'Share'
    }
}
