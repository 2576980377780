//used in multiple files...
const Index = {
    formTitles: {
        profilePhoto: {
            title: 'Foto de perfil',
            description: 'Agrega una foto para tu cuenta.',
            updateTitle: 'Sube una foto desde tu computadora',
        },
        name: 'Nombre',
        fullname: 'Nombre completo',
        firstname: 'Nombre de pila',
        lastname: 'Apellido',
        username: 'Nombre de usuario',
        email: 'Dirección de correo electrónico',
        emailUsername: 'Correo electrónico',
        yourEmail: 'Tu correo electrónico',
        zipcode: 'Código postal',
        race: 'Etnicidad',
        birthdate: 'Cumpleaños',
        employmentStatus: 'Estado de Empleo',
        gender: 'Género',
        password: {
            title: 'Contraseña',
            placeholder: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            confirmPassword: 'Confirmar contraseña',
            updatePassword: 'Actualiza contraseña',
        },
        caregiverEmail: 'Correo electrónico del cuidador',
        caregiverEmailPlaceholder: 'Introduce la dirección de correo electrónico del cuidador',
    },
    buttons: {
        logout: 'Cerrar sesión',
        login: 'Iniciar sesión',
        edit: 'Editar',
        accept: 'Aceptar',
        decline: 'Rechazar',
        remove: 'Eliminar',
        save: 'Salvar',
        cancel: 'Cancelar',
        manageAccount: 'Gestiona tu cuenta',
        signOut: 'Desconectar',
        addNew: 'Añadir Nueva',
        resend: 'Reenviar',
        accountSignUp: 'No tienes una cuenta? Regístrate',
        forgotPassword: 'Olvidé mi contraseña',
        existingAccountSignIn: 'Ya tienes una cuenta? Inicia sesión',
        update: 'Actualizar',
        send: 'Enviar',
        back: 'Atrás',
        selectAll: 'Seleccionar todo',
        startWatching: 'Empezar a mirar',
        next: 'Siguiente',
        getResources: 'Obtener recursos',
    },
};

const FormTranslations = Object.assign(Index)

export default FormTranslations;
