import Facebook from '@/assets/icons/facebook.svg';
import Instagram from '@/assets/icons/instagram.svg';
import Twitter from '@/assets/icons/twitter.svg';
import LinkedIn from '@/assets/icons/linkedin.svg';
import AltFacebook from '@/assets/icons/facebook-solid.svg';
import AltInstagram from '@/assets/icons/instagram-solid.svg';
import AltTwitter from '@/assets/icons/twitter-solid.svg';
import AltLinkedIn from '@/assets/icons/linkedin-solid.svg';
import Image from '@/components/atoms/image';
import {ImageSizes} from '@/constants/image-sizes.constant';
import {useTranslation} from '@/hooks';
import PropTypes from 'prop-types';
import React, {memo, useEffect, useRef, useState} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import Skeleton from 'react-loading-skeleton';
import styles from './article-banner.module.scss';
import { getParsedImageURL } from '@/utils/images';

const SocialMedia = ({instagramLink, facebookLink, twitterLink, linkedinLink, altIcons}) => {
  const {t} = useTranslation();

  
  return <div className={styles.cgArticleBanner__socialContainer}>
    <p className={styles.cgArticleBanner__socialMediaTitle}>{t('moleculeTranslations.followUs')}:</p>
    <div className={styles.cgArticleBanner__socialIconsWrapper}>
      { instagramLink &&
        <a className={styles.cgArticleBanner__icon} rel="noopener noreferrer" target="_blank"  href={instagramLink}>
          {!altIcons ? <Instagram /> : <AltInstagram />}
        </a>
      }
      { facebookLink &&
        <a className={styles.cgArticleBanner__icon} rel="noopener noreferrer" target="_blank"  href={facebookLink}>
          {!altIcons ? <Facebook />: <AltFacebook />}
        </a>
      }
      { twitterLink &&
          <a className={styles.cgArticleBanner__icon} rel="noopener noreferrer" target="_blank"  href={twitterLink}>
            {!altIcons ? <Twitter /> : <AltTwitter />}
          </a>
      }
      { linkedinLink &&
          <a className={styles.cgArticleBanner__icon} rel="noopener noreferrer" target="_blank"  href={linkedinLink}>
            {!altIcons ? <LinkedIn /> : <AltLinkedIn />}
          </a>
      }
    </div>
  </div>
}

const ArticleBanner = ({className = '', contentClassName = '', eager = false, img, imgSize, title, subtitle, color, facebookLink, instagramLink, twitterLink, linkedinLink, altIcons, EmbeddedButton, ...props}) => {
  const [absoluteElementHeight, setAbsoluteElementHeight] = useState(0);
  const ref = useRef(null);
  const [imgLoad, setImgLoad] = useState('');

  useEffect(() => {
    if (ref.current) {
      setAbsoluteElementHeight(ref.current.clientHeight);
    }
  }, [])

  useEffect(() => {
    if (img !== imgLoad) {
      setImgLoad(img);
    }
  }, [img])

  useEffect(() => {
    // create virtual image, trigger callback to set height when image is loaded as it should coincide with the background image that is actually used being loaded
    if (window && imgLoad !== '') {
      const image = new window.Image();
      image.addEventListener('load', function () {
        setAbsoluteElementHeight(ref.current.clientHeight);
      });
      image.src = getParsedImageURL(imgLoad, ImageSizes.BANNER);
    }
  }, [imgLoad])

  const handleResize = () => {
    setAbsoluteElementHeight(ref.current.clientHeight);
  }

  if (typeof window != 'undefined') {
    window.addEventListener('resize', handleResize)
  }

  const getBackground = (img) => {
    if (color) {
      // if length is 7 or less, assume it's a hex color
      if (color.length <= 7) {
        if (color[0] !== '#') {
          color = `#${color}`;
        }
        return { backgroundColor: color };
      } else {
        return { background: color }; // pass whatever is in the database as the background
      }
    }
    if (!img) {
      return { backgroundColor: '#4e61ed' };
    }
    return {
      backgroundImage: `url(${getParsedImageURL(img, ImageSizes.BANNER)})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    };
  }

  const showSocial = facebookLink || instagramLink || twitterLink;

  const background = getBackground(img);
  return (
    <div className={className} style={{position: 'relative', overflow: 'hidden'}}>
      <div
        className={ `${styles.cgArticleBanner__banner} ${color ? '' : styles.cgArticleBanner__bluredImage}`}
        style={
          {
            ...background,
            height: `${absoluteElementHeight}px`
          }
        }
        >
        </div>
        <div
          ref={ref}
          className={`${styles.cgArticleBanner} ${contentClassName}`}
          {...props}>
            <div className={styles.cgArticleBanner__infoContainer}>
              { title &&
                <h2 className={styles.cgArticleBanner__title}>{title}</h2>
              }
              { subtitle &&
                <p className={styles.cgArticleBanner__subtitle}>{subtitle}</p>
              }
              <div className={styles.cgArticleBanner__social}>
                <EmbeddedButton/>
                { showSocial &&
                  <div className={`${styles.cgArticleBanner__divider} d-none d-md-block`}></div>
                }
                { showSocial &&
                <SocialMedia facebookLink={facebookLink} instagramLink={instagramLink} twitterLink={twitterLink} linkedinLink={linkedinLink} altIcons={altIcons}/>
                }
              </div>
            </div>
            <Image eager={eager} className={styles.cgArticleBanner__img} src={img} size={imgSize || ImageSizes.MEDIUM}/>
          </div>
      </div>
  );
};

ArticleBanner.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  img: PropTypes.string,
  imgSize: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  buttonText: PropTypes.string,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  altIcons: PropTypes.bool,
  EmbeddedButton: PropTypes.any, // PropTypes.node returns a warning regardless of how the component is passed. Setting to any to prevent warnings since it's useless anyway
  eager: PropTypes.bool, // true to not lazy load the image
};

SocialMedia.propTypes = {
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  altIcons: PropTypes.bool,
};

const onErrorFallbackComponent = () => <Skeleton height={330}/>;

export default memo(withErrorBoundary(ArticleBanner, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
}));
