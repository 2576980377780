import React, {useEffect, useRef} from 'react';
import { Button } from 'react-bootstrap';
import styles from './user-dropdown.module.scss';
import UserDropdownItemComponent from '../user-dropdown-item';
import CareRecipientsRound from '@/assets/icons/care-recipients-round.svg';
import FollowingRound from '@/assets/icons/following-round.svg';
import SavedRound from '@/assets/icons/saved-round.svg';
import {useObservable} from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useCareRecipientsObservable } from '@/observables/care-recipients.observable';
import Avatar from '@/components/atoms/avatar';
import PropTypes from 'prop-types';
import {useTranslation} from '@/hooks';
import LogoutComponent from '@/components/pages/authentication/logout';

const UserDropdownComponent = ({user, showDropdown, classNameMobile, refAvatar}) => {
    const ref = useRef(null);
    const history = useNavigate();
    const { careRecipients$ } = useCareRecipientsObservable();
    const [careRecipients] = useObservable(careRecipients$.asObservable());
    const {t} = useTranslation();

    const onLogout = () => {
        LogoutComponent()
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    const onClickItems = (url) => {
        showDropdown(false);
        history(url);
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && !refAvatar?.current?.contains(event.target)) {
            showDropdown(false);
        }
    }

    return (
        <div ref={ref} className={`${styles.cgUserDropdown} ${classNameMobile}`}>
            <Avatar
                name={user?.firstname ? (`${user?.firstname} ${user?.lastname || ''}` || user.fullname) : ''}
                photo={user?.avatar?.location}
                update={() => onClickItems('/manage')}
            />
            <h2 className={styles.cgUserDropdown__name}>
                {user ? (`${user?.firstname} ${user?.lastname}` || user.fullname) : ''}
            </h2>
            <p className={styles.cgUserDropdown__mail}>{user?.email}</p>
            <Button
                className={styles.cgUserDropdown__button}
                onClick={onClickItems.bind(this, '/manage')}
            >
                {t('buttons.manageAccount')}
            </Button>

            <div className={styles.cgUserDropdown__menu}>
                <UserDropdownItemComponent
                    name={`${t(
                        'moleculeTranslations.myCareRecipients'
                    )} (${careRecipients?.length || 0})`}
                    icon={<CareRecipientsRound className={styles.cgUserDropdown__icon} />}
                    url={() => onClickItems('/manage/care-recipients')}
                />
                <UserDropdownItemComponent
                    name={t('following')}
                    icon={<FollowingRound className={styles.cgUserDropdown__icon} />}
                    url={() => onClickItems('/manage/following')}
                />
                <UserDropdownItemComponent
                    name={t('saved')}
                    icon={<SavedRound className={styles.cgUserDropdown__icon} />}
                    url={() => onClickItems('/manage/saved')}
                />
            </div>
            <Button className={styles.cgUserDropdown__button} onClick={onLogout}>
                {t('buttons.signOut')}
            </Button>
        </div>
    );
}

UserDropdownComponent.propTypes = {
    user: PropTypes.any,
    showDropdown: PropTypes.func,
    classNameMobile: PropTypes.any,
    refAvatar: PropTypes.any
}

export default UserDropdownComponent;