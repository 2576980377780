import { BehaviorSubject } from 'rxjs';

const showAlertSubject = new BehaviorSubject(false);

export const AlertObservable = Object.freeze({
  fireAlert: (config) => {
    showAlertSubject.next(config);
  },
  fireWarning: (config) => {
    const { type: _, ...rest } = config;
    showAlertSubject.next({ type: 'warning', ...rest });
  },
  fireError: (config) => {
    const { type: _, title = 'Error', ...rest } = config;
    showAlertSubject.next({ type: 'error', title, ...rest });
  },
  fireSuccess: (config) => {
    const { type: _, ...rest } = config;
    showAlertSubject.next({ type: 'success', ...rest });
  },
  hideAlert: () => {
    showAlertSubject.next(undefined);
  },
  showAlert$: showAlertSubject.asObservable(),
});
