export const Pages = Object.freeze({
  HOME: 'Home',
  BLOG: 'Blog',
  POSTS: 'Posts',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot password',
  REGISTER: 'Register',
  GIVEAWAY: 'Giveaway',
  ARTICLES: 'Articles',
  MEDIA_VIDEOS: 'Videos',
  NEWSROOM: 'Newsroom',
  COMMUNITY: 'Community',
  OUR_STORY: 'Out story',
  CHAMPIONS_CONTACT: 'ChampionsContact',
  CONTRIBUTOR: 'Contributor',
  CONTRIBUTOR_CONTACT: 'ContributorContact',
  SEARCH: 'Search',
  MANAGE_ACCOUNT: 'User account',
  MANAGE_PROVIDERS: 'Manage providers',
  PROVIDER_PROFILE: 'Provider profile',
  PROVIDER_EDIT_PROFILE: 'Provider edit profile',
  PRIVACY_POLICY: 'Privacy policy',
  REPRINT_PERMISSION: 'Reprint permission',
  TERMS_OF_USE: 'Terms of use',
  CORONAVIRUS_RESOURCES: 'Coronavirus resources',
  CHAT: 'Chat',
  SAVED: 'Saved',
  FOLLOWING: 'Following',
  ONE_LINK: 'One Link',
  MEMBERS_ONBOARD: 'Care recipient member invite',
});
