import PropTypes from 'prop-types';
import React from 'react';
import {Button as ButtonComponent} from 'react-bootstrap';
import {withErrorBoundary} from 'react-error-boundary';
import Skeleton from 'react-loading-skeleton';
import styles from './button.module.scss';

export const BUTTON_VARIANT = {
  primary: 'primary',
  link: 'link'
};

const Button = ({className, FaIcon, color, backgroundColor = 'linear-gradient(172.66deg, #4E61ED 0%, #9662DB 100%)', children, variant = BUTTON_VARIANT.primary, ...props}) => (
  <ButtonComponent style={{background: backgroundColor}} className={[
    className && className,
    styles.cgButtonComponent,
    variant === BUTTON_VARIANT.link && styles.cgButtonComponent__linkVariant
  ]} {...props}>
    <span style={{color: color}}>
      {FaIcon &&
      <FaIcon className={styles.cgButtonComponent__buttonIcon} style={{color: color}}/>}
      {children}
    </span>
  </ButtonComponent>
);

Button.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  FaIcon: PropTypes.any,
  color: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANT)),
};

const onErrorFallbackComponent = () => <Skeleton height={28} width={50} count={1}/>

export default withErrorBoundary(Button, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
});
