export const ProviderPageTranslations = {
  providerPageTranslations: {
    caregiverSupport: 'Caregiver Support',
    insuranceCompany: 'Insurance Company',
    caregivingPromise: 'For the good of the industry, the Caregiving Promise is to never sell leads or charge communities a commission.',
    more: 'more',
    about: 'About',
    follow: 'Subscribe',
    following: 'Subscribed',
    save: 'Save',
    saved: 'Saved',
    share: 'Share',
    createAccountCTA: {
      body: 'Get the most out of caregiving.com by creating an account.',
      button: 'Sign up now!',
    },
    discoveryItems: {
      caregiving101: 'Caregiving 101',
      longTermCare: 'Long-term care',
      personalWellness: 'Personal wellness',
      financialLegal: 'Financial & legal',
      healthMedical: 'Health & medical',
    },
    types: {
      contributor: 'Contributor'
    },
  },
};
