import CancelIcon from '@/assets/icons/cancel.svg';
import SearchIcon from '@/assets/icons/search.svg';
import SearchIconCircle from '@/assets/icons/searchCircle.svg';
import {KeyCodes} from '@/constants/key-codes.constant';
import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {withErrorBoundary} from 'react-error-boundary';
import Skeleton from 'react-loading-skeleton';
import {useLocation} from 'react-router-dom';
import styles from './search.module.scss';
import { useTranslation } from '@/hooks';

const SearchComponent = forwardRef(({ // eslint-disable-line react/display-name
  onChange,
  options,
  onSearch = () => {},
  loading,
  value,
  placeholder,
  onEnterPress,
  onFocus,
  className,
  mobile,
  hideAutoComplete,
  onClear = () => {},
}, ref) => {
  const {pathname} = useLocation();
  const [showSearch, setShowSearch] = useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    setShowSearch(false);
  }, [pathname]);

  return (
    <>
      <SearchIcon
        className={`${!mobile ? 'd-block d-md-none d-lg-none' : 'd-none'}`}
        onClick={() => {
          if (!pathname.startsWith('/search')) {
            setShowSearch(!showSearch);
          }
        }}
      />
      <div
        className={`${
          mobile || showSearch
            ? 'd-flex d-md-none d-lg-none'
            : 'd-none d-md-flex d-lg-flex'
        } ${showSearch ? styles.cgSearchHeader : styles.cgSearchHeaderResults}`}
      >
        <AsyncTypeahead
          className={[`${styles.cgSearchHeader__input}`, className]}
          ref={ref}
          filterBy={() => true}
          id="search-header"
          selected={[value]}
          isLoading={loading || false}
          minLength={hideAutoComplete ? Number.MAX_SAFE_INTEGER : 3}
          useCache={false}
          delay={500}
          options={options}
          promptText={t('atomsTranslations.search.noResults')}
          searchText={t('atomsTranslations.search.searching')}
          autoFocus={mobile}
          onKeyDown={(e) => {
            if (
              e.keyCode === KeyCodes.KEY_ENTER ||
              e.keyCode === KeyCodes.KEY_RETURN
            ) {
              e.target.blur();
              const text = e.currentTarget.value;
              onChange && onChange(text);
              onEnterPress?.(e);
            }
          }}
          onInputChange={(_, e) => {
            const text = e.target.value;
            onChange && onChange(text);
            if (text?.length >= 3) {
              onSearch(text);
            }
          }}
          onChange={(opt) => {
            opt?.[0] && onChange && onChange(opt[0]);
          }}
          onFocus={(e) => {
            if (onFocus) {
              onFocus(e);
            }
          }}
          onSearch={onSearch}
          placeholder={placeholder || `${t('atomsTranslations.search.search')}`}
        />
        <Button
          as="div"
          className={styles.cgSearchHeader__button}
          onClick={() => {
            onEnterPress();
            setShowSearch(false);
          }}
        >
          <SearchIconCircle />
        </Button>
        <CancelIcon
          className={`${styles.cgSearchHeader__clear} ${
            value !== '' ? 'visible' : 'invisible'
          }`}
          onClick={onClear}
        />
      </div>
    </>
  );
});

SearchComponent.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onEnterPress: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.any,
  mobile: PropTypes.bool,
  hideAutoComplete: PropTypes.bool,
  onClear: PropTypes.func,
}

const onErrorFallbackComponent = () => <Skeleton height={50} width={400}/>;

export default withErrorBoundary(SearchComponent, {
  FallbackComponent: onErrorFallbackComponent,
  onError: console.debug,
});
