import React, {memo} from 'react';
import PropTypes from 'prop-types';
import CgIcon from '@/components/atoms/cg-icon';
import styles from './back.module.scss';
import {useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import {useTranslation} from '@/hooks';

const BackComponent = ({text, className, linkUrl, hashBack, onClickBack}) => {
  const history = useNavigate();
  const {t} = useTranslation();

  text = text ? text : `${t('atomsTranslations.back')}`;

  const onButtonClick = () => {
    if (typeof onClickBack === 'function') {
      onClickBack();
    } else {
      if (hashBack) {
        history(hashBack);
      } else if (linkUrl) {
        history(linkUrl);
      } else {
        history(-1);
      }
    }
  }

  return (<Button
      variant="link"
      className={`${history.length < 3 && styles.cgBack} ${className}`}
      onClick={onButtonClick}
    >
      <div className={styles.cgBack__buttonContent}>
        <CgIcon iconName="IoIosArrowBack" className={styles.cgBack__icon} />
        {hashBack ?
          <HashLink onClick={(e) => e.stopPropagation()} to={hashBack} className={styles.cgBack__text}>{text}</HashLink>
        :
        <span className={styles.cgBack__text}>{text}</span>}
      </div>
    </Button>
  );
};

BackComponent.propTypes = {
  text: PropTypes.string,
  linkUrl: PropTypes.string,
  className: PropTypes.string,
  onClickBack: PropTypes.func,
  hashBack: PropTypes.string,
  alwaysShow: PropTypes.bool
};

export default memo(BackComponent);
