export const PartnerTranslations = {
    partnerTranslations:{
        submitted: 'Thank you!',
        submitDescription: 'Your submission has been received!',
        error: 'Oops! Something went wrong while submitting the form.',
        headingTitle: 'Unlock the Power of Partnership',
        paragraph1: 'Are you ready to revolutionize the way you support caregivers in your community? Look no further! Caregiving.com is thrilled to extend an exclusive invitation to Area Agencies on Aging to join forces with us in our mission to transform the caregiving landscape.',
        subtitle: 'Why Partner with Caregiving.com?',
        serviceRow1 :{
            title: 'Amplify Your Impact:',
            paragraph: 'By joining forces, we can combine our expertise and resources to exponentially enhance the services and support we offer to caregivers. Together, we can empower caregivers and improve the lives of those they care for.'
        },
        serviceRow2 :{
            title: 'Access Innovative Solutions:',
            paragraph: 'Say goodbye to outdated approaches! Our cutting-edge tools and resources empower caregivers, offering them a lifeline of support tailored to their unique needs.'
        },
        serviceRow3 :{
            title: 'Showcase Your Expertise:',
            paragraph: 'is dedicated to promoting excellence in care. As a partner, you have the opportunity to showcase your agency\'s expertise and valuable resources to our engaged audience of caregivers. Strengthen your reputation and become a go-to resource in your community.'
        },
        serviceRow4 :{
            title: 'Sustainable Funding Source:',
            paragraphPart1: 'We understand the challenges of securing reliable funding for your important work.',
            paragraphPart2: 'offers a unique opportunity to unlock a sustainable and expansive funding source. As our partner, you\'ll have access to funding opportunities, grants, and partnerships that can bolster your financial stability and enable you to continue serving your community with excellence.',
        },
        footer: {
            title: 'Ready to Take the First Step?',
            paragraph:'We believe that every partnership begins with a conversation.  Our team will be in touch with you shortly to discuss the endless possibilities that lie ahead. Join us on this transformative journey and be a driving force for positive change in caregiving. Together, we can create a brighter future for caregivers and their loved ones.'
        },
        contactRequest: {
            heading: 'Let\'s Connect',
            firstName: 'First name',
            lastName: 'Last name',
            commentsOrQuestions: 'Comments or Questions',
            email: 'Email',
            submit: 'Submit',
            wait: 'Please wait...',
            placeholder: 'Type your message...',
        },
    }
}