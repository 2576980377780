export const CareRecipientTranslations = {
  careRecipientTranslations: {
    careSituation: {
      title: 'Cuéntenos sobre su situación de atención con ',
      subtitle: 'Cuéntenos sobre su situación de atención para que podamos brindarle contenido relevante para su atención.'
    },
    name: 'Nombre',
    completion: 'Finalización',
    dateAdded: 'Fecha agregada',
    profilePhoto: 'Foto de perfil',
    fullName: 'Nombre completo',
    first_name: 'Nombre de pila',
    last_name: 'Apellido',
    birthdate: 'Fecha de nacimiento',
    zipcode: 'Código postal',
    zipcodeError: {
      required: 'Este campo es obligatorio',
      invalid: 'Debe ser alfanumérico',
    },
    livingSituation: 'Situación de vida',
    insurance: 'Seguro',
    careCategories: 'Categorías de atención',
    relationship: 'Relación',
    listRelationship: 'Relación',
    feelings: {
      text1: '¿Cómo es el cuidado de ',
      text2: ' ¿hacerte sentir?',
    },
    careTasks: 'Tareas de cuidado',
    challenges: 'Desafíos del cuidado',
    challengePlaceholder:
      'Opcional. En sus propias palabras, cuéntenos sobre los desafíos que enfrenta actualmente como cuidador para ',
    inviteMembers: 'Invitar a los miembros',
    back: 'Atrás',
    next: 'Siguiente',
    done: 'Hecho',
    more: 'Más',
    error:
      'Hubo un error al procesar su solicitud. Por favor verifique que haya completado toda la información correctamente.',
    example: {
      title: 'Ejemplo',
      paragraph1: 'cuidado de ',
      paragraph2:
        ' como resultado menos tiempo para mí y otros miembros de la familia. A menudo paso tanto tiempo en tareas de cuidado que termino sacrificando las cosas que disfruto, como pasatiempos o vacaciones. Tengo problemas para equilibrar los horarios de trabajo en torno a la prestación de cuidados.',
      paragraph3:
        'A menudo, los deberes de cuidado ocupan tanto de mi tiempo que ya no puedo mantener conexiones sociales fuera del hogar. Esta falta de tiempo con amigos y familiares ha comenzado a conducir a la depresión y la ansiedad.',
    },
    photoTitle: 'Foto del destinatario del cuidado',
    insuranceCoverage: 'Cobertura del seguro',
    careExperience: 'Su experiencia de cuidado',
    topics: 'Temas de enfoque',
    updateTitle: 'Sube una foto desde tu computadora',
    editHandler: {
      title: 'Foto del destinatario del cuidado',
      description: 'Agregue una foto para su destinataria de atención.',
    },
    conditions: {
      title: 'Condiciones',
      addButton: 'Agregar nueva',
      addAnotherButton: 'Agregar otra condición',
      searchText: 'Comience a escribir para buscar condiciones.',
      noConditions: 'No se encuentran condiciones',
      subtitle: {
        paragraph1: 'Agregar una nueva condición a ',
        paragraph2: 'perfil',
      },
      back: 'Perfil'
    },
    medications: {
      placeHolder:
        'Comience a escribir para buscar medicamentos por nombre o NDC.',
      backButton: 'Medicamentos',
      title: 'Agregar nuevo medicamento',
      noMedications: 'No se encontraron medicamentos.',
      subtitle: {
        paragraph1: 'Agregar un nuevo medicamento a ',
        paragraph2: ' perfil',
      },
      header: 'Medicamentos',
      back: 'Perfil'
    },
    optional: 'Opcional',
    progressBar: {
      step1: 'Información general',
      step2: 'Situación de atención',
      step3: 'Elija temas',
      step4: 'Invitar a los miembros',
      step5: 'Detalles finales',
    },
    pageTitle: '¿A quién estás cuidando?',
    pageDescription:
      'Cree un perfil de cuidado para que podamos brindarle contenido relevante para su cuidado.',
    pageTip: {
      title: 'Consejo:',
      description:
        'Llena todo lo que puedas. Siempre puede agregar más detalles en las preferencias del usuario más adelante.',
      button: 'Entendido',
    },
    careRecipients: 'Destinatarias de atención',
    pendingInvites: 'Invitaciones pendientes:',
    relationshipPlaceholder: {
      text1: 'Soy ... de ',
      text2: '',
    },
    conditionsPlaceholder: 'P.ej. Hipertensión',
    medicationsPlaceHolder: 'P.ej. Ibuprofeno',
    medicationsDosagePlaceHolder: 'Agregar dosis',
    medicationsFrequencyPlaceHolder: 'Frecuencia',
    edit: {
        title: 'Perfil',
        categoryAtentionPlaceholder: 'Estas categorias ayudan a...'
    },
    members: {
        title: 'Miembros',
        anchor: 'Administrar'
    },
    required: '¡Por favor rellene este campo!',
    requiredMarker: 'Campo requerido',
    describeCareRecipient: '¿Alguno de los siguientes describe a la persona que está cuidando?',
    careRecipientConditions: '¿Qué condiciones tiene la persona que cuida?',
    noCareRecipientBanner: {
      title: 'Desbloquee todo lo que caregiving.com tiene para ofrecer.',
      subTitle: 'Cree un perfil de destinatario de cuidado para obtener contenido curado relevante para su situación de cuidado única.'
    }
  },
};
