import {useEffect, useState} from 'react';
import { LayoutObservable } from '@/observables/layout.observable';

export const useDisposeHeader = (mobileOnly = false) => {
  const [width, setWidth] = useState(typeof window !== 'undefined'? window.innerWidth: 768);
  
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [window]);

  useEffect(() => {
    if (!mobileOnly) {
      LayoutObservable.toggleDisplayHeader(false);
    } else if (width <= 768) {
      LayoutObservable.toggleDisplayHeader(false);
    }

    return () => LayoutObservable.toggleDisplayHeader(true);
  }, [width]);
};
