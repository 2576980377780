import En from '@/languages/language_en';
import Es from '@/languages/language_es';

export const Resources = {
    en: {
        translation: En
    },
    es: {
        translation: Es
    }
};