import React from 'react';
import styles from '@/styles/_legal.module.scss';
import {useDataLayer, useTranslation} from '@/hooks';
import { Pages } from '@/constants/pages.constant';
import { Container } from 'react-bootstrap';
import SignupMailSection from '@/components/organisms/signup-mail-section';


const PrivacyPolicyComponent = () => {
  useDataLayer(Pages.PRIVACY_POLICY);
  const {t} = useTranslation();

  return (
    <>
      <Container className='my-5'>
        <div
          className={`${styles.cgLegal__section} justify-content-md-center`}
        >
          <div className={styles.cgLegal__subSection}>
            <h1 className={`${styles.cgLegal__title} pb-5`}>{t('privacyPolicyTranslations.title')}</h1>
            <p className={`${styles.cgLegal__description} pb-3`}>
              {t('privacyPolicyTranslations.effectiveDate')}
            </p>
            <p className={`${styles.cgLegal__description} pb-3`}>
              {t('privacyPolicyTranslations.paragraph1')}
            </p>
            <p className={`${styles.cgLegal__description} pb-3`}>
              {t('privacyPolicyTranslations.paragraph2')}
              <a href='https://www.caregiving.com/'>
                {t('privacyPolicyTranslations.link')}
              </a>
            </p>
            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.definitions')}
              </span>
              <ul>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.service')}
                  </span>
                  {t('privacyPolicyTranslations.serviceText')}
                  <a href='https://www.caregiving.com/'>
                    {t('privacyPolicyTranslations.link')}
                  </a>
                  {t('privacyPolicyTranslations.website')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.personalData')}
                  </span>
                  {t('privacyPolicyTranslations.personalDataText')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.usageData')}
                  </span>
                    {t('privacyPolicyTranslations.usageDataText')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                      {t('privacyPolicyTranslations.cookies')}
                  </span>
                  {t('privacyPolicyTranslations.cookiesText')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataController')}
                  </span>
                  {t('privacyPolicyTranslations.dataControllerText')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataProcessors')}
                  </span>
                  {t('privacyPolicyTranslations.dataProcessorsText')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataSubject')}
                  </span>{t('privacyPolicyTranslations.dataSubjectText')}
                </li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.collectedInformation')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.collectedInformationText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.personalData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.personalDataText2.paragraph1')}
              <ul>
                <li>{t('privacyPolicyTranslations.personalDataText2.listItem1')}</li>
                <li>{t('privacyPolicyTranslations.personalDataText2.listItem2')}</li>
                <li>{t('privacyPolicyTranslations.personalDatText2.listItem3')}</li>
              </ul>
              {t('privacyPolicyTranslations.personalDataText2.paragraph2')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.comments')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.commentsText.paragraph1')}
              <a href='https://automattic.com/privacy/'>
                {t('privacyPolicyTranslations.commentsText.link')}
              </a>
              {t('privacyPolicyTranslations.commentsText.paragraph2')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.media')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.mediaText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.contactForms')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.contactFormsText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                 {t('privacyPolicyTranslations.embeddedContent')}
              </span>{' '}
              <br></br>
                {t('privacyPolicyTranslations.embeddedContentText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.usageData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.usageDataText2')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.cookieData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.cookieDataText.paragraph1')}
            </p>
            <p className={`${styles.cgLegal__description} pb-3`}>
              {t('privacyPolicyTranslations.cookieDataText.paragraph2')}
            </p>
            <p className={`${styles.cgLegal__description} pb-3`}>
              {t('privacyPolicyTranslations.cookieDataText.paragraph3')}
            </p>
            <p className={`${styles.cgLegal__description} pb-3`}>
              {t('privacyPolicyTranslations.cookieDataText.examples')}
              <ul>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.cookieDataText.example1')}
                  </span>
                  {t('privacyPolicyTranslations.cookieDataText.example1Text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.cookieDataText.example2')}
                  </span>
                  {t('privacyPolicyTranslations.cookieDataText.example2Text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.cookieDataText.example3')}
                  </span>
                  {t('privacyPolicyTranslations.cookieDataText.example3Text')}
                </li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.useOfData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.useOfDataText.paragraph1')}
              <ul>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem1')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem2')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem3')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem4')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem5')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem6')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem7')}</li>
                <li>{t('privacyPolicyTranslations.useOfDataText.listItem8')}</li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.legalBasis')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.legalBasisText.paragraph1')}
              <ul>
                <li>{t('privacyPolicyTranslations.legalBasisText.listItem1')}</li>
                <li>{t('privacyPolicyTranslations.legalBasisText.listItem2')}</li>
                <li>{t('privacyPolicyTranslations.legalBasisText.listItem3')}</li>
                <li>{t('privacyPolicyTranslations.legalBasisText.listItem4')}</li>
                <li>{t('privacyPolicyTranslations.legalBasisText.listItem5')}</li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.retentionOfData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.retentionOfDataText.paragraph1')}
              <br></br>
              {t('privacyPolicyTranslations.retentionOfDataText.paragraph2')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.transferOfData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.transferOfDataText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.disclosureOfData')}
              </span>{' '}
              <br></br>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.outsideParties')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.outsidePartiesText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.businessTransaction')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.businessTransactionText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.disclosureForLawEnforcement')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.disclosureForLawEnforcementText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.legalRequirements')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.legalRequirementsText.paragraph1')}
              <ul>
                <li>{t('privacyPolicyTranslations.legalRequirementsText.listItem1')}</li>
                <li>{t('privacyPolicyTranslations.legalRequirementsText.listItem2')}</li>
                <li>{t('privacyPolicyTranslations.legalRequirementsText.listItem3')}</li>
                <li>{t('privacyPolicyTranslations.legalRequirementsText.listItem4')}</li>
                <li>{t('privacyPolicyTranslations.legalRequirementsText.listItem5')}</li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.securityData')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.securityDataText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.doNotTrack')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.doNotTrackText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.dataProtections')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.dataProtectionText.paragraph1')}
              <br></br>
              {t('privacyPolicyTranslations.dataProtectionText.paragraph2')}
              <br></br>
              {t('privacyPolicyTranslations.dataProtectionText.paragraph3')}
              <ul>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataProtectionText.listItem1.title')}
                  </span>{' '}
                  {t('privacyPolicyTranslations.dataProtectionText.listItem1.text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataProtectionText.listItem2.title')}
                  </span>{' '}
                  {t('privacyPolicyTranslations.dataProtectionText.listItem2.text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataProtectionText.listItem3.title')}
                  </span>{' '}
                  {t('privacyPolicyTranslations.dataProtectionText.listItem3.text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataProtectionText.listItem4.title')}
                  </span>{' '}
                  {t('privacyPolicyTranslations.dataProtectionText.listItem4.text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.dataProtectionText.listItem5.title')}
                  </span>{' '}
                  {t('privacyPolicyTranslations.dataProtectionText.listItem5.text')}
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                   {t('privacyPolicyTranslations.dataProtectionText.listItem6.title')}
                  </span>{' '}
                  {t('privacyPolicyTranslations.dataProtectionText.listItem6.text')}
                </li>
              </ul>
              {t('privacyPolicyTranslations.dataProtectionText.paragraph4')}
              <br></br>
              {t('privacyPolicyTranslations.dataProtectionText.paragraph5')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.serviceProviders')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.serviceProvidersText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.analytics')}
              </span>
              <br></br>
              {t('privacyPolicyTranslations.analyticsText.paragraph1')}
              <ul>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.analyticsText.listItem')}
                  </span>
                  {t('privacyPolicyTranslations.analyticsText.listItemText')}
                  <a href='https://policies.google.com/privacy?hl=en'>
                    {t('privacyPolicyTranslations.analyticsText.link')}
                  </a>
                </li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.payments')}
              </span>
              <br></br>
              {t('privacyPolicyTranslations.paymentsText.paragraph1')}
              <br></br>
              {t('privacyPolicyTranslations.paymentsText.paragraph2')}
              <br></br>
              {t('privacyPolicyTranslations.paymentsText.paragraph3')}
              <ul>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.paymentsText.listItem1.title')}
                  </span>
                  {t('privacyPolicyTranslations.paymentsText.listItem1.text')}
                  <a href='https://stripe.com/us/privacy'>
                    {t('privacyPolicyTranslations.paymentsText.listItem1.link')}
                  </a>
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.paymentsText.listItem2.title')}
                  </span>
                  {t('privacyPolicyTranslations.paymentsText.listItem2.text')}
                  <a href='https://squareup.com/legal/privacy-no-account'>
                    {t('privacyPolicyTranslations.paymentsText.listItem2.link')}
                  </a>
                </li>
                <li>
                  <span className={`${styles.cgLegal__interlinedBold}`}>
                    {t('privacyPolicyTranslations.paymentsText.listItem3.title')}
                  </span>
                  {t('privacyPolicyTranslations.paymentsText.listItem3.text')}
                  <a href='https://www.paypal.com/us/webapps/mpp/ua/privacy-full'>
                    {t('privacyPolicyTranslations.paymentsText.listItem3.link')}
                  </a>
                </li>
              </ul>
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.otherSites')}
              </span>
              <br></br>
              {t('privacyPolicyTranslations.otherSitesText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.childrenPrivacy')}
              </span>{' '}
              <br></br>
              {t('privacyPolicyTranslations.childrenPrivacyText')}
            </p>

            <p className={`${styles.cgLegal__description} pb-3`}>
              <span className={`${styles.cgLegal__subtitle} pb-5`}>
                {t('privacyPolicyTranslations.changePolicy')}
              </span>
              <br></br>
              {t('privacyPolicyTranslations.changePolicyText')}
            </p>
          </div>
        </div>
      </Container>
      <SignupMailSection headingNumber={2} />
    </>
  );
}

export default PrivacyPolicyComponent;
